import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { handleForgotCheck, handleValidateEmail, handleValidateEmailResend } from "src/api/auth/auth.service";
import {
  setForgotPasswordToken,
  setUUID,
  setValidationExpires,
  setValidationToken,
} from "src/store/reducers/auth.reducer";
import { logo } from "../../../assets";
import { Button, CustomAlert, Input, SpinnerLoading, Typography } from "../../../components/common/Base";
import { RootState } from "../../../store";
import "./styles.scss";

const VerificationCodeDefault = () => {
  const [subText, setSubText] = useState("");
  const [routeName, setRouteName] = useState("");
  const [loading, setLoading] = useState(false);
  // const [otpType, setOtpType] = useState<number | null>(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const method = new URLSearchParams(location.search).get("method");
  const {
    uuid,
    validationExpires: validationTokenExpires,
    forgotPasswordExpires,
  } = useSelector((state: RootState) => state.auth.profile);
  const [remainingTime, setRemainingTime] = useState("");

  useEffect(() => {
    if (!validationTokenExpires) {
      return;
    }
    const calculateRemainingTime = (expires: number) => {
      const now = new Date().getTime();
      const distance = expires - now;
      if (distance < 0) {
        setRemainingTime("");
        clearInterval(interval);
      } else {
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setRemainingTime(`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`);
      }
    };
    const interval = setInterval(() => {
      switch (method) {
        case "account":
          if (!validationTokenExpires) {
            break;
          }
          calculateRemainingTime(new Date(validationTokenExpires).getTime());
          break;
        case "forgot":
          if (!forgotPasswordExpires) {
            break;
          }
          calculateRemainingTime(new Date(forgotPasswordExpires).getTime());
          break;
        default:
          // handle default case here
          break;
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [validationTokenExpires, forgotPasswordExpires, method]);

  useEffect(() => {
    switch (method) {
      case "account":
        setSubText(`Enter it below to verify your Account`);
        setRouteName("/dashboard");
        break;
      case "forgot":
        setSubText("Check your email to get your confirmation code. Enter it below to continue.");
        setRouteName("/auth/forgot/password");
        break;
      default:
        // handle default case here
        break;
    }

    // get uuid from URL
    const uuid = new URLSearchParams(location.search).get("uuid");
    setUUID(uuid);
  }, [location.search, method]);

  const { values, handleChange, handleBlur, handleSubmit, errors, setErrors, isValid } = useFormik({
    initialValues: {
      token: "",
      submit: null,
    },
    onSubmit: () => {
      const { token } = values;
      if (!token) {
        setErrors({ submit: "Verification code is required" });
        return;
      }

      if (!uuid) {
        setErrors({ submit: "Invalid request" });
        return;
      }

      setLoading(true);
      let request;
      switch (method) {
        case "account":
          request = handleValidateEmail({ uuid, token });
          break;
        case "forgot":
          request = handleForgotCheck({ uuid, token });
          break;
        default:
          // handle default case here
          break;
      }
      if (!request) {
        setErrors({ submit: "Invalid request" });
        setLoading(false);
        return;
      }
      request
        .then((res) => {
          const { uuid } = res.data;
          dispatch(setUUID(uuid));
          switch (method) {
            case "account":
              dispatch(setValidationToken(token));
              break;
            case "forgot":
              dispatch(setForgotPasswordToken(token));
              break;
            default:
              // handle default case here
              break;
          }
          navigate(routeName);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message || err?.message || "An error occurred";
          setErrors({ submit: errorMessage });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const onResendEmail = () => {
    if (!uuid) {
      setErrors({ submit: "Invalid request" });
      return;
    }
    handleValidateEmailResend({ uuid })
      .then((res) => {
        const { validationTokenExpires } = res.data;
        dispatch(setValidationExpires(validationTokenExpires));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="sign-up-container">
      <div className="left-containerr"></div>

      <div className="right-container">
        <div className="align-center-code">
          <img
            src={logo}
            alt="logo"
            className="logo"
          />
          <Typography
            variant="h3"
            className="code-text"
          >
            We sent you a code
          </Typography>
          <Typography
            variant="small-text"
            className="sub-text"
          >
            {subText}
          </Typography>
          {values.token && errors.submit ? (
            <CustomAlert
              variant={"danger"}
              text={"The code you entered is incorrect. Please try again."}
            />
          ) : (
            ""
          )}
          <form
            className="body-container"
            onSubmit={handleSubmit}
          >
            <div>
              <Typography
                variant="small-text"
                className="field-name"
              >
                Verification Code
              </Typography>
              <Input
                type="text"
                placeholder="6-digit code"
                name="token"
                value={values.token}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.code && <small>{errors.code}</small>} */}
              <Typography
                variant="small-text"
                className="link-subtext"
              >
                <Button
                  className="linkStylee"
                  onClick={onResendEmail}
                  type="button"
                  disabled={remainingTime !== ""}
                >
                  Resend email {remainingTime ?? "- " + remainingTime}
                </Button>
              </Typography>
            </div>
            <div>
              <Button
                color="primary"
                className="next-button"
                type="submit"
                disabled={!isValid}
              >
                <Typography variant="button-text">{loading ? <SpinnerLoading /> : "Next"}</Typography>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerificationCodeDefault;
