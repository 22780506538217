import React, { useState } from "react";
import { Plus } from "react-feather";
import { Button, Typography } from "../../Base";
import "./styles.scss";

interface SingleTextCardProps {
  image?: string;
  heading: string | JSX.Element;
  description?: string;
  showIcon?: boolean;
  headingAlignment?: string;
}

const SingleTextCard: React.FC<SingleTextCardProps> = ({ image, heading, description, showIcon, headingAlignment }) => {
  const [showDescription, setShowDescription] = useState(false);
  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      toggleDescription();
    }
  };
  return (
    <>
      <div
        className={`bg-image-text-container`}
        onClick={description ? toggleDescription : undefined}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        {image && (
          <img
            src={image}
            alt=""
            className="image-style"
          />
        )}
        <div className={`image-headingg ${showDescription ? "show" : ""}`}>
          <div className="heading-icon">
            <Typography
              variant="body-1"
              className={`stc-heading ${headingAlignment}`}
            >
              {heading}
            </Typography>
          </div>
          {showDescription && (
            <div className={`stc-description `}>
              <Typography variant="small-text">{description}</Typography>
            </div>
          )}
        </div>
        <div className="icon-styling">
          {showIcon && (
            <Button
              className={`stc-button`}
              onClick={toggleDescription}
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <Plus className={`stc-icon ${showDescription ? "cross" : ""}`} />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleTextCard;
