import { useFormik } from "formik";
import { useState } from "react";
import { CheckCircle } from "react-feather";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { handleSignUp } from "../../../api/auth/auth.service";
import { logo } from "../../../assets";
import {
  Button,
  CustomAlert,
  Input,
  OptionsSelector,
  SpinnerLoading,
  Typography,
} from "../../../components/common/Base";
import { signUp } from "../../../store/reducers/auth.reducer";
import { validationSchemaCreateAccount } from "../../../utils/validationSchema";
import "./styles.scss";

interface Option {
  value: string;
  label: string;
}

const CreateAccountDefault = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const generateOptions = (start: number, end: number): Option[] => {
    const options: Option[] = [];
    for (let i = start; i <= end; i++) {
      options.push({ value: i.toString(), label: i.toString() });
    }
    return options;
  };

  const isPasswordLengthValid = (password: string) => {
    return password.length >= 8 && password.length <= 30;
  };

  const containsNumber = (password: string) => {
    return /^(?=.*[0-9])(?=.*[a-zA-Z]).+$/.test(password);
  };

  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const days = generateOptions(1, 31);
  const years = generateOptions(1990, 2024);
  // const [phone, setPhone] = useState();

  const { values, handleChange, handleBlur, handleSubmit, errors, setErrors, touched, isValid } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      month: "",
      day: "",
      year: "",
      submit: null,
    },
    validationSchema: validationSchemaCreateAccount,
    onSubmit: () => {
      const { month, day, year } = values;
      const { firstName, lastName, email, password } = values;

      if (!isValid) {
        return;
      }

      if (!isPasswordLengthValid(password) || !containsNumber(password)) {
        setErrors({ submit: "Invalid password" });
        return;
      }

      const birthDate = new Date(`${month} ${day} ${year}`);

      setLoading(true);
      handleSignUp({
        firstName,
        lastName,
        email,
        password,
        birthDate,
      })
        .then((response) => {
          const { uuid, firstName, lastName, email, validationTokenExpires } = response.data;
          console.log(response.data);

          dispatch(
            signUp({
              uuid,
              firstName,
              lastName,
              email,
              validationTokenExpires,
            }),
          );
          navigate("/auth/validation?method=account");
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message ?? err?.message ?? "An error occurred";
          setErrors({ submit: errorMessage });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="sign-up-container-create-account">
      <div className="left-container-create-account"></div>

      <div className="right-container-create-account">
        <div className="create-account-container">
          <img
            src={logo}
            alt="logo"
            className="logo"
          />
          <Typography
            variant="h3"
            className="create-account-text"
          >
            Create your Account
          </Typography>
          <form onSubmit={handleSubmit}>
            <div className="input">
              <Typography variant="small-text">First name</Typography>
              <Input
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.firstName}
                state={errors.firstName && touched.firstName ? "error" : ""}
              />
            </div>
            <div className="input">
              <Typography variant="small-text">Last name</Typography>
              <Input
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.lastName}
                state={errors.lastName && touched.lastName ? "error" : ""}
              />
            </div>
            <div className="input">
              <Typography variant="small-text">Email</Typography>
              <Input
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.email}
                state={errors.email && touched.email ? "error" : ""}
              />
            </div>
            <div className="body-container">
              <div className="password-input">
                <Typography
                  variant="small-text"
                  className="field-name"
                >
                  Password
                </Typography>
                <Input
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    <div className="helper-text">
                      <div
                        className={`helper-text-1 ${isPasswordLengthValid(values.password) && containsNumber(values.password) ? "hidden" : ""}`}
                      >
                        <div className="point-circle"></div>
                        <Typography
                          variant="small-text"
                          className={`hp-1-text ${isPasswordLengthValid(values.password) ? "hp-checked" : ""}`}
                        >
                          Between 8 to 30 characters
                        </Typography>
                      </div>
                      <div
                        className={`helper-text-2 ${isPasswordLengthValid(values.password) && containsNumber(values.password) ? "hidden" : ""}`}
                      >
                        <div className="point-circle"></div>
                        <Typography
                          variant="small-text"
                          className={`hp-2-text`}
                        >
                          At least one number (0-9)
                        </Typography>
                      </div>
                      <div
                        className={`helper-success ${isPasswordLengthValid(values.password) && containsNumber(values.password) ? "hp-success" : ""}`}
                      >
                        <CheckCircle className="check-icon" /> Now, that's a good password!
                      </div>
                    </div>
                  }
                />
              </div>
            </div>

            {/* <div className="input">
							<Typography variant="small-text">Phone number (Optional)</Typography>
							<Input type="tel" className="cfr-input-style" value={phone} name="phone"
								// TODO: check if this is correct
								// onChange={setPhone}
								onBlur={handleBlur} helperText={errors.phone} state={errors.phone && touched.phone ? 'error' : ''} maxLength={15} />
						</div> */}
            <div className="date-of-birth-container">
              <Typography variant="small-text">Date of birth</Typography>
              <Typography
                variant="small-text"
                className="date-of-birth-subtext"
              >
                Your birthday won’t be shown publicly
              </Typography>
            </div>
            <div className="month-date-year-container">
              <div>
                <Typography variant="small-text">Month</Typography>
                <OptionsSelector
                  options={months}
                  name="month"
                  value={values.month}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.month && (
                  <Typography
                    variant="small-text"
                    className="error"
                  >
                    {errors.month}
                  </Typography>
                )}
              </div>
              <div>
                <Typography variant="small-text">Day</Typography>
                <OptionsSelector
                  options={days}
                  name="day"
                  value={values.day}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.day && (
                  <Typography
                    variant="small-text"
                    className="error"
                  >
                    {errors.day}
                  </Typography>
                )}
              </div>
              <div>
                <Typography variant="small-text">Year</Typography>
                <OptionsSelector
                  options={years}
                  name="year"
                  value={values.year}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.year && (
                  <Typography
                    variant="small-text"
                    className="error"
                  >
                    {errors.year}
                  </Typography>
                )}
              </div>
            </div>
            <Typography
              variant="small-text"
              className="agreement-text"
            >
              By signing up, you agree to the{" "}
              <Link
                className="link-styling"
                to="#"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                className="link-styling"
                to="#"
              >
                Privacy Policy
              </Link>
              , including{" "}
              <Link
                className="link-styling"
                to="#"
              >
                Cookie Use.
              </Link>
            </Typography>
            {errors.submit && (
              <CustomAlert
                variant="danger"
                text={errors.submit}
              />
            )}
            <Button
              color="primary"
              className="button-next"
              type="submit"
              disabled={!isValid}
            >
              <Typography variant="button-text">{loading ? <SpinnerLoading /> : "Next"}</Typography>
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateAccountDefault;
