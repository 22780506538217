import React, { useEffect, useRef, useState } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Button, Select, Typography } from "../../../../components/common/Base";
import { SearchBar } from "../../../../components/common/Widgets";
import DetailsSelection from "../DetailsSelection";
import "./styles.scss";

const talentOptions = ["Actor", "Dancer", "Extra", "Influencer", "Stuntman", "UGC Creator"];
const productionOptions = ["Assistant Producer", "Location Manager", "PA", "Podcast Moderator"];
const directionOptions = [
  "AD",
  "Casting Director",
  "Choreographer",
  "Director",
  "Location Manager",
  "Script Supervisor",
  "Screenwriter",
  "1AD",
];
const cameraOptions = [
  "AC",
  "Camera Operator",
  "Content Creator",
  "DP",
  "Photographer",
  "Jib Operator",
  "Steadicam Operator",
  "Drone Operator",
  "2nd AC",
];
const artOptions = ["Art Director", "Art Department Coordinator", "Production Designer", "Prop Master", "Set Builder"];
const stylingOptions = [
  "Costume Designer",
  "Hair Stylist",
  "Makeup Artist",
  "SFX Makeup",
  "Stylist",
  "Wardrobe Assistant",
  "Wardrobe Supervisor",
];
const gandeOptions = ["Costume Designer", "Grip", "Key Grip", "Rigging grip"];
const postOptions = ["Colorist", "DIT", "Editor", "Motion Graphic", "Motion Graphic", "Pre Editor", "Photo Editor"];
const managementOptions = ["Manager"];

interface ExpertiseSelectionProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  handleNext: () => void;
}

const ExpertiseSelection: React.FC<ExpertiseSelectionProps> = ({ step, setStep, handleNext }) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Change 768 to your desired breakpoint
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleStepDotClick = (index: number) => setStep(index);
  const scrollToSection = () => {
    // Scrolls to the section
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleBack = () => {
    setStep((prevStep: number) => prevStep - 1);
    scrollToSection();
  };

  return (
    <>
      <div
        className="expertise-selection-container"
        ref={sectionRef}
      >
        <div className="expertise-selection-left">
          <Typography
            variant="pre-title"
            className="es-sub-title-1"
          >
            Our experts
          </Typography>
          <Typography
            variant="h1"
            className="es-main-heading"
          >
            Select your expertise.
          </Typography>
          <Typography
            variant="pre-title"
            className="es-sub-title-2"
          >
            Forreels.io welcomes all members of the media and entertainment industry to a revolutionary space. Step into
            the future with us.
          </Typography>
          <div className="previous-dot-next-buttons">
            <Button
              className="previous-button"
              onClick={handleBack}
            >
              <Typography variant="small-text">
                <ArrowLeft /> Previous
              </Typography>
            </Button>
            <div className="step-dots">
              {[0, 1, 2].map((index) => (
                <div
                  key={index}
                  className={`dot ${step === index ? "active" : ""}  ${step === 2 ? "third-ad-dots" : ""}`}
                  onClick={() => handleStepDotClick(index)}
                  onKeyDown={() => {
                    // TODO: dotIndex not exist
                    // if (e.key === 'Enter') {
                    // 	handleStepDotClick(dotIndex);
                    // }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <span className="sr-only">Step {index + 1}</span> {/* Screen reader-only text for accessibility */}
                </div>
              ))}
            </div>
            <Button
              className="next-button"
              onClick={handleNext}
            >
              <Typography variant="small-text">
                Next <ArrowRight />{" "}
              </Typography>
            </Button>
          </div>
        </div>
        <div className="expertise-selection-right">
          <div className="search-bar-co">
            <SearchBar
              inputPlaceholder="Search"
              icon={true}
            />
          </div>
          <div className="expertise-list">
            <Typography
              variant="pre-title"
              className="el-main-heading"
            >
              Talent
            </Typography>
            <Select options={talentOptions} />
          </div>
          <div className="expertise-list">
            <Typography
              variant="pre-title"
              className="el-main-heading"
            >
              Production
            </Typography>
            <Select options={productionOptions} />
          </div>
          <div className="expertise-list">
            <Typography
              variant="pre-title"
              className="el-main-heading"
            >
              Direction
            </Typography>
            <Select options={directionOptions} />
          </div>
          <div className="expertise-list">
            <Typography
              variant="pre-title"
              className="el-main-heading"
            >
              Camera
            </Typography>
            <Select options={cameraOptions} />
          </div>
          <div className="expertise-list">
            <Typography
              variant="pre-title"
              className="el-main-heading"
            >
              Art
            </Typography>
            <Select options={artOptions} />
          </div>
          <div className="expertise-list">
            <Typography
              variant="pre-title"
              className="el-main-heading"
            >
              Styling
            </Typography>
            <Select options={stylingOptions} />
          </div>
          <div className="expertise-list">
            <Typography
              variant="pre-title"
              className="el-main-heading"
            >
              G&E
            </Typography>
            <Select options={gandeOptions} />
          </div>
          <div className="expertise-list">
            <Typography
              variant="pre-title"
              className="el-main-heading"
            >
              Post
            </Typography>
            <Select options={postOptions} />
          </div>
          <div className="expertise-list">
            <Typography
              variant="pre-title"
              className="el-main-heading"
            >
              Management
            </Typography>
            <Select options={managementOptions} />
          </div>
        </div>
        {isSmallScreen && (
          <div className="expertise-selection-footer">
            <div className="previous-dot-next-buttons">
              <Button
                className="previous-button"
                onClick={handleBack}
              >
                <Typography variant="small-text">
                  <ArrowLeft /> Previous
                </Typography>
              </Button>
              <div className="step-dots">
                {[0, 1, 2].map((index) => (
                  <div
                    key={index}
                    className={`dot ${step === index ? "active" : ""}  ${step === 2 ? "third-ad-dots" : ""}`}
                    onClick={() => handleStepDotClick(index)}
                    onKeyDown={() => {
                      // TODO: dotIndex not exist
                      // if (e.key === 'Enter') {
                      // 	handleStepDotClick(dotIndex);
                      // }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="sr-only">Step {index + 1}</span> {/* Screen reader-only text for accessibility */}
                  </div>
                ))}
              </div>
              <Button
                className="next-button"
                onClick={handleNext}
              >
                <Typography variant="small-text">
                  Next <ArrowRight />{" "}
                </Typography>
              </Button>
            </div>
          </div>
        )}
      </div>
      {step === 2 && <DetailsSelection setStep={setStep} />}
    </>
  );
};

export default ExpertiseSelection;
