import React from "react";
import "./style.scss";
import Button from "../Button";
import { ReactComponent as PlusIcon2 } from "./../../../../assets/images/plusIcon2.svg";

interface ButtonWithIconProps {
  type: "submit" | "button" | "reset";
  onClick?: () => void;
  text: string;
  className?: string;
}

const AddNewButton: React.FC<ButtonWithIconProps> = ({ type, onClick, text, className }) => {
  return (
    <Button
      type={type}
      className={`buttonCard ${className}`}
      onClick={onClick}
    >
      <span className="icon">
        <PlusIcon2 />
      </span>
      <span className="text">{text}</span>
    </Button>
  );
};

export default AddNewButton;
