import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  creativeCollaborationImg7,
  haderbachLogo,
  landingPage1,
  landingPage2,
  landingPage3,
  landingPage4,
  landingPage5,
  landingPage6,
  landingPage7,
  landingPage8,
  landingPage9,
  landingPageVideo,
  music1,
  music2,
  music3,
  music4,
  music5,
  music7,
  seeStoriesVideo,
  vector1,
  vector2,
  vector3,
  vector4,
  vision1,
  vision2,
} from "../../../assets";
import { Button, CarouselSlider, Typography } from "../../../components/common/Base";
import "./style.scss";

import { motion, useScroll, useTransform } from "framer-motion";
import { ArrowLeft, ArrowRight, Play, X } from "react-feather";
import { BlueSection, CustomModal } from "../../../components/common/Layout";
import { CustomSlider, PercentageText, SingleTextCard } from "../../../components/common/Widgets";

const modalHeadings = [
  "Music Artists",
  "Influencers",
  "Models",
  "Actors",
  "Agencies",
  "Brands",
  "Startups",
  "Podcasters",
];
const data = [
  { title: "Music Artists", image: music1 },
  { title: "Influencers", image: music2 },
  { title: "Models", image: music3 },
  { title: "Actors", image: music4 },
  { title: "Agencies", image: music5 },
  { title: "Brands", image: creativeCollaborationImg7 },
  { title: "Startups", image: music7 },
  { title: "Podcasters", image: creativeCollaborationImg7 },
];
const itemList = [
  {
    heading: "Editors",
    image: landingPage1,
    description:
      "Post-production professionals, including editors, preeditors, photo editors, SFX artists, and motion graphic designers, can trust Forreels.io to provide them with consistent, high-quality opportunities that align with their skills and advance their careers.",
    showIcon: true,
  },
  {
    heading: "Talent",
    image: landingPage2,
    description:
      "Forreels.io simplifies the booking process for a diverse range of talents, such as dancers, stunt performers, influencers, choreographers, and models, enabling them to secure engagements effortlessly.",
    showIcon: true,
  },
  {
    heading: "Cinematographers",
    image: landingPage3,
    description:
      "Cinematographers, along with every member of the camera department, have the unique opportunity to streamline their booking processes, foster communication, and facilitate collaboration through a unified platform.",
    showIcon: true,
  },
  {
    heading: "Producers",
    image: landingPage4,
    description:
      "Forreels.io serves as a dynamic playground for producers and their production department, offering them a world of creative talent to collaborate on legendary projects around the globe.",
    showIcon: true,
  },
  {
    heading: "Directors",
    image: landingPage5,
    description:
      "Directors and the direction department gain unparalleled access to a vast network of filmmakers and clients worldwide, opening up a global arena of collaborative and client opportunities.",
    showIcon: true,
  },
  {
    heading: "Style and Art Dep.",
    image: landingPage6,
    description:
      "The entire styling team, encompassing makeup artists, hair stylists, the art department, and wardrobe professionals, enjoys global access to a wide range of work opportunities, connecting them with diverse projects around the world.",
    showIcon: true,
  },
  {
    heading: "Clients",
    image: landingPage7,
    description:
      "Clients and companies worldwide choose Forreels.io for their major projects, benefiting from access to a global network of thousands of creators. This platform connects them with a diverse pool of talent for their creative needs.",
    showIcon: true,
  },
  {
    heading: "Sound Department",
    image: landingPage8,
    description:
      "The sound department, including boom operators, sound engineers, composers, sound mixers, and Foley artists, effortlessly taps into global gigs, blending ease with opportunity in today's interconnected world.",
    showIcon: true,
  },
  {
    heading: "Marketing",
    image: landingPage9,
    description:
      "Marketing managers are provided with a platform that streamlines the organization and execution of major projects for their clients, ensuring seamless communication and impeccable organization.",
    showIcon: true,
  },
];

const ccItems = [
  {
    imageText: "Editors",
    image: landingPage1,
    description:
      "Post-production professionals, including editors, preeditors, photo editors, SFX artists, and motion graphic designers, can trust Forreels.io to provide them with consistent, high-quality opportunities that align with their skills and advance their careers.",
    showIcon: true,
  },
  {
    imageText: "Talent",
    image: landingPage2,
    description:
      "Forreels.io simplifies the booking process for a diverse range of talents, such as dancers, stunt performers, influencers, choreographers, and models, enabling them to secure engagements effortlessly.",
    showIcon: true,
  },
  {
    imageText: "Cinematographers",
    image: landingPage3,
    description:
      "Cinematographers, along with every member of the camera department, have the unique opportunity to streamline their booking processes, foster communication, and facilitate collaboration through a unified platform.",
    showIcon: true,
  },
  {
    imageText: "Producers",
    image: landingPage4,
    description:
      "Forreels.io serves as a dynamic playground for producers and their production department, offering them a world of creative talent to collaborate on legendary projects around the globe.",
    showIcon: true,
  },
  {
    imageText: "Directors",
    image: landingPage5,
    description:
      "Directors and the direction department gain unparalleled access to a vast network of filmmakers and clients worldwide, opening up a global arena of collaborative and client opportunities.",
    showIcon: true,
  },
  {
    imageText: "Style and Art Dep.",
    image: landingPage6,
    description:
      "The entire styling team, encompassing makeup artists, hair stylists, the art department, and wardrobe professionals, enjoys global access to a wide range of work opportunities, connecting them with diverse projects around the world.",
    showIcon: true,
  },
  {
    imageText: "Clients",
    image: landingPage7,
    description:
      "Clients and companies worldwide choose Forreels.io for their major projects, benefiting from access to a global network of thousands of creators. This platform connects them with a diverse pool of talent for their creative needs.",
    showIcon: true,
  },
  {
    imageText: "Sound Department",
    image: landingPage8,
    description:
      "The sound department, including boom operators, sound engineers, composers, sound mixers, and Foley artists, effortlessly taps into global gigs, blending ease with opportunity in today's interconnected world.",
    showIcon: true,
  },
  {
    imageText: "Marketing",
    image: landingPage9,
    description:
      "Marketing managers are provided with a platform that streamlines the organization and execution of major projects for their clients, ensuring seamless communication and impeccable organization.",
    showIcon: true,
  },
];

const LandingPage = () => {
  const mainRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: mainRef,
    offset: ["start start", "end start"],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [showIcon, setShowIcon] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [step, setStep] = useState(0);
  const handleNext = () => setStep((prevStep) => prevStep + 1);
  const handleBack = () => setStep((prevStep) => prevStep - 1);
  const imageRefs = useRef<HTMLImageElement[]>([]);
  const handleVideoEnded = () => setShowIcon(true);
  const handleVideoPlay = () => {
    if (videoRef.current) {
      videoRef.current.play(); // Play the video
      setShowIcon(false); // Hide the play button
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      setShowModal2(true);
    }
  };
  const handleOpenModal = () => setShowModal(true);
  const handleOpenModal2 = (stepIndex: number) => {
    setShowModal2(true);
    setStep(stepIndex);
  };
  const handleCloseModal = () => setShowModal(false);
  const handleCloseModal2 = () => setShowModal2(false);

  const handleMouseMove = (e: React.MouseEvent, index: number) => {
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    requestAnimationFrame(() => {
      imageRefs.current[index].style.left = `${offsetX}px`;
      imageRefs.current[index].style.top = `${offsetY}px`;
    });
  };
  const handleMouseLeave = (index: number) => {
    requestAnimationFrame(() => {
      imageRefs.current[index].style.left = "100px";
      imageRefs.current[index].style.top = "50%";
    });
  };

  return (
    <>
      <div className="landing-page-wrap">
        <div className="banner-sec-landingPage">
          <section className="banner-sec">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-9 col-md-10 ">
                  <motion.div
                    className="description"
                    ref={mainRef}
                    style={{ y: backgroundY }}
                  >
                    <div
                      className="desktop  h1"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                      data-aos-duration="500"
                    >
                      <Typography variant="h1">The ultra modern creative connection.</Typography>
                    </div>
                    <p
                      className="desktop banner-para"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                      data-aos-duration="500"
                    >
                      <Typography
                        variant="small-text"
                        className="banner-sec-subheading"
                      >
                        Forreels.io is the central hub for <span>hiring creatives worldwide</span> in the{" "}
                        <span>film and photography industry</span>, find <span>creative opportunities</span> as a
                        creative, <span>build</span>, organize and deliver your work so you that you{" "}
                        <span>create mind-blowing products</span> on time, every time.
                      </Typography>
                    </p>
                    <Button
                      className={`m-auto desktop animate__fadeInUp animate__animated animate__slow sign-up-button`}
                      color={"secondary"}
                      variant={"outlined"}
                    >
                      <Typography variant="button-text">Sign Up</Typography>
                    </Button>
                  </motion.div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="container video-section col-12">
          <div className="video-box">
            <video
              autoPlay
              muted
              loop
              className={`desktop animate__fadeInUp animate__animated animate__slow videoStyles`}
            >
              <track
                kind="captions"
                src=""
                label="English"
              />
              <source
                src={landingPageVideo}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </section>

        <section className="creative__collaboration">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="desktop  h1 cc-main-text"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  <Typography variant="h1">The platform for creative collaboration.</Typography>
                </div>
                <div
                  className="desktop "
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  <Typography
                    variant="small-text"
                    className="cc-subtitle-styling"
                  >
                    <span>Connecting clients</span> with the <span>most brilliant</span> creatives{" "}
                    <span>worldwide while building</span> more professional teams.{" "}
                  </Typography>
                </div>

                <div
                  className="container grid-container cc-desktop"
                  data-aos="zoom-out"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  {itemList.map((item) => (
                    <SingleTextCard
                      heading={item.heading}
                      image={item.image}
                      key={item.heading}
                      description={item.description}
                      showIcon={item.showIcon}
                    />
                  ))}
                </div>
                <div className="cc-mobile-slider">
                  <CarouselSlider images={ccItems} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="works-of-art">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div
                className="col-md-10"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="text-center desktop  h2">
                  <Typography
                    variant="h2"
                    className="woa-mainHeading"
                  >
                    THE UNFORGIVABLES
                  </Typography>
                </div>
                <div className="text-center desktop  h2">
                  <Typography
                    variant="h3"
                    className="woa-subHeading"
                  >
                    “Forreels.io brings a system for both client and creative to create the most magnificent works of
                    art.”
                  </Typography>
                </div>
                <div className="desktop ">
                  <Typography
                    variant="pre-title"
                    className="subtitle-styling w-100"
                  >
                    Allendra Freeman <span>- Director “The Unforgivables”</span>{" "}
                  </Typography>
                  <Typography
                    variant="pre-title"
                    className="subtitle-stylingg w-100"
                  >
                    Allendra Freeman
                    <br /> <span>Director “The Unforgivables”</span>{" "}
                  </Typography>
                </div>
                <Button
                  className="watch-video-button"
                  onClick={handleOpenModal}
                >
                  <div className="button-icon-container">
                    <div className="image-button">
                      <Play className="icon-style" />
                    </div>
                  </div>
                  <Typography variant="body-1">Watch the Trailer</Typography>
                </Button>
              </div>
            </div>
          </div>
        </section>

        <CustomModal
          show={showModal}
          handleClose={handleCloseModal}
          className="custom-modal-container"
          size="xl"
          ref={videoRef}
        >
          <div className="embed-responsive embed-responsive-16by9 video-container">
            <button
              className="button-styless"
              onClick={handleCloseModal}
            >
              <X className="icon-style" />
            </button>
            <video
              controls
              autoPlay
              className="video-styling"
              onEnded={handleVideoEnded}
              onPause={handleVideoEnded}
              onPlay={handleVideoPlay}
            >
              <track
                kind="captions"
                src=""
                label="English"
              />
              <source
                src={seeStoriesVideo}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            {showIcon && (
              <button
                className="center-play-icon-container"
                onClick={handleVideoPlay}
              >
                {/* Icon component or image */}
                <Play className="play-icon-style" />
              </button>
            )}
          </div>
        </CustomModal>

        <section className="create-your-series text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div
                  className=""
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  <div className="desktop cys-subtitle">
                    <Typography variant="pre-title">
                      <span>Booking and Reviews</span>
                    </Typography>
                  </div>
                  <div className="white desktop  h1">
                    <Typography
                      variant="h1"
                      className="cys-subtitlee"
                    >
                      Create your series. <br className="break" />
                      Find creatives for your masterpiece.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="finding-your-team">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="description desktop "
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  <div className="desktop ">
                    <Typography
                      variant="pre-title"
                      className="fyt-subtitle"
                    >
                      <span>Finding your team, for you.</span>
                    </Typography>
                  </div>
                  <div className="white desktop h1">
                    <Typography
                      variant="h1"
                      className="fyt-mainText"
                    >
                      Forreels.io<br></br>makes booking a breeze.
                    </Typography>
                  </div>
                  <div className="mb-4">
                    <Typography
                      variant="small-text"
                      className="fyt-subtitle-2"
                    >
                      <span>Sourcing reliable creatives for your next idea has never been breezier.</span>
                    </Typography>
                  </div>
                  <Button
                    className={`m-auto animate__animated animate__fast`}
                    color={"secondary"}
                    variant={"outlined"}
                  >
                    <Typography variant="button-text">Book a creative</Typography>
                  </Button>
                </div>
              </div>
              <div
                className="col-md-3"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="img-title">
                  <div className="img">
                    <img
                      className={`desktop animate__fadeInUp animate__animated animate__slow`}
                      src={vector1}
                      alt="VectorImg"
                    />
                  </div>
                  <Typography variant="body-2">Free and easy signup</Typography>
                </div>
                <div>
                  <Typography
                    variant="body-2"
                    className="mt-1"
                  >
                    <span>No annual or monthly charges required to signup.</span>
                  </Typography>
                </div>
              </div>
              <div
                className="col-md-3"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="img-title">
                  <div className="img">
                    <img
                      className={`desktop animate__fadeInUp animate__animated animate__slow`}
                      src={vector2}
                      alt="VectorImg"
                    />
                  </div>
                  <Typography variant="body-2">Pre-vetted creatives</Typography>
                </div>
                <div>
                  <Typography
                    variant="body-2"
                    className="mt-1"
                  >
                    <span>Creatives with verified reputation and high quality work. </span>
                  </Typography>
                </div>
              </div>
              <div
                className="col-md-3"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="img-title">
                  <div className="img">
                    <img
                      className={`desktop animate__fadeInUp animate__animated animate__slow`}
                      src={vector3}
                      alt="VectorImg"
                    />
                  </div>
                  <Typography variant="body-2">Smart</Typography>
                </div>
                <div>
                  <Typography
                    variant="body-2"
                    className="mt-1"
                  >
                    <span>Intelligent and intuitive software built for structure and convenience.</span>
                  </Typography>
                </div>
              </div>
              <div
                className="col-md-3"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="img-title">
                  <div className="img">
                    <img
                      className={`desktop animate__fadeInUp animate__animated animate__slow`}
                      src={vector4}
                      alt="VectorImg"
                    />
                  </div>
                  <Typography variant="body-2">Ensured delivery</Typography>
                </div>
                <div>
                  <Typography
                    variant="body-2"
                    className="mt-1"
                  >
                    <span>Providing a visual timeline on delivery expectation.</span>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="glow-up">
          <div className="container">
            <div className="row">
              <div className="col-md-11">
                <div
                  className="description desktop "
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  <div>
                    <Typography
                      variant="pre-title"
                      className=" gu-subtitle"
                    >
                      <span>Convenience</span>
                    </Typography>
                  </div>
                  <div className="white gu-mainHeading">
                    <Typography
                      variant="h1"
                      className="styling h1"
                    >
                      <b>
                        Your glow up<br></br>is here.
                      </b>
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="small-text"
                      className="glow-up-subtitle-styling"
                    >
                      Do you consider yourself a creative with a <span>brilliant idea</span> but find yourself{" "}
                      <span>lacking the resources</span> to craft a <span>captivating self-image</span> that entices
                      your audience to click and engage with your content? If so, we have the solution to{" "}
                      <span>elevate your presence</span> and make your <span>potential truly shine</span> through. Your{" "}
                      <b>"Glow-up"</b> is where <span>potential meets aesthetics</span>.
                    </Typography>
                  </div>
                </div>
              </div>
              <div
                className="col-md-5 "
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <Typography
                  variant="h2"
                  className="styling h3"
                >
                  Ready, set, <b>glow.</b>
                </Typography>
                <Typography
                  variant="small-text"
                  className="glow-up-subtitle-styling-2"
                >
                  <span>In the sky, the glow is why some stars stand out and others don’t.</span>
                </Typography>
              </div>

              <div
                className="col-md-6 glow-up-names"
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <ul className="ah-music-sec">
                  {data.map((item, index) => (
                    <>
                      <li
                        key={index}
                        onMouseMove={(e) => handleMouseMove(e, index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                      >
                        <Button
                          onClick={() => {
                            handleOpenModal2(index);
                          }}
                        >
                          <Typography
                            variant="h1"
                            className="h1"
                          >
                            {item.title}
                          </Typography>
                        </Button>
                        <Button
                          className={`desktop`}
                          onClick={() => {
                            handleOpenModal2(index);
                          }}
                          onKeyDown={(event) => {
                            handleKeyPress(event);
                          }}
                        >
                          <img
                            ref={(ref) => {
                              if (ref) {
                                imageRefs.current[index] = ref;
                              }
                            }}
                            src={item.image}
                            alt={`GalleryImage${index}`}
                          />
                        </Button>
                      </li>
                      <br></br>
                    </>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div className="lp-custom-slider">
          <CustomSlider
            slides={[
              {
                image: music1,
                text: "““Forreels.io is unrivalled when it comes to sourcing reliable SFX artists and locations for The Unforgivables.",
                subtext: (
                  <>
                    Allendra Freeman
                    <br />
                    <span>Director "The Unforgivables"</span>
                  </>
                ),
              },
              {
                image: music2,
                text: "““Forreels.io is unrivalled when it comes to sourcing reliable SFX artists and locations for The Unforgivables.",
                subtext: (
                  <>
                    Allendra Freeman
                    <br />
                    <span>Director "The Unforgivables"</span>
                  </>
                ),
              },
              {
                image: music3,
                text: "““Forreels.io is unrivalled when it comes to sourcing reliable SFX artists and locations for The Unforgivables.",
                subtext: (
                  <>
                    Allendra Freeman
                    <br />
                    <span>Director "The Unforgivables"</span>
                  </>
                ),
              },
              {
                image: music4,
                text: "““Forreels.io is unrivalled when it comes to sourcing reliable SFX artists and locations for The Unforgivables.",
                subtext: (
                  <>
                    Allendra Freeman
                    <br />
                    <span>Director "The Unforgivables"</span>
                  </>
                ),
              },
              {
                image: music5,
                text: "““Forreels.io is unrivalled when it comes to sourcing reliable SFX artists and locations for The Unforgivables.",
                subtext: (
                  <>
                    Allendra Freeman
                    <br />
                    <span>Director "The Unforgivables"</span>
                  </>
                ),
              },
              {
                image: creativeCollaborationImg7,
                text: "““Forreels.io is unrivalled when it comes to sourcing reliable SFX artists and locations for The Unforgivables.",
                subtext: (
                  <>
                    Allendra Freeman
                    <br />
                    <span>Director "The Unforgivables"</span>
                  </>
                ),
              },
              {
                image: music7,
                text: "““Forreels.io is unrivalled when it comes to sourcing reliable SFX artists and locations for The Unforgivables.",
                subtext: (
                  <>
                    Allendra Freeman
                    <br />
                    <span>Director "The Unforgivables"</span>
                  </>
                ),
              },
              {
                image: creativeCollaborationImg7,
                text: "““Forreels.io is unrivalled when it comes to sourcing reliable SFX artists and locations for The Unforgivables.",
                subtext: (
                  <>
                    Allendra Freeman
                    <br />
                    <span>Director "The Unforgivables"</span>
                  </>
                ),
              },
            ]}
          />
        </div>
        <CustomModal
          show={showModal2}
          handleClose={handleCloseModal2}
          size="xl"
          className="custom-modal-container-2 "
        >
          <div className="header-container">
            <img
              src={
                step === 0
                  ? music1
                  : step === 1
                    ? music2
                    : step === 2
                      ? music3
                      : step === 3
                        ? music4
                        : step === 4
                          ? music5
                          : step === 5
                            ? creativeCollaborationImg7
                            : step === 6
                              ? music7
                              : step === 7
                                ? creativeCollaborationImg7
                                : ""
              }
              alt="modal-view"
            />
            <div className="header-modal">
              <div
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <Typography variant="pre-title">{modalHeadings[step]}</Typography>
              </div>
              <button
                className="button-styless"
                onClick={handleCloseModal2}
              >
                <X className="icon-stylee" />
              </button>
            </div>
            <div
              className="body-modal"
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <Typography
                variant="h3"
                className="bm-1-text"
              >
                “C2C is creating a think tank in your pocket. Your closest collaborators iterate faster.”
              </Typography>
              <div>
                <Typography
                  variant="body-1"
                  className="bm-2-text"
                >
                  Ryan Connolly -- Dir. Host of Film Riot
                </Typography>
              </div>
            </div>
            <div
              className="footer-modal"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <div className="divider-line"></div>
              <div className="prev-next-buttons">
                <Button
                  className="prev-button"
                  onClick={handleBack}
                  disabled={step === 0}
                >
                  {step === 0 ? (
                    <Typography variant="small-text">
                      <ArrowLeft />
                      Previous{" "}
                    </Typography>
                  ) : (
                    <Typography variant="small-text">
                      <span>
                        <ArrowLeft />
                        {modalHeadings[step - 1]} --
                      </span>{" "}
                      Previous{" "}
                    </Typography>
                  )}
                </Button>
                <Button
                  className="next-button"
                  onClick={handleNext}
                  disabled={step === 7}
                >
                  {step === 7 ? (
                    <Typography variant="small-text">
                      {" "}
                      Next <ArrowRight />
                    </Typography>
                  ) : (
                    <Typography variant="small-text">
                      {" "}
                      Next{" "}
                      <span>
                        -- {modalHeadings[step + 1]} <ArrowRight />
                      </span>
                    </Typography>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
        <section className="the-glow">
          <div className="container">
            <div className="row justify-content-between">
              <div
                className="col-md-5"
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="description">
                  <div>
                    <Typography
                      variant="pre-title"
                      className="tg-subtitle"
                    >
                      Give your content a facebeat.
                    </Typography>
                  </div>
                  <Typography
                    variant="h1"
                    className="white h1 tg-mainHeading"
                  >
                    The Glow
                  </Typography>
                  <Typography
                    variant="small-text"
                    className="the-glow-subtext "
                  >
                    Our team of creative <span>geniuses provide</span> you with all the <span>expertise</span> that you
                    need so that can focus on your art. Avoid those artist growth pains and bad first impressions by{" "}
                    <span>hacking the system</span> with our solutions from veteran, <span>industry professionals</span>{" "}
                    used by <span>industry artists and professionals</span> of all kinds.
                  </Typography>
                </div>
              </div>
              <div
                className="col-md-6 "
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="counter-sec">
                  <div className="white">
                    <PercentageText
                      number={40}
                      text={"more subscriptions follows, and likes."}
                    />
                  </div>
                </div>
                <div className="counter-sec">
                  <div className="white">
                    <PercentageText
                      number={40}
                      text={"more subscriptions follows, and likes."}
                    />
                  </div>
                </div>
                <div className="counter-sec">
                  <div className="white">
                    <PercentageText
                      number={40}
                      text={"more subscriptions follows, and likes."}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="guid-your-vision">
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <div
                  className="description desktop "
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  <Typography
                    variant="pre-title"
                    className="gyv-subtext-1"
                  >
                    Building <span>teams.</span>
                  </Typography>
                  <Typography
                    className="white h1 gyv-mainText"
                    variant="h1"
                  >
                    SuperProducers<span className="r-symbol">®</span>,
                    <Typography
                      variant="h1"
                      className="gyv-mainText"
                    >
                      guide your vision.
                    </Typography>
                  </Typography>
                  <Typography
                    variant="small-text"
                    className="gyv-subtext-2"
                  >
                    <span>
                      From idea to export our Super Producers are here to help your story come to life. Super Producers
                      are similar to contractors, they already have their team in place so you can start your project
                      today.
                    </span>
                  </Typography>
                  <Link
                    className="d-link"
                    to="#"
                  >
                    <Typography
                      variant="body-1"
                      className="link-button-styles"
                    >
                      See their story <i>&#8594;</i>
                    </Typography>
                  </Link>
                </div>
              </div>
              <div
                className="col-md-6 "
                data-aos="zoom-in"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="box ">
                  <div className="img-box">
                    <img
                      className={`desktop gyv-image`}
                      src={vision1}
                      alt="vision1"
                    />
                  </div>
                  <Typography
                    variant="subtitle"
                    className="img-box-subtitle"
                  >
                    Producer Mel Thomas shares her vetting process on hiring her crew.
                  </Typography>
                  <Link
                    className="d-link"
                    to="#"
                  >
                    <Typography
                      variant="link-text"
                      className="link-text-style"
                    >
                      See their story <i>&#8594;</i>
                    </Typography>
                  </Link>
                </div>
              </div>
              <div
                className="col-md-6 ss-button"
                data-aos="zoom-in"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="box">
                  <div className="img-box">
                    <img
                      className={`desktop gyv-image`}
                      style={{ width: "100%" }}
                      src={vision2}
                      alt="vision2"
                    />
                  </div>
                  <Typography variant="pre-title">
                    Post production house CEO, Cutting Colour dives deep into the coloring for “Revive”.
                  </Typography>
                  <Link
                    className="d-link"
                    to="#"
                  >
                    <Typography
                      variant="link-text"
                      className="link-text-style"
                    >
                      See their story <i>&#8594;</i>
                    </Typography>
                  </Link>
                </div>
              </div>
              <div className="text-center bas-button">
                <Button
                  className={`m-auto desktop `}
                  color={"secondary"}
                  variant={"outlined"}
                >
                  <Typography variant="button-text">Book a SuperProducer®</Typography>
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section className="meets-structure">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6"
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="structure-sec">
                  {" "}
                  <Typography
                    variant="pre-title"
                    className="heading-styles"
                  >
                    Pre-production
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">
                        Create then greenlight treatments, screenplays and concepts
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Location scouting and auditions</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">
                        Approve schedule, crew, talent, locations, transport, permits and gear
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Confirm shotlist </Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Light rehersal, blocking and choreography</Typography>
                    </li>
                  </ul>
                  <Typography
                    variant="pre-title"
                    className="heading-styles"
                  >
                    Production
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body-1">
                        Create call sheets, plan craft services and arrange transport
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Production date </Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share daliies and raws</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Plan and shoot pickups </Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-md-6 desktop"
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="structure-sec">
                  <Typography
                    variant="pre-title"
                    className="heading-styles"
                  >
                    Post-production
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                  </ul>
                  <Typography
                    variant="pre-title"
                    className="heading-styles"
                  >
                    Ensured delivery
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Share reel and vision board to future clients</Typography>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="game-changing-location description desktop ">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <div
                  className=""
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  <Typography
                    variant="pre-title"
                    className="gcl-subtitle"
                  >
                    <span>Locations and scouts</span>
                  </Typography>
                  <Typography
                    variant="h1"
                    className="white h1"
                  >
                    Game changing<br></br>locations.
                  </Typography>
                  <Typography
                    variant="small-text"
                    className="gcl-subtext"
                  >
                    <span>
                      Use Forreels.io location scouts to boost the production value of your next film, way way up.
                    </span>
                  </Typography>
                  <Link
                    className="d-link"
                    to="#"
                  >
                    <Typography
                      variant="link-text"
                      className="link-text-style"
                    >
                      See their story <i>&#8594;</i>
                    </Typography>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="haderbach-sec">
          <div className="container">
            <div className="row hb-allText">
              <div className="col-md-7">
                <div
                  className="description description desktop"
                  data-aos="fade-right"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  <Typography
                    variant="h3"
                    className="hb-mainText"
                  >
                    HADERBACH
                  </Typography>
                  <Typography
                    variant="h3"
                    className="hb-text"
                  >
                    “The locations that we have discovered from using Forreels.io made the film much more outstanding
                    than we could’ve imagined. “
                  </Typography>
                  <Typography
                    variant="body-1"
                    className="haderbach-text"
                  >
                    <b>Johnathon Furr</b> - Producer, Haderbach
                  </Typography>
                  <Typography
                    variant="body-1"
                    className="haderbach-textt"
                  >
                    <b>Johnathon Furr</b> <br />
                    Producer, Haderbach
                  </Typography>
                </div>
              </div>
              <div
                className="col-md-6 description desktop "
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <img
                  className={`desktop hb-image`}
                  style={{ width: "100%" }}
                  src={haderbachLogo}
                  alt="GalleryImage1"
                />
              </div>
              <div
                className="col-md-6 description desktop "
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <Typography
                  variant="body-1"
                  className="text-center hb-lastText"
                >
                  The Fairy Pools, Isle of Skye, Scotland
                </Typography>
              </div>
            </div>
          </div>
        </section>

        <BlueSection className="blue-section-styles description desktop ">
          <div className="section-text">
            <Typography
              variant="h2"
              className="section-text-style"
            >
              Join the 250,000 who collaborate with Forreels.io creatives.
            </Typography>
          </div>
        </BlueSection>
      </div>
    </>
  );
};

export default LandingPage;
