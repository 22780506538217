import React from "react";
import { Footer, MenuNavbar } from "../components/common/Layout";
import { AccountsDefault } from "../components/features";

const Accounts = () => (
  <>
    <MenuNavbar />
    <AccountsDefault />
    <Footer showLargeFooter />
  </>
);

export default Accounts;
