import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ArrowLeft } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleChooseMethod } from "../../../api/auth/auth.service";
import { logo } from "../../../assets";
import { Button, RadioButton, SpinnerLoading, Typography } from "../../../components/common/Base";
import { signUp } from "../../../store/reducers/auth.reducer";
import "./styles.scss";

const ChooseMethodDefault = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    method: "",
    submit: "",
    email: "",
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    if (email) {
      setValues({ ...values, email: email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const { values, handleChange, handleBlur, handleSubmit, errors, setErrors, setValues } = useFormik({
    initialValues,
    onSubmit: () => {
      setLoading(true);
      handleChooseMethod({ email: values.email, type: values.method })
        .then((response) => {
          const { uuid } = response.data;
          dispatch(
            signUp({
              otpId: uuid,
            }),
          );
          navigate("/auth/validation?method=forgot");
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message || err?.message || "An error occurred";
          setErrors({ submit: errorMessage });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="choose-method-container">
      <div className="cmc-left-container"></div>
      <div className="cmc-right-container">
        <Button
          className="back-button"
          onClick={() => {
            window.history.back();
          }}
        >
          <ArrowLeft className="arrow-icon" />
          <Typography variant="small-text"> Back</Typography>
        </Button>
        <form
          className="cmc-align-center"
          onSubmit={handleSubmit}
        >
          <img
            src={logo}
            alt="logo"
            className="logo"
          />
          <Typography
            variant="h3"
            className="cmc-text"
          >
            Where should we send a confirmation code?
          </Typography>
          <Typography
            variant="small-text"
            className="cmc-sub-text"
          >
            Enter the email, phone number, or username associated with your account to change your password.
          </Typography>
          <div className="radio-text-1">
            <RadioButton
              value="email"
              name="method"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Typography
              variant="small-text"
              className="cmc-radio-1-text"
            >
              Send an email to pa**********@g****.***
            </Typography>
          </div>
          <div className="radio-text-2">
            <RadioButton
              value="phone"
              name="method"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Typography
              variant="small-text"
              className="cmc-radio-1-text"
            >
              Text a code to the phone number ending in 1234
            </Typography>
          </div>
          <div>
            <Button
              color="primary"
              className="cmc-next-button"
              type="submit"
              disabled={!values.method || errors.submit == undefined}
            >
              <Typography variant="button-text">{loading ? <SpinnerLoading /> : "Next"}</Typography>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChooseMethodDefault;
