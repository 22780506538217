import { KeyboardEvent, SetStateAction, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Typography } from "../../Base";
import "./style.scss";

// TODO: sync img and image
interface CustomSliderProps {
  slides: {
    img?: string;
    heading?: string;
    text: string;
    subtext?: string | JSX.Element;
    title?: string;
    image?: string;
    personName?: string;
    designation?: string;
  }[];
  centerMode?: boolean;
}

const CustomSlider: React.FC<CustomSliderProps> = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleDotClick = (index: SetStateAction<number>) => {
    setCurrentSlide(index);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, index: SetStateAction<number>) => {
    if (event.key === "Enter" || event.key === " ") {
      setCurrentSlide(index);
    }
  };
  const settings = {
    dots: true,
    customPaging: function (i: SetStateAction<number>) {
      return (
        <div
          className={`custom-dot ${i === currentSlide ? "active" : ""}`}
          onClick={() => handleDotClick(i)}
          onKeyDown={(event) => handleKeyDown(event, i)}
          role="button"
          tabIndex={0}
        ></div>
      );
    },
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      {slides.map((item, index) => (
        <div key={index}>
          <img
            src={item.image}
            alt="hh"
            className="slider-image"
          />
          <div className="text-containerr">
            <Typography
              variant="h2"
              className="slider-heading"
            >
              {item.heading}
            </Typography>
            <Typography
              variant="pre-title"
              className="slider-text"
            >
              {item.text}
            </Typography>
            <Typography
              variant="small-text"
              className="slider-subtext"
            >
              {item.subtext}
            </Typography>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default CustomSlider;
