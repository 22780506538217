import Card from "../../Base/Card";
import "./style.scss";
import { ReactComponent as ProjectCard } from "./../../../../assets/icons/projectcard.svg";
import { ReactComponent as CalendarIcon } from "./../../../../assets/icons/calendarIcon.svg";
import { ReactComponent as ProductionICon } from "./../../../../assets/icons/productionIcon.svg";

import EmptyList from "../../Base/EmptyList";

interface ProductionInterFace {
  id?: number;
  title?: string;
  date?: string;
  taskCount?: number;
  status?: string;
  shotData?: string;
}

interface ProductionListProps {
  productions: ProductionInterFace[];
}

const ProductionList: React.FC<ProductionListProps> = ({ productions }) =>
  productions.length > 0 ? (
    <div className="taskListMain">
      {productions.map((production, index) => {
        return (
          <Card
            key={index}
            className="taskListCard-main"
          >
            <div className="status">Active</div>
            <div className="project-card">
              <div className="project-img">
                <ProjectCard />
              </div>
              <div className="cardContent">
                <h1>{production.title}</h1>
                <div className="date">
                  <CalendarIcon />
                  <span>Apr 28, 2024</span>
                </div>
                <div className="divider"></div>
                <div className="tasks">
                  <span>10 tasks</span> &bull; <span>26 days to shot date</span>
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  ) : (
    <EmptyList
      text="No production yet."
      icon={<ProductionICon />}
      className="emptyTaskCard"
    />
  );

export default ProductionList;
