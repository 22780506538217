import { useSelector } from "react-redux";
import {
  PaymentDashboardClientsDefault,
  PaymentDashboardCreatorDefault,
  PaymentDashboardSuperProducerDefault,
} from "src/components/features";

import { RootState } from "src/store";

const Payment = () => {
  const type = useSelector((state: RootState) => state.auth.type);
  switch (type) {
    case "User":
      return <PaymentDashboardClientsDefault />;
    case "Creator":
      return <PaymentDashboardCreatorDefault />;
    case "Super Producer":
      return <PaymentDashboardSuperProducerDefault />;
    default:
      return <PaymentDashboardClientsDefault />;
  }
};
export default Payment;
