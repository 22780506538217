import { Footer, SimpleNavbar } from "../components/common/Layout";
import { SubscriptionConfirmationDefault } from "../components/features";

const SubscriptionConfirmation = () => (
  <>
    <SimpleNavbar />
    <SubscriptionConfirmationDefault />
    <Footer />
  </>
);

export default SubscriptionConfirmation;
