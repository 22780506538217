// PaymentHistoryCard.tsx

import React from "react";
import "./style.scss";
import defaultPaymentHistoryImage from "./../../../../assets/images/payment-upcoming.png";
interface PaymentHistory {
  image: string;
  price: string | number;
  currency: string;
  scheduledDate: string;
  email: string;
  cardType: string;
  productionDate: string;
}

interface PaymentHistoryCardProps {
  paymentHistory: PaymentHistory;
}

const PaymentHistoryCard: React.FC<PaymentHistoryCardProps> = ({ paymentHistory }) => {
  return (
    <div className="card-payment">
      <div className="card-image">
        <img
          src={paymentHistory.image ? paymentHistory.image : defaultPaymentHistoryImage}
          alt="Payment Icon"
        />
      </div>
      <div className="card-content">
        <div className="card-header">
          <h3>Scheduled {paymentHistory.scheduledDate}</h3>
          <p className="card-email">{`${paymentHistory.cardType} ${paymentHistory.email}`}</p>
          <p>Production {paymentHistory.productionDate}</p>
        </div>
      </div>
      <div className="card-body">
        <h3 className="card-price">¥{paymentHistory.price} </h3>
        <p className="currency">{paymentHistory.currency}</p>
      </div>
    </div>
  );
};

export default PaymentHistoryCard;
