import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ArriLogo, logoBlue, MortonLogo, RedLogo, textLogoBlue, ZeissLogo } from "../../../assets";
import "./styles.scss";

import { useFormik } from "formik";
import { Check } from "react-feather";
import { handleContactForm, handleGetContactFormLists } from "../../../api/contact/contact.service";
import { Button, CheckBox, Input, OptionsSelector, SpinnerLoading, Typography } from "../../../components/common/Base";
import countriesData from "../../../utils/countries.json";
import { validationSchemaContactForm } from "../../../utils/validationSchema";

const ContactFormDefault = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    phone: "",
    country: "",
    companySize: "",
    videoDescription: "",
    roleDescription: "",
    helpDescription: "",
    agreement: "",
  };
  // const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);
  const [videoDescriptions, setVideoDescriptions] = useState([]);
  const [roleDescriptions, setRoleDescriptions] = useState([]);
  const [companySizes, setCompanySizes] = useState([]);
  const navigate = useNavigate();
  const { values, handleChange, handleBlur, handleSubmit, errors } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaContactForm,
    onSubmit: () => {
      setLoading(true);
      handleContactForm({
        first_name: values.firstName,
        last_name: values.lastName,
        business_email: values.email,
        company_name: values.companyName,
        country: values.country,
        phone_number: values.phone,
        comapny_size: values.companySize,
        looking_to_do_video: values.videoDescription,
        option_best_describe: values.roleDescription,
        help_you_today: values.helpDescription,
        agree_to_newsletter: values.agreement,
      })
        .then(() => {
          navigate("/contact-form-confirmation");
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const countries = countriesData.map((item) => ({ label: item.title }));

  useEffect(() => {
    handleGetContactFormLists()
      .then((response) => {
        // TODO: Handle response
        const { data } = response;
        const companySizes = data
          .find((item: { name: string }) => item.name === "Company Size")
          .subcategories.map((subcategory: { id: string; name: string }) => ({
            value: subcategory.id,
            label: subcategory.name,
          }));

        const videoDescriptions = data
          .find((item: { name: string }) => item.name === "Looking to do with Video")
          .subcategories.map((subcategory: { id: string; name: string }) => ({
            value: subcategory.id,
            label: subcategory.name,
          }));

        const roleDescriptions = data
          .find((item: { name: string }) => item.name === "describe your role")
          .subcategories.map((subcategory: { id: string; name: string }) => ({
            value: subcategory.id,
            label: subcategory.name,
          }));

        setCompanySizes(companySizes);
        setVideoDescriptions(videoDescriptions);
        setRoleDescriptions(roleDescriptions);
      })
      .catch(() => {});
  }, []);
  return (
    <>
      <div className="contact-form-container">
        <div className="contact-form_navbar">
          <Link
            className="navbar-brandd"
            to="/"
          >
            <>
              <img
                src={logoBlue}
                alt="Logo"
                width={40}
                height={40}
                className="img-fluid logo me-3"
              />
              <img
                src={textLogoBlue}
                alt="Text logo"
                className="img-fluid text-logo"
              />
            </>
          </Link>

          <div className="skip-button-container">
            <Button className={`skip-button`}>
              <Typography variant="button-text">Skip</Typography>
            </Button>
          </div>
        </div>
        <div className="container contact-form-body">
          <div className="contact-form-left-container">
            <Typography
              variant="h1"
              className="cf-main-heading"
            >
              Connect with our team
            </Typography>
            <Typography
              variant="pre-title"
              className="cf-subtitle"
            >
              Our team is happy to answer your questions about our video tools.
            </Typography>
            <div className="cf-features-container">
              <div className="cf-icon-text-container">
                <div className="circle-icon">
                  <Check className="check-icon" />
                </div>
                <Typography variant="small-text">How your business uses video</Typography>
              </div>
              <div className="cf-icon-text-container">
                <div className="circle-icon">
                  <Check className="check-icon" />
                </div>
                <Typography variant="small-text">Get pricing information</Typography>
              </div>
              <div className="cf-icon-text-container">
                <div className="circle-icon">
                  <Check className="check-icon" />
                </div>
                <Typography variant="small-text">Learn about other great brands using Forreels</Typography>
              </div>
            </div>

            <div className="cf-text-logos-container">
              <Typography
                variant="pre-title"
                className="cf-subtitle"
              >
                Our team is happy to answer your questions about our video tools.
              </Typography>
              <div className="cf-logos">
                <img
                  src={ArriLogo}
                  alt="arri-logo"
                />
                <img
                  src={ZeissLogo}
                  alt="zeiss-logo"
                />
                <img
                  src={MortonLogo}
                  alt="red-logo"
                />
                <img
                  src={RedLogo}
                  alt="morton-logo"
                />
              </div>
            </div>
          </div>
          <div className="contact-form-right-container">
            <form
              className="cfr-form-container"
              onSubmit={handleSubmit}
            >
              <div>
                <Typography variant="small-text">First Name</Typography>
                <Input
                  type="text"
                  className="cfr-input-style"
                  value={values.firstName}
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.firstName}
                  state={errors.firstName ? "error" : ""}
                />
              </div>
              <div className="label-text-container">
                <Typography variant="small-text">Last name</Typography>
                <Input
                  type="text"
                  className="cfr-input-style"
                  value={values.lastName}
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.lastName}
                  state={errors.lastName ? "error" : ""}
                />
              </div>
              <div className="label-text-container">
                <Typography variant="small-text">Business email</Typography>
                <Input
                  type="email"
                  className="cfr-input-style"
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.email}
                  state={errors.email ? "error" : ""}
                />
              </div>
              <div className="label-text-container">
                <Typography variant="small-text">Company name</Typography>
                <Input
                  type="text"
                  className="cfr-input-style"
                  value={values.companyName}
                  name="companyName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.companyName}
                  state={errors.companyName ? "error" : ""}
                />
              </div>
              {/* <div className="label-text-container">
                <Typography variant="small-text">Phone number (Optional)</Typography>
                <Input
                  type="tel"
                  className="cfr-input-style"
                  value={phone}
                  name="phone"
                  // TODO: check if this is correct
                  // onChange={setPhone}
                  onBlur={handleBlur}
                  helperText={errors.phone}
                  state={errors.phone ? "error" : ""}
                  maxLength={15}
                />
              </div> */}
              <div className="label-text-container">
                <Typography variant="small-text">Country</Typography>
                <OptionsSelector
                  options={countries}
                  value={values.country}
                  className="cfr-input-style"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="country"
                  helperText={errors.country}
                  state={errors.country ? "error" : ""}
                />
              </div>
              <div className="label-text-container">
                <Typography variant="small-text">Company size</Typography>
                <OptionsSelector
                  options={companySizes}
                  value={values.companySize}
                  className="cfr-input-style"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="companySize"
                  helperText={errors.companySize}
                  state={errors.companySize ? "error" : ""}
                />
              </div>
              <div className="label-text-container">
                <Typography variant="small-text">What are you looking to do with video?</Typography>
                <OptionsSelector
                  options={videoDescriptions}
                  value={values.videoDescription}
                  className="cfr-input-style"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="videoDescription"
                  helperText={errors.videoDescription}
                  state={errors.videoDescription ? "error" : ""}
                />
              </div>
              <div className="label-text-container">
                <Typography variant="small-text">Which option best describes you role?</Typography>
                <OptionsSelector
                  options={roleDescriptions}
                  value={values.roleDescription}
                  className="cfr-input-style"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="roleDescription"
                  helperText={errors.roleDescription}
                  state={errors.roleDescription ? "error" : ""}
                />
              </div>
              <div className="label-text-container">
                <Typography variant="small-text">How can we help you today?</Typography>
                <Input
                  type="textarea"
                  className="cfr-input-style cfr-text-area"
                  value={values.helpDescription}
                  name="helpDescription"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.helpDescription}
                  state={errors.helpDescription ? "error" : ""}
                />
              </div>
              <div className="cf-icon-text-agreement-error-container">
                <div className="cf-icon-text-agreement-container">
                  <CheckBox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.agreement}
                    name="agreement"
                  />
                  <Typography
                    variant="small-text"
                    className="agreement-text"
                  >
                    I agree to receive newsletters, product updates, and exclusive offers from Forreels.
                  </Typography>
                </div>
                {errors.agreement ? (
                  <Typography
                    className="agreement-error"
                    variant="small-text"
                  >
                    {errors.agreement}
                  </Typography>
                ) : (
                  ""
                )}
              </div>
              <div className="contact-our-team-button">
                <Button
                  color="primary"
                  type="submit"
                  className="cot-button"
                >
                  <Typography variant="button-text">{loading ? <SpinnerLoading /> : "Contact our team"}</Typography>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactFormDefault;
