import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { creatorOnBoardingAvatar, logoBlue, textLogoBlue } from "../../../assets";
import { Button, Typography } from "../../../components/common/Base";
import DetailsSelection from "./DetailsSelection";
import ExpertiseSelection from "./ExpertiseSelection";
import GetStarted from "./GetStarted";
import "./styles.scss";

const CreateOnBoarding_AfterSignUpDefault = () => {
  const [showLogout, setShowLogout] = useState(false);
  const [step, setStep] = useState(0);

  const handleNext = () => {
    const nextStep = step + 1;
    if (nextStep <= 2) {
      setStep(nextStep);
      scrollToSection();
    }
  };

  const handleLogoutClick = () => {
    setShowLogout(!showLogout); // Toggle the logout div visibility
  };

  const displayBody = () => {
    if (step === 0) {
      return <GetStarted handleNext={handleNext} />;
    } else if (step === 1) {
      return (
        <ExpertiseSelection
          handleNext={handleNext}
          step={step}
          setStep={setStep}
        />
      );
    } else {
      return (
        <DetailsSelection
          handleNext={handleNext}
          step={step}
          setStep={setStep}
        />
      );
    }
  };

  const sectionRef = useRef<HTMLDivElement | null>(null);

  const scrollToSection = () => {
    // Scrolls to the section
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div
      className="creator-onboarding-after-signup-container"
      ref={sectionRef}
    >
      <div className="creator-onboarding-navbar">
        <Link
          className="navbar-brand"
          to="/"
        >
          <>
            <img
              src={logoBlue}
              alt="Logo"
              width={40}
              height={40}
              className="img-fluid logo me-3"
            />
            <img
              src={textLogoBlue}
              alt="Text logo"
              className="img-fluid text-logo"
            />
          </>
        </Link>
        <div>
          <div className="avatar-button">
            <Button
              className="avatar"
              onClick={handleLogoutClick}
            >
              <img
                className="avatar-img"
                src={creatorOnBoardingAvatar}
                alt="user@email.com"
              />
            </Button>
          </div>
          {showLogout && (
            <>
              <div className="button-triangle">
                <Button className="logout-tooltip">
                  <Typography
                    variant="link-text"
                    className="log-out-text"
                  >
                    Log out
                  </Typography>
                </Button>
                <div className="triangle-container"></div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="container">{displayBody()}</div>
    </div>
  );
};

export default CreateOnBoarding_AfterSignUpDefault;
