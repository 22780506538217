import Card from "../../Base/Card";
import "./style.scss";

interface Task {
  title: string;
  count: number;
}

interface TaskCountProps {
  tasks: Task[];
}

const TaskCount: React.FC<TaskCountProps> = ({ tasks }) => {
  return (
    <div className="taskCountContainer">
      {tasks.map((task, index) => (
        <Card
          key={index}
          className="taskCountCard"
        >
          <p className="text">{task.title}</p>
          <span className={`${task.count == 0 ? "zeroCount" : "count"}`}>{task.count ? task.count : 0}</span>
        </Card>
      ))}
    </div>
  );
};

export default TaskCount;
