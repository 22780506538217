import { useFormik } from "formik";
import { useState } from "react";
import { CheckCircle } from "react-feather";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { handleCreateNewPassword } from "../../../api/auth/auth.service";
import { logo } from "../../../assets";
import { Button, CustomAlert, Input, SpinnerLoading, Typography } from "../../../components/common/Base";
import { RootState } from "../../../store";
import "./styles.scss";

const CreatePasswordDefault = () => {
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const uuid = useSelector((state: RootState) => state.auth.profile.uuid);

  const isPasswordLengthValid = (password: string) => {
    return password.length >= 8 && password.length <= 30;
  };

  const containsNumber = (password: string) => {
    return /^(?=.*[0-9])(?=.*[a-zA-Z]).+$/.test(password);
  };

  const { values, handleChange, handleBlur, handleSubmit, setErrors } = useFormik({
    initialValues: {
      password: "",
      submit: null,
    },
    onSubmit: () => {
      if (!uuid) {
        setErrors({ submit: "Invalid request" });
        return;
      }
      setLoading(true);
      handleCreateNewPassword({ uuid, password: values.password })
        .then(() => {
          setToast(true);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="sign-up-container">
      <div className="left-containerr"></div>
      <div className="right-container">
        <form
          className="align-center-code"
          onSubmit={handleSubmit}
        >
          {toast ? (
            <CustomAlert
              variant={"success"}
              text={"Your password has been created successfully!"}
            />
          ) : (
            ""
          )}
          <img
            src={logo}
            alt="logo"
            className="logo"
            style={{ opacity: 0.5 }}
          />

          <Typography
            variant="h3"
            className="password-text"
          >
            You'll need a password
          </Typography>
          <Typography
            variant="small-text"
            className="sub-text"
          >
            Make sure it's 8 characters or more.
          </Typography>
          <div className="body-container">
            <div className="password-input">
              <Typography
                variant="small-text"
                className="field-name"
              >
                Password
              </Typography>
              <Input
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  <div className="helper-text">
                    <div
                      className={`helper-text-1 ${isPasswordLengthValid(values.password) && containsNumber(values.password) ? "hidden" : ""}`}
                    >
                      <div className="point-circle"></div>
                      <Typography
                        variant="small-text"
                        className={`hp-1-text ${isPasswordLengthValid(values.password) ? "hp-checked" : ""}`}
                      >
                        Between 8 to 30 characters
                      </Typography>
                    </div>
                    <div
                      className={`helper-text-2 ${isPasswordLengthValid(values.password) && containsNumber(values.password) ? "hidden" : ""}`}
                    >
                      <div className="point-circle"></div>
                      <Typography
                        variant="small-text"
                        className={`hp-2-text`}
                      >
                        At least one number (0-9)
                      </Typography>
                    </div>
                    <div
                      className={`helper-success ${isPasswordLengthValid(values.password) && containsNumber(values.password) ? "hp-success" : ""}`}
                    >
                      <CheckCircle className="check-icon" /> Now, that's a good password!
                    </div>
                  </div>
                }
              />
            </div>

            <Typography
              variant="small-text"
              className="agreement-text"
            >
              By signing up, you agree to the{" "}
              <Link
                className="link-styling"
                to="#"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                className="link-styling"
                to="#"
              >
                Privacy Policy
              </Link>
              , including{" "}
              <Link
                className="link-styling"
                to="#"
              >
                Cookie Use.
              </Link>
            </Typography>
          </div>

          <Button
            color="primary"
            className="next-button-link"
            type="submit"
            disabled={
              values.password === "" || !isPasswordLengthValid(values.password) || !containsNumber(values.password)
            }
          >
            <Typography variant="button-text">{loading ? <SpinnerLoading /> : "Next"}</Typography>
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CreatePasswordDefault;
