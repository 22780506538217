import { useSelector } from "react-redux";
import {
  DashboardClientsDefault,
  DashboardCreatorDefault,
  DashboardSuperProducerDefault,
} from "src/components/features";
import { RootState } from "src/store";

const Dashboard = () => {
  const type = useSelector((state: RootState) => state.auth.type);
  switch (type) {
    case "User":
      return <DashboardClientsDefault />;
    case "Creator":
      return <DashboardCreatorDefault />;
    case "Super Producer":
      return <DashboardSuperProducerDefault />;
    default:
      return <DashboardClientsDefault />;
  }
};

export default Dashboard;
