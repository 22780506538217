import Card from "../../Base/Card";
import "./styles.scss";
import {} from "react-icons";
import { errorIcon } from "src/assets";

const ActionCards = () => {
  const actions = [
    { title: "Verify your identity", route: "/verify" },
    { title: "Verify your identity", route: "/verify" },
    { title: "Verify your identity", route: "/verify" },
  ];

  return (
    <div className="actionMain">
      {actions?.map((action, index) => {
        return (
          <Card
            className="actionCard"
            key={index}
          >
            <div className="actionBodyWrapper">
              <div>
                <div className="action-text">
                  {action.title}
                  <p className="dangerText">Required</p>
                </div>

                <a
                  href={action.route}
                  className="action-link"
                >
                  Get Started
                </a>
              </div>
              <div className="icon">
                <img
                  src={errorIcon}
                  alt=""
                />
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default ActionCards;
