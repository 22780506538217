import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { Typography } from "../../Base";
import "./styles.scss";

interface AccordionProps {
  title: string;
  content: string;
}

const Accordion: React.FC<AccordionProps> = ({ title, content }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      // Trigger your click handler on Enter or Space key press
      handleClick();
    }
  };

  return (
    <div className="accordion-container">
      <div
        className="title-icon"
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        role="button"
        tabIndex={0}
      >
        <Typography
          variant={"body-2"}
          className="accordion-title"
        >
          {title}
        </Typography>
        <div className="icon">{open ? <ChevronUp /> : <ChevronDown />}</div>
      </div>
      {open && (
        <Typography
          className="content"
          variant={"body-1"}
        >
          {content}
        </Typography>
      )}
      <div className="accordion-divider"></div>
    </div>
  );
};

export default Accordion;
