import { UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown, Menu } from "antd";
import { useState } from "react";
import { Input } from "src/components/common/Base";
import AddNewButton from "src/components/common/Base/addNewButton";
import PaymentHistoryCard from "src/components/common/components/PaymentHistoryCard";
import PaymentPageSidebar from "src/components/common/components/PaymentPageSidebar";
import PayoutCard from "src/components/common/components/PayoutCard";
import { ReactComponent as FilterIcon } from "./../../../../src/assets/icons/filterIcon.svg";
import { ReactComponent as SearchIcon } from "./../../../../src/assets/icons/searchIcon.svg";
import "./style.scss";

import useDeviceStatus from "src/utils/responsive";

const handleMenuClick: MenuProps["onClick"] = (e) => {
  console.log("click", e);
};

const items: MenuProps["items"] = [
  {
    label: "1st menu item",
    key: "1",
    icon: <UserOutlined />,
    onClick: handleMenuClick,
  },
  {
    label: "2nd menu item",
    key: "2",
    icon: <UserOutlined />,
    onClick: handleMenuClick,
  },
  {
    label: "3rd menu item",
    key: "3",
    icon: <UserOutlined />,
    onClick: handleMenuClick,

    danger: true,
  },
  {
    label: "4rd menu item",
    key: "4",
    icon: <UserOutlined />,
    onClick: handleMenuClick,
    danger: true,
    disabled: true,
  },
];

const menuProps = {
  items,
  onClick: handleMenuClick,
};

const PaymentDashboardClientsDefault = () => {
  const { isDesktop } = useDeviceStatus();
  const payoutCardData = [
    { email: "allendra@gmail.com", paymentMethod: "paypal", handleEdit: () => console.log("edit") },
    { email: "allendra@gmail.com", paymentMethod: "paypal", handleEdit: () => console.log("edit") },
    { email: "allendra@gmail.com", paymentMethod: "paypal", handleEdit: () => console.log("edit") },
  ];

  const [isActive, setIsActive] = useState("Payment History");
  const paymentHistory = [
    {
      image: "",
      price: "178900 >",
      currency: "JPY",
      scheduledDate: "Sept 18",
      email: "allendra@gmail.com",
      cardType: "Paypal",
      productionDate: "sep 3-sep 8",
    },
    {
      image: "",
      price: "178900  >",
      currency: "JPY",
      scheduledDate: "Sept 18",
      email: "allendra@gmail.com",
      cardType: "Paypal",
      productionDate: "sep 3-sep 8",
    },
  ];

  const sideBardMenu = [
    {
      title: "Payment History",
      onClick: () => setIsActive("Payment History"),
      isActive: isActive == "Payment History",
    },
    { title: "Payouts", onClick: () => setIsActive("Payouts"), isActive: isActive == "Payouts" },
    { title: "Rates", onClick: () => setIsActive("Rates"), isActive: isActive == "Rates" },
  ];
  return (
    <div className="payment-Deshborad-Main">
      <div className="payment-Header">
        <h2>
          Manage Your <span className="headingcolor">Payments.</span>
        </h2>

        <div className="input-main">
          <div className="searchIconBox">
            <div className="iconSearch">
              <SearchIcon />
            </div>
            <Input
              type="search"
              placeholder="Search "
              className="input-searchBar"
            />
          </div>
          <Dropdown
            overlay={<Menu items={items} />}
            menu={menuProps}
            className="filter-Dropdown"
          >
            <Button>
              <FilterIcon />
              Filter
            </Button>
          </Dropdown>
        </div>

        <div className="text-payment-heading">
          <h3>Payments</h3>
        </div>

        <div className="payment-data">
          <PaymentPageSidebar menu={sideBardMenu} />

          <div className="payment-info-right">
            <div className="payment-Info">
              {isActive == "Payment History" && (
                <div className="payment-History">
                  <h2>Payment History</h2>

                  <div className="payment-History-card">
                    <div className="history-card">
                      <h3>Upcoming</h3>

                      {paymentHistory.length > 0 &&
                        paymentHistory.map((paymentData, index) => (
                          <PaymentHistoryCard
                            paymentHistory={paymentData}
                            key={index}
                          />
                        ))}
                    </div>

                    {/*  */}
                    <div className="history-card ">
                      <h3>2202</h3>
                      {paymentHistory.length > 0 &&
                        paymentHistory.map((paymentData, index) => (
                          <PaymentHistoryCard
                            paymentHistory={paymentData}
                            key={index}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              )}

              {isActive == "Payouts" && (
                <div className="payment-Payouts">
                  <h2>Payouts</h2>
                  <div className="payment-Payouts-card">
                    <div className="payouts-card">
                      <h3>How you get paid?</h3>
                      <p>
                        You can send your money to one or more payout methods. To manage your payout method(s) or assign
                        a taxpayer, use the edit menu next to each payout method.
                      </p>

                      {payoutCardData.length > 0 &&
                        payoutCardData.map((value, index) => (
                          <PayoutCard
                            payoutData={value}
                            type="payout"
                            key={index}
                          />
                        ))}
                    </div>

                    <AddNewButton
                      className="add-btn"
                      type="button"
                      text="Add payout method"
                    />
                  </div>
                </div>
              )}

              {!isDesktop && (
                <>
                  <div className="payment-Payouts">
                    <h2>Payouts</h2>
                    <div className="payment-Payouts-card">
                      <div className="payouts-card">
                        <h3>How you get paid?</h3>
                        <p>
                          You can send your money to one or more payout methods. To manage your payout method(s) or
                          assign a taxpayer, use the edit menu next to each payout method.
                        </p>

                        {payoutCardData.length > 0 &&
                          payoutCardData.map((value, index) => (
                            <PayoutCard
                              payoutData={value}
                              type="payout"
                              key={index}
                            />
                          ))}
                      </div>

                      <AddNewButton
                        className="add-btn"
                        type="button"
                        text="Add payout method"
                      />
                    </div>
                  </div>

                  <div className="rates-Payouts">
                    <h2>Rates</h2>

                    <div className="rates-Payouts-card">
                      <div className="rates-card">
                        <h3>How you get paid?</h3>
                        <p>
                          You can send your money to one or more payout methods. To manage your payout method(s) or
                          assign a taxpayer, use the edit menu next to each payout method.
                        </p>

                        {payoutCardData.length > 0 &&
                          payoutCardData.map((value, index) => (
                            <PayoutCard
                              payoutData={value}
                              type="Rates"
                              key={index}
                            />
                          ))}
                      </div>

                      <AddNewButton
                        className="add-btn"
                        type="button"
                        text="Add payout method"
                      />
                    </div>
                  </div>
                </>
              )}

              {isActive == "Rates" && (
                <div className="rates-Payouts">
                  <h2>Rates</h2>

                  <div className="rates-Payouts-card">
                    <div className="rates-card">
                      <h3>How you get paid?</h3>
                      <p>
                        You can send your money to one or more payout methods. To manage your payout method(s) or assign
                        a taxpayer, use the edit menu next to each payout method.
                      </p>

                      {payoutCardData.length > 0 &&
                        payoutCardData.map((value, index) => (
                          <PayoutCard
                            payoutData={value}
                            type="Rates"
                            key={index}
                          />
                        ))}
                    </div>

                    <AddNewButton
                      className="add-btn"
                      type="button"
                      text="Add payout method"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDashboardClientsDefault;
