import React from "react";
import { Select } from "../../../../components/common/Base";

const CreaterAssessment = () => {
  const selectOptions = ["absolutely not", "no", "I don't know", "sometimes", "absolutely"];

  return (
    <div className="creater-assessment-container">
      <Select
        text={"The creator shown signs of professionalism"}
        options={selectOptions}
      />
      <Select
        text={"The creator provided clear communication throughout the entire project. "}
        options={selectOptions}
      />
      <Select
        text={"The creator’s skills did match their credentials."}
        options={selectOptions}
      />
      <Select
        text={"The creator was resourceful and met issues with ease."}
        options={selectOptions}
      />
      <Select
        text={"The creator was team-focused and collaborative."}
        options={selectOptions}
      />
      <Select
        text={"The creator was positive and encouraging."}
        options={selectOptions}
      />
      <Select
        text={"The creator provided a very complex and challenging service."}
        options={selectOptions}
      />
    </div>
  );
};

export default CreaterAssessment;
