import React, { useState } from "react";
import Card from "../../Base/Card";
import "./style.scss";
import { plusIcon } from "src/assets";
import { CustomModal } from "../../Layout";
import { ReactComponent as CloseBtnICon } from "./../../../../assets/icons/closeBtnIcon.svg";
import { Button } from "../../Base";

const TipsSection: React.FC = () => {
  const [isShowModal, setShowModal] = useState(false);
  const [description, setDescription] = useState("");

  const handleClick = (text: string) => {
    console.log("text", text);
    setShowModal(true);
    setDescription(text);
  };

  const items = [
    {
      text: "Update your reel",
      description:
        "Updating your reel yearly will make your profile more attractive which helps you find a wide-rande of clients",
    },
    {
      text: "Verify your Prodile",
      description:
        "Verifying your profile will make your profile more attractive which helps you find a wide-rande of clients",
    },
    {
      text: "Link your Profile",
      description:
        "Linking your profile will make your profile more attractive which helps you find a wide-rande of clients",
    },
  ];

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="tipsSectionMain">
      <h4 className="titleText">Tips</h4>
      <div className="tipsCardWrapper ">
        {items.map((item, index) => (
          <Card
            key={index}
            className="tipsCard"
          >
            <Button
              onClick={() => handleClick(item.description)}
              className="btn-undefind
              "
            >
              <div className="body">
                <div className="addButton">
                  <img
                    src={plusIcon}
                    alt=""
                  />
                </div>
                <h3 className="text">{item.text}</h3>
              </div>
            </Button>
          </Card>
        ))}
      </div>
      {isShowModal && (
        <CustomModal
          handleClose={handleClose}
          show={isShowModal}
          className="customModalCard"
        >
          <div className="customCard">
            <Button
              className="close-btn"
              type="button"
              onClick={handleClose}
            >
              <CloseBtnICon />
            </Button>
            <p>{description}</p>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default TipsSection;
