import { Button } from "react-bootstrap";
import { ArrowRight, Plus } from "react-feather";
import { Link } from "react-router-dom";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { GenericComponentProps, ImageProps } from "../../../../interfaces/component";
import { CarouselSlider, Typography } from "../../Base";
import "./style.scss";

interface HalfImageTextProps extends GenericComponentProps {
  heading?: string;
  title: string | JSX.Element;
  text: string | JSX.Element;
  align?: string;
  // TODO: sync variants
  titleVariant?:
    | "h1"
    | "h2"
    | "h3"
    | "subtitle"
    | "body-1"
    | "body-2"
    | "small-text"
    | "pre-title"
    | "button-text"
    | "link-text";
  textVariant?:
    | "h1"
    | "h2"
    | "h3"
    | "subtitle"
    | "body-1"
    | "body-2"
    | "small-text"
    | "pre-title"
    | "button-text"
    | "link-text";
  image?: ImageProps[] | ImageProps;
  linkButton?: boolean;
  iconButton?: boolean;
}

const HalfImageText: React.FC<HalfImageTextProps> = ({
  align,
  heading,
  title,
  text,
  image,
  titleVariant,
  textVariant,
  linkButton,
  iconButton,
}) => {
  const [windowDimensions] = useWindowDimensions();

  const renderImage = () => {
    if (Array.isArray(image)) {
      return image.map((item, index) =>
        item.carousel ? (
          <CarouselSlider
            images={image.filter((img) => !img.carousel)}
            key={index}
          />
        ) : (
          <img
            src={item.src}
            className="img-fluid"
            alt=""
            key={index}
          />
        ),
      );
    }
    return (
      <img
        src={image?.src ?? ""}
        className="img-fluid"
        alt=""
      />
    );
  };

  const renderTextContent = () => (
    <div className="text">
      <Typography
        variant="pre-title"
        className="pink-text"
      >
        {heading}
      </Typography>
      <Typography
        variant={titleVariant}
        className="title-style"
      >
        {title}
      </Typography>
      <Typography
        variant={textVariant}
        className="text-style"
      >
        {text}
      </Typography>
      {linkButton && (
        <Link
          className="d-link"
          to="#"
        >
          <Typography variant="link-text">
            See their story{" "}
            <ArrowRight
              size={18}
              className="arrow-icon"
            />
          </Typography>
        </Link>
      )}
      {iconButton && (
        <Button className="find-out-more-button">
          <Typography
            variant="button-text"
            className="find-out-more-button-text"
          >
            find out more
          </Typography>
          <div className="pink-circle-icon">
            <Plus className="arrow-icon" />
          </div>
        </Button>
      )}
    </div>
  );

  return (
    <section className="half-image-text">
      <div className="row">
        {align === "left" ? (
          <>
            <div className="col-12 col-md-6">{renderImage()}</div>
            <div className="col-12 col-md-6 d-flex justify-content-center flex-column">{renderTextContent()}</div>
          </>
        ) : (
          <>
            {windowDimensions.width <= 992 ? (
              <>
                <div className="col-12 col-md-6">{renderImage()}</div>
                <div className="col-12 col-md-6 d-flex justify-content-center flex-column">{renderTextContent()}</div>
              </>
            ) : (
              <>
                <div className="col-12 col-md-6 d-flex justify-content-center flex-column">{renderTextContent()}</div>
                <div className="col-12 col-md-6">{renderImage()}</div>
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default HalfImageText;
