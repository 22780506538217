import { useRef, useState } from "react";
import { Play, X } from "react-feather";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { seeStoriesVideo, ssAwards, ssBanner, ssSlide1, ssSliderMobile, sshaderbach } from "../../../assets";
import { Button, Typography } from "../../../components/common/Base";
import { BlueSection, CustomModal } from "../../../components/common/Layout";
import { CustomSlider } from "../../../components/common/Widgets";
import "./style.scss";

const SeeStories = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 400px)" });
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [showIcon, setShowIcon] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleVideoEnded = () => setShowIcon(true);

  const handleVideoPlay = () => {
    if (videoRef.current) {
      videoRef.current.play(); // Play the video
      setShowIcon(false); // Hide the play button
    }
  };

  const handleOpenModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);
  return (
    <>
      <div className="main-seeStories">
        {/* ============= Banner Section ============= */}
        <section
          className="banner-sec"
          style={{ backgroundImage: `url(${ssBanner})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="description">
                  <img
                    className={`desktop animate__fadeInUp animate__animated animate__slow`}
                    src={ssAwards}
                    alt="GalleryImage1"
                  />
                  <div
                    data-aos="fade-right"
                    data-aos-easing="linear"
                    data-aos-duration="500"
                  >
                    <Typography
                      variant="h1"
                      className="main-headingg"
                    >
                      THE UNFORGIVABLES
                    </Typography>
                    <Typography
                      variant="pre-title"
                      className="subtitle-styless"
                    >
                      John Kaluuya is a British actor. Prominent both on screen and stage, he has received numerous
                      accolades, including an Academy Award, two BAFTA Awards, and a Golden Globe Award.
                    </Typography>
                    <Button
                      className="watch-video-button"
                      onClick={handleOpenModal}
                    >
                      <div className="button-icon-container">
                        <div className="image-button">
                          <Play className="icon-style" />
                        </div>
                      </div>
                      <Typography variant="body-1">Watch the Trailer</Typography>
                    </Button>
                    <CustomModal
                      show={showModal}
                      handleClose={handleCloseModal}
                      className="modal-container"
                      size="xl"
                    >
                      <div className="embed-responsive embed-responsive-16by9 video-container">
                        <button
                          className="button-styles"
                          onClick={handleCloseModal}
                        >
                          <X className="icon-style" />
                        </button>
                        <video
                          controls
                          autoPlay
                          className="video-styling"
                          ref={videoRef}
                          onEnded={handleVideoEnded}
                          onPause={handleVideoEnded}
                          onPlay={handleVideoPlay}
                        >
                          <track
                            kind="captions"
                            src=""
                            label="English"
                          />
                          <source
                            src={seeStoriesVideo}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                        {showIcon && (
                          <button
                            className="center-play-icon-container"
                            onClick={handleVideoPlay}
                          >
                            {/* Icon component or image */}
                            <Play className="play-icon-style" />
                          </button>
                        )}
                      </div>
                    </CustomModal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ============= THE UNFORGIVABLES ============= */}
        <section className="the-unforgivables-sec">
          <div className="eclipse"></div>
          <div className="container">
            <div className="row justify-content-between">
              <div
                className="col-md-4"
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="description">
                  <div className="border-top"></div>

                  <Typography
                    variant="h2"
                    className="different-font-style"
                  >
                    THE UNFORGIVABLES
                  </Typography>
                  <Typography
                    variant="body-1"
                    className="subtitle-styles"
                  >
                    <span className="fs-16">
                      John Kaluuya is a British actor. Prominent both on screen and stage, he has received numerous
                      accolades, including an Academy Award, two BAFTA Awards, and a Golden Globe Award.
                    </span>
                  </Typography>
                  <div className="border-top"></div>
                  <Typography
                    variant="body-1"
                    className="mb-4"
                  >
                    The Highlights
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body-1">75 types of SFX</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Unique costume design</Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Stellar sound design </Typography>
                    </li>
                    <li>
                      <Typography variant="body-1">Harmonious crew</Typography>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-md-6"
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <div className="description">
                  <div className="border-top"></div>
                  <Typography
                    variant="h2"
                    className="styling"
                  >
                    See how the most talked about superhero series of this generation was created by using Forreels.io.
                  </Typography>

                  <Typography
                    variant="h3"
                    className="subtitle-styles"
                  >
                    Process
                  </Typography>
                  <Typography
                    variant="pre-title"
                    className="pink-text tu-subtitle-text"
                  >
                    John Kaluuya is a British actor. Prominent both on screen and stage, he has received numerous
                    accolades, including an Academy Award, two BAFTA Awards, and a Golden Globe Award. John Kaluuya is a
                    British actor. Prominent both on screen and stage, he has received numerous accolades, including an
                    Academy Award, two BAFTA Awards, and a Golden Globe Award.{" "}
                  </Typography>
                  <Typography
                    variant="h3"
                    className="subtitle-styles"
                  >
                    Approach
                  </Typography>
                  <Typography
                    variant="pre-title"
                    className="pink-text tu-subtitle-text"
                  >
                    John Kaluuya is a British actor. Prominent both on screen and stage, he has received numerous
                    accolades, including an Academy Award, two BAFTA Awards, and a Golden Globe Award. John Kaluuya is a
                    British actor. Prominent both on screen and stage, he has received numerous accolades, including an
                    Academy Award, two BAFTA Awards, and a Golden Globe Award.{" "}
                  </Typography>
                  <Typography
                    variant="h3"
                    className="subtitle-styles"
                  >
                    Result
                  </Typography>
                  <Typography
                    variant="pre-title"
                    className="pink-text tu-subtitle-text"
                  >
                    John Kaluuya is a British actor. Prominent both on screen and stage, he has received numerous
                    accolades, including an Academy Award, two BAFTA Awards, and a Golden Globe Award. John Kaluuya is a
                    British actor. Prominent both on screen and stage, he has received numerous accolades, including an
                    Academy Award, two BAFTA Awards, and a Golden Globe Award.{" "}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="ss-custom-slider">
          <CustomSlider
            slides={[
              {
                image: isMobile ? ssSliderMobile : ssSlide1,
                heading: "THE UNFORGIVABLES",
                text: "““Forreels.io is unrivalled when it comes to sourcing reliable SFX artists and locations for The Unforgivables.",
                subtext: (
                  <>
                    Allendra Freeman - <span>Director, The Unforgivables</span>
                  </>
                ),
              },
              {
                image: isMobile ? ssSliderMobile : ssSlide1,
                heading: "THE UNFORGIVABLES",
                text: "““Forreels.io is unrivalled when it comes to sourcing reliable SFX artists and locations for The Unforgivables.",
                subtext: (
                  <>
                    Allendra Freeman - <span>Director, The Unforgivables</span>
                  </>
                ),
              },
            ]}
          />
        </div>
        {/* ============= THE UNFORGIVABLES ============= */}
        <section className="the-unforgivables-secc">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-md-6">
                <div
                  className="description"
                  data-aos="fade-left"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  <Typography
                    variant="h2"
                    className="text-style"
                  >
                    Learn more about why the creators from The Unforgivables use Forreels.io to make Brandon and Moses
                    fly.{" "}
                  </Typography>

                  <Typography
                    variant="h3"
                    className="subtitle-styles"
                  >
                    Magic and Meditate
                  </Typography>
                  <Typography
                    variant="body-1"
                    className="pink-text tu-subtitle-text"
                  >
                    John Kaluuya is a British actor. Prominent both on screen and stage, he has received numerous
                    accolades, including an Academy Award, two BAFTA Awards, and a Golden Globe Award. John Kaluuya is a
                    British actor. Prominent both on screen and stage, he has received numerous accolades, including an
                    Academy Award, two BAFTA Awards, and a Golden Globe Award.{" "}
                  </Typography>
                  <Typography
                    variant="h3"
                    className="subtitle-styles"
                  >
                    The color grading process
                  </Typography>
                  <Typography
                    variant="body-1"
                    className="pink-text tu-subtitle-text"
                  >
                    John Kaluuya is a British actor. Prominent both on screen and stage, he has received numerous
                    accolades, including an Academy Award, two BAFTA Awards, and a Golden Globe Award. John Kaluuya is a
                    British actor. Prominent both on screen and stage, he has received numerous accolades, including an
                    Academy Award, two BAFTA Awards, and a Golden Globe Award.{" "}
                  </Typography>
                  <Typography
                    variant="h3"
                    className="subtitle-styles"
                  >
                    How style in fashion design made an impact on the “look”.
                  </Typography>
                  <Typography
                    variant="body-1"
                    className="pink-text tu-subtitle-text"
                  >
                    John Kaluuya is a British actor. Prominent both on screen and stage, he has received numerous
                    accolades, including an Academy Award, two BAFTA Awards, and a Golden Globe Award. John Kaluuya is a
                    British actor. Prominent both on screen and stage, he has received numerous accolades, including an
                    Academy Award, two BAFTA Awards, and a Golden Globe Award.{" "}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ============= THE UNFORGIVABLES ============= */}
        <section
          className="haderbach-sec"
          style={{ backgroundImage: `url(${sshaderbach})` }}
          data-aos="zoom-out"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="description">
                  <Typography variant="body-2">Up Next</Typography>
                  <Typography
                    variant="h1"
                    className="different-font-style-2"
                  >
                    HADERBACH
                  </Typography>
                  <Link
                    className="d-link"
                    to="#"
                  >
                    <Typography variant="link-text">
                      See their story <i>&#8594;</i>
                    </Typography>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner Section */}
        <BlueSection className="blue-section-styles">
          <div className="section-text">
            <div className="section-text-style">
              <Typography variant="h2">Forreels.io, supporting the world’s top content creators.</Typography>
            </div>
          </div>
        </BlueSection>
      </div>
    </>
  );
};

export default SeeStories;
