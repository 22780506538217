import { useNavigate } from "react-router-dom";
import { logo } from "../../../assets";
import { Button, Typography } from "../../../components/common/Base";
import "./styles.scss";
const PasswordChangeSuccessDefault = () => {
  const navigate = useNavigate();
  return (
    <div className="password-change-success-container">
      <div className="pcs-left-container"></div>
      <div className="pcs-right-container">
        <div className="pcs-right-container-align-center">
          <img
            src={logo}
            alt="logo"
            className="logo"
          />
          <Typography
            variant="h3"
            className="success-text"
          >
            Password change successfull
          </Typography>
          <Typography
            variant="small-text"
            className="success-sub-text"
          >
            Your password is successfully change
          </Typography>
          <Button
            color="primary"
            className="success-continue-button"
            type="submit"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <Typography variant="button-text">Continue to foreels.io</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeSuccessDefault;
