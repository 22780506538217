import { Accordion, Typography } from "../../../components/common/Base";
import { BlueSection } from "../../../components/common/Layout";
import "./styles.scss";

const sectionsData = [
  {
    heading: "Overview",
    accordions: [
      {
        title: "How is Forreels unique?",
        content:
          "Its never too late or too early to break into creative spaces. Our SuperProducers are also here for other creators including child actors, to mentor, to coach and to pave the way. Our SuperProducers provide support that helps instil confidence useful in landing your first gig.",
      },
      {
        title: "What happens if I’m not satisfied with Forreels SuperProducers?",
        content: "Hello World 2",
      },
      { title: "Are there any hidden fees? ", content: "Hello World 3" },
    ],
  },
  {
    heading: "Geography",
    accordions: [
      { title: "How is Forreels unique?", content: "Hello World 1" },
      {
        title: "What happens if I’m not satisfied with Forreels SuperProducers?",
        content: "Hello World 2",
      },
    ],
  },
  {
    heading: "Pricing and Payment",
    accordions: [
      {
        title: "What are method of payments do you accept?",
        content: "Hello World 1",
      },
      {
        title: "How are payments and invoices handled?",
        content: "Hello World 2",
      },
      { title: "How often will I be charged? ", content: "Hello World 3" },
    ],
  },
  {
    heading: "Forreels Network and Services ",
    accordions: [
      {
        title: "What happens if the weather is unsuitable during an outdoor production date?",
        content: "Hello World 1",
      },
      { title: "Are there any hidden fees? ", content: "Hello World 2" },
      {
        title: "What if I need additional prroduction days?",
        content: "Hello World 3",
      },
    ],
  },
  {
    heading: "Intellectual Property",
    accordions: [
      {
        title: "Who owns the legal rights to the work created on Forreels? ",
        content: "Hello World 1",
      },
      {
        title: "What’s the process of contract signing? ",
        content: "Hello World 2",
      },
      {
        title: "How do we ensure that our intellectual property protected?",
        content: "Hello World 3",
      },
    ],
  },
  {
    heading: "Testing and screening",
    accordions: [
      {
        title: "How are the creators on Forreels selected? ",
        content: "Hello World 1",
      },
      {
        title: "What’s the process of joining Forreels?",
        content: "Hello World 2",
      },
      { title: "Are there any hidden fees?", content: "Hello World 3" },
    ],
  },
  // Add more sections as needed
];

const CommonQuestionsDefault = () => {
  return (
    <>
      <div className="container common-questions-container">
        <div className="main-heading">
          <Typography variant="h2">Common Questions</Typography>
        </div>
        <div className="accordions">
          {sectionsData.map((section, index) => (
            <div
              className="accordions-containers"
              key={index}
            >
              <div className="accordion-heading">
                <Typography variant="h3">{section.heading}</Typography>
              </div>
              <div className="all-accordions">
                {section.accordions.map((accordion, i) => (
                  <Accordion
                    key={i}
                    title={accordion.title}
                    content={accordion.content}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <BlueSection className="blue-section-styles">
        <div className="sectionText">
          <Typography
            variant="h2"
            className="textStyle"
          >
            Forreels.io, supporting the world’s top content creators.
          </Typography>
        </div>
      </BlueSection>
    </>
  );
};

export default CommonQuestionsDefault;
