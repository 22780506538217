import React from "react";
import { Select } from "../../../../components/common/Base";

const Tip = () => {
  const optionsData = ["5%", "10%", "5%", "Custom"];
  const topTextOptionsData = ["$3.45", "$10.25", "$32.86", ""];

  return (
    <div className="tip-container">
      <Select
        options={optionsData}
        topTextOptions={topTextOptionsData}
      />
    </div>
  );
};

export default Tip;
