// src/CalendarComponent.js
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./style.scss";

const CalendarComponent = () => {
  const date = new Date();
  const onChange = () => {
    console.log("onchange date");
  };

  return (
    <div className="calendar-container">
      <Calendar
        className={"calendar-main"}
        onChange={onChange}
        value={date}
      />
    </div>
  );
};

export default CalendarComponent;
