import React from "react";
import "./style.scss";
import { Button } from "../../Base";

interface MenuItem {
  title: string;
  onClick: () => void;
  isActive?: boolean;
}

interface PaymentPageSidebarProps {
  menu: MenuItem[];
}

const PaymentPageSidebar: React.FC<PaymentPageSidebarProps> = ({ menu }) => {
  return (
    <div className="payment-list-left">
      <div className="payment-list">
        <ul className="payment-menu">
          {menu.map((item, index) => (
            <li
              key={index}
              className={`payment-menu-item ${item.isActive ? "active" : ""}`}
            >
              <Button
                onClick={item.onClick}
                className="payment-item-btn"
              >
                {item.isActive && <span className="dot"></span>}
                {item.title}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PaymentPageSidebar;
