import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "src/store";
import { handleSignIn } from "../../../api/auth/auth.service";
import { logo } from "../../../assets";
import { Button, CustomAlert, Input, SpinnerLoading, Typography } from "../../../components/common/Base";
import { signUp } from "../../../store/reducers/auth.reducer";
import "./styles.scss";

const SignInEmailPasswordDefault = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { email } = useSelector((state: RootState) => state.auth.profile);

  const { values, handleChange, handleBlur, handleSubmit, errors, setErrors, isValid } = useFormik({
    initialValues: {
      email: email ?? "",
      password: "",
      submit: null,
    },
    onSubmit: () => {
      setLoading(true);
      handleSignIn({ email: values.email, password: values.password })
        .then((response) => {
          const { uuid, validated, validationExpires } = response.data;
          dispatch(
            signUp({
              uuid,
              validated,
              validationExpires,
            }),
          );

          if (validated) {
            navigate("/dashboard");
            return;
          }
          navigate("/auth/validation");
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message || err?.message || "An error occurred";
          setErrors({ submit: errorMessage });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  return (
    <div className="sign-in-email-password-container">
      <div className="siep-left-container"></div>
      <div className="siep-right-container">
        <div className="siep-align-center">
          <img
            src={logo}
            alt="logo"
            className="logo"
          />
          <Typography
            variant="h3"
            className="siep-main-text"
          >
            Enter your password
          </Typography>
          {errors.submit ? (
            <CustomAlert
              variant="danger"
              text="Incorrect email address and / or password. You can try again or reset your password"
            />
          ) : (
            ""
          )}
          <form
            onSubmit={handleSubmit}
            className="siep-form-container"
          >
            <div>
              <Typography
                variant="small-text"
                className="siep-field-name"
              >
                Email
              </Typography>
              <Input
                type="text"
                placeholder="Enter your email address"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={true}
              />
            </div>
            <div>
              <Typography
                variant="small-text"
                className="siep-field-name"
              >
                Password
              </Typography>
              <Input
                type="password"
                placeholder="Enter password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div>
              <Button
                color="primary"
                className="siep-next-button"
                type="submit"
                disabled={!isValid || !values.password || !values.email}
              >
                <Typography variant="button-text">{loading ? <SpinnerLoading /> : "Log in"}</Typography>
              </Button>
            </div>
          </form>
          <div>
            <Button
              color="secondary"
              variant="outlined"
              className="siep-fp-button"
              onClick={() => navigate("/auth/forgot")}
            >
              <Typography variant="button-text">Forgot Password?</Typography>
            </Button>
          </div>
          <div className="siep-last-text">
            <Typography
              variant="link-text"
              className="siep-text"
            >
              Don’t Have an account?{" "}
              <Link
                className="link-style"
                to={"/auth/sign-up"}
              >
                Sign up
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInEmailPasswordDefault;
