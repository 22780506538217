import React from "react";
import { GenericComponentProps } from "src/interfaces/component";
import "./style.scss";

const Wrapper: React.FC<GenericComponentProps> = ({ children }) => {
  return (
    <div className="main-container">
      <aside></aside>
      <main>{children}</main>
    </div>
  );
};

export default Wrapper;
