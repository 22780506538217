import { ArrowRight } from "react-feather";
import { Link } from "react-router-dom";
import { Typography } from "../../Base";
import "./style.scss";

interface ImageDescriptionProps {
  cover: string;
  logo: string;
  text: string;
}

const ImageDescription: React.FC<ImageDescriptionProps> = ({ cover, logo, text }) => {
  return (
    <>
      <section>
        <div className="img-container">
          <img
            src={cover}
            className="cover img-fluid"
            alt=""
          />
        </div>
        <div className="text-container">
          <img
            src={logo}
            className="logo img-fluid"
            alt=""
          />
          <Typography
            variant="pre-title"
            className="subtitle"
          >
            {text}
          </Typography>
          <Link to="#">
            <Typography
              variant="link-text"
              className="linkStyle"
            >
              See their story <ArrowRight size={18} />
            </Typography>
          </Link>
        </div>
      </section>
    </>
  );
};

export default ImageDescription;
