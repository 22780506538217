import React from "react";
import { Input } from "../../../../components/common/Base";
import "./styles.scss";

const AddSomething = () => {
  return (
    <div className="add-something-container">
      <Input
        type="textarea"
        className="input-style"
        placeholder="Type here"
      />
    </div>
  );
};

export default AddSomething;
