import React from "react";
import { Modal } from "react-bootstrap";
import { GenericComponentProps } from "../../../../interfaces/component";

interface CustomModalProps extends GenericComponentProps {
  show: boolean;
  handleClose: () => void;
  className?: string;
  size?: "sm" | "lg" | "xl";
  ref?: React.RefObject<HTMLVideoElement>;
}

const CustomModal: React.FC<CustomModalProps> = ({ show, children, handleClose, className, size }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={`${className ?? ""}`}
      size={size}
    >
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
