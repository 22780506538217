import Wrapper from "src/components/common/components/Wrapper";
import Dashboard from "src/pages/dashboard";
import Payment from "src/pages/payment";
import SignInEmail from "src/pages/signInEmail";
import SignInEmailPassword from "src/pages/signInEmailPassword";
import AccountDetails_Clients from "../pages/accountDetails_Clients";
import AccountDetails_Creator from "../pages/accountDetails_Creator";
import AccountDetails_Manager from "../pages/accountDetails_Manager";
import AccountDetails_SuperProducer from "../pages/accountDetails_SuperProducer";
import Accounts from "../pages/accounts";
import Careers from "../pages/careers";
import ChooseMethod from "../pages/chooseMethod";
import ClientReview from "../pages/clientReview";
import CommonQuestions from "../pages/common-questions";
import ContactForm from "../pages/contactForm";
import ContactFormConfirmation from "../pages/contactFormConfirmation";
import CreateAccount from "../pages/createAccount";
import CreateNewPassword from "../pages/createNewPassword";
import CreatePassword from "../pages/createPassword";
import CreaterOnBoarding_AfterSignUp from "../pages/createrOnBoarding_AfterSignUp";
import Customers from "../pages/customers";
import FindAccount from "../pages/findAccount";
import LandingPage from "../pages/landing-page";
import PasswordChangeSuccess from "../pages/passwordChangeSuccess";
import SeeStories from "../pages/see-stories";
import SubscriptionConfirmation from "../pages/subscription-confirmation";
import SuperProducers from "../pages/super-producers";
import VerificationCode from "../pages/verificationCode";
import WelcomeSignUp from "../pages/welcomeSignUp";
import WhatsNew from "../pages/whats-new";

const routes = {
  // comingSoon: {
  //   path: "/",
  //   label: "Coming Soon",
  //   component: <ComingSoon />,
  // },
  landingPage: {
    path: "/",
    label: "Landing Page",
    component: <LandingPage />,
  },
  welcomeSignUp: {
    path: "/auth/sign-up",
    label: "Welcome SignUp",
    component: <WelcomeSignUp />,
  },
  verificationCode: {
    path: "/auth/validation",
    label: "Verification Code",
    component: <VerificationCode />,
  },
  signInEmail: {
    path: "/auth/sign-in",
    label: "Sign In with Email",
    component: <SignInEmail />,
  },
  signInEmailPassword: {
    path: "/auth/sign-in/email",
    label: "Sign In with Email and Password",
    component: <SignInEmailPassword />,
  },
  createAccount: {
    path: "/auth/sing-up/account",
    label: "Create Account",
    component: <CreateAccount />,
  },
  createPassword: {
    path: "/auth/sign-up/password",
    label: "Create Password",
    component: <CreatePassword />,
  },
  findAccount: {
    path: "/auth/forgot",
    label: "Forgot Account",
    component: <FindAccount />,
  },
  chooseMethod: {
    path: "/auth/forgot/method",
    label: "Choose Method",
    component: <ChooseMethod />,
  },
  createNewPassword: {
    path: "/auth/forgot/password",
    label: "Create New Password",
    component: <CreateNewPassword />,
  },
  passwordChangeSuccess: {
    path: "/auth/forgot/success",
    label: "Password Change Success",
    component: <PasswordChangeSuccess />,
  },
  subscriptionConfirmation: {
    path: "/subscription-confirmation",
    label: "Subscription Confirmation",
    component: <SubscriptionConfirmation />,
  },

  careers: {
    path: "/careers",
    label: "Careers",
    component: <Careers />,
  },
  commonQuestions: {
    path: "/questions/common",
    label: "Common Questions",
    component: <CommonQuestions />,
  },
  customers: {
    path: "/customers",
    label: "Customers",
    component: <Customers />,
  },
  superProducers: {
    path: "/super-producers",
    label: "Super Producers",
    component: <SuperProducers />,
  },
  seeStories: {
    path: "/stories",
    label: "See Stories",
    component: <SeeStories />,
  },
  whatsNew: {
    path: "/whats-new",
    label: "Whats New",
    component: <WhatsNew />,
  },
  clientReview: {
    path: "/reviews",
    label: "Client Review",
    component: <ClientReview />,
  },
  accounts: {
    path: "/accounts",
    label: "Accounts",
    component: <Accounts />,
  },
  accountDetails_SuperProducer: {
    path: "/account-details-superproducer",
    label: "Account Details - SuperProducer",
    component: <AccountDetails_SuperProducer />,
  },
  accountDetails_Creator: {
    path: "/account-details-creator",
    label: "Account Details - Creator",
    component: <AccountDetails_Creator />,
  },
  accountDetails_Clients: {
    path: "/account-details-clients",
    label: "Account Details - Clients",
    component: <AccountDetails_Clients />,
  },
  accountDetails_Manager: {
    path: "/account-details-manager",
    label: "Account Details - Manager",
    component: <AccountDetails_Manager />,
  },
  creatorOnBoarding_AfterSignUp: {
    path: "/creator-onboarding-after-signup",
    label: "Creator OnBoarding After SignUp",
    component: <CreaterOnBoarding_AfterSignUp />,
  },
  contactForm: {
    path: "/contact-form",
    label: "Contact Form",
    component: <ContactForm />,
  },
  contactFormConfirmation: {
    path: "/contact-form-confirmation",
    label: "Contact Form Confirmation",
    component: <ContactFormConfirmation />,
  },
  dashboard: {
    path: "/dashboard",
    label: "Clients Dashboard",
    component: (
      <Wrapper>
        <Dashboard />,
      </Wrapper>
    ),
  },
  payment: {
    path: "/payment",
    label: "Clients Payment Dashboard",
    component: (
      <Wrapper>
        <Payment />,
      </Wrapper>
    ),
  },
};

export const publicRoutes = [
  routes.accounts,
  // routes.comingSoon,
  routes.landingPage,
  routes.careers,
  routes.subscriptionConfirmation,
  routes.commonQuestions,
  routes.customers,
  routes.superProducers,
  routes.seeStories,
  routes.whatsNew,
  routes.clientReview,
  routes.contactForm,
  routes.contactFormConfirmation,
];
export const authRoutes = [
  routes.welcomeSignUp,
  routes.createAccount,
  routes.verificationCode,
  routes.createPassword,
  routes.findAccount,
  routes.createNewPassword,
  routes.passwordChangeSuccess,
  routes.chooseMethod,
  routes.signInEmail,
  routes.signInEmailPassword,
];
export const privateRoutes = [
  routes.accountDetails_SuperProducer,
  routes.accountDetails_Creator,
  routes.accountDetails_Clients,
  routes.creatorOnBoarding_AfterSignUp,
  routes.accountDetails_Manager,
  routes.dashboard,
  routes.payment,
];
