import React from "react";
import { GenericComponentProps } from "src/interfaces/component";
import "./styles.scss";

interface ImageTextHeaderProps extends GenericComponentProps {
  bgImage: string;
}

const ImageTextHeader: React.FC<ImageTextHeaderProps> = ({ bgImage, children, className }) => {
  return (
    <div className="main-containerr">
      <div
        className={`header-image-container ${className}`}
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
      {children}
    </div>
  );
};

export default ImageTextHeader;
