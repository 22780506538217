import { useFormik } from "formik";
import { useState } from "react";
import { Button, CustomAlert, Input, SpinnerLoading, Typography } from "../../../components/common/Base";
import "./styles.scss";

import { ArrowLeft } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setForgotPasswordExpires, setUUID } from "src/store/reducers/auth.reducer";
import { validationSchemaForgotPassword } from "src/utils/validationSchema";
import { handleForgot } from "../../../api/auth/auth.service";
import { logo } from "../../../assets";

const FindAccountDefault = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { values, handleChange, handleBlur, handleSubmit, errors, setErrors, isValid } = useFormik({
    initialValues: {
      email: undefined,
      submit: null,
    },
    validationSchema: validationSchemaForgotPassword,
    onSubmit: () => {
      const { email } = values;
      if (!email) {
        return;
      }
      setLoading(true);
      handleForgot({ email })
        .then((res) => {
          const { uuid, forgotPasswordExpires } = res.data;
          dispatch(setUUID(uuid));
          dispatch(setForgotPasswordExpires(forgotPasswordExpires));
          // navigate("/auth/forgot/method");
          navigate("/auth/validation?method=forgot");
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message || err?.message || "An error occurred";
          setErrors({ submit: errorMessage });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  return (
    <div className="find-account-container">
      <div className="find-account-left-container"></div>
      <div className="find-account-right-container">
        <Button
          className="back-button"
          onClick={() => {
            window.history.back();
          }}
        >
          <ArrowLeft className="arrow-icon" />
          <Typography variant="small-text"> Back</Typography>
        </Button>
        <div className="right-container-align-center">
          <img
            src={logo}
            alt="logo"
            className="logo"
          />
          <Typography
            variant="h3"
            className="account-text"
          >
            Find your account
          </Typography>
          <Typography
            variant="small-text"
            className="account-sub-text"
          >
            Enter the email, phone number, or username associated with your account to change your password.
          </Typography>
          {values.email && errors.submit ? (
            <CustomAlert
              variant="danger"
              text={
                <>
                  Sorry, we could not find your account. <br /> Please try again with other information.
                </>
              }
            />
          ) : (
            ""
          )}

          <form
            className="account-body-container"
            onSubmit={handleSubmit}
          >
            <div>
              <Typography
                variant="small-text"
                className="account-field-name"
              >
                Email
              </Typography>
              <Input
                type="text"
                placeholder="Enter your email address"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div>
              <Button
                color="primary"
                className="account-next-button"
                type="submit"
                disabled={!isValid}
              >
                <Typography variant="button-text">{loading ? <SpinnerLoading /> : "Next"}</Typography>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FindAccountDefault;
