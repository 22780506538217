import Dropdown from "../../Base/Dropdown";
import AddNewButton from "../../Base/addNewButton";
import TaskCount from "../../components/TaskCountCard";
import TaskList from "../../components/TaskList";

import "./style.scss";

const TaskWidget = () => {
  const taskCount = 10;

  const taskOption = [
    { value: "In-Progress", label: "In-Progress" },
    { value: "Completed", label: "Completed" },
    { value: "Active", label: "Active" },
  ];

  const taskList = [
    {
      id: 1,
      title: "Upload selects from photoshoot",
      production: "IZZO music videoshoot",
      logo: "",
      dueDate: "",
      status: "Complete",
      createdBy: "Parves Ahamad",
      createdAt: "Feb 29",
      attachments: 2,
    },
    {
      id: 2,
      title: "Upload selects from photoshoot",
      production: "IZZO music videoshoot",
      logo: "",
      dueDate: "",
      status: "In-Progress",
      createdBy: "Parves Ahamad",
      createdAt: "Feb 29",
      attachments: 2,
    },
    {
      id: 3,
      title: "Upload selects from photoshoot",
      production: "IZZO music videoshoot",
      logo: "",
      dueDate: "",
      status: "Not Started",
      createdBy: "Parves Ahamad",
      createdAt: "Feb 29",
      attachments: 2,
    },
    {
      id: 4,
      title: "Upload selects from photoshoot",
      production: "IZZO music videoshoot",
      logo: "",
      dueDate: "",
      status: "Cancelled",
      createdBy: "Parves Ahamad",
      createdAt: "Feb 29",
      attachments: 2,
    },
  ];

  const taskCounts = [
    { title: "Tasks In Progress", count: 0 },
    { title: "Completed Tasks", count: 77 },
    { title: "Active Production", count: 3 },
  ];

  return (
    <div className="taskWidgetMain">
      <div className="taskHeader">
        <div className="task-heading-text">
          <h4 className="title">My Tasks ({taskCount})</h4>
          <div className="dropDownWarpper">
            <Dropdown
              placeholder="All Tasks"
              options={taskOption}
              onChange={(option) => console.log(option)}
            />
          </div>
        </div>

        <TaskList tasks={taskList} />
        <AddNewButton
          text="Add a new task"
          type="button"
          onClick={() => console.log("Add new task from here")}
        />
        <TaskCount tasks={taskCounts} />
      </div>
    </div>
  );
};

export default TaskWidget;
