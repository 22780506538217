import {
  ciara,
  ciaraLogo,
  crush,
  crushLogo,
  spaceX,
  spaceXLogo,
  uber,
  uberLogo,
  whatsNew1,
  whatsNew2,
  whatsNew3,
} from "../../../assets";
import { Typography } from "../../common/Base";
import { BlueSection } from "../../common/Layout";
import { HalfImageText, ImageDescription } from "../../common/Widgets";
import Accordion from "../../common/Widgets/Accordion";
import "./styles.scss";

const WhatsNewDefault = () => {
  const sectionsData = [
    {
      heading: "Overview",
      accordions: [
        { title: "How is Forreels unique?", content: "Hello World 1" },
        {
          title: "What happens if I’m not satisfied with Forreels SuperProducers?",
          content: "Hello World 2",
        },
        { title: "Are there any hidden fees? ", content: "Hello World 3" },
        { title: "Geography ", content: "Hello World 3" },
      ],
    },
    {
      heading: "Geography",
      accordions: [{ title: "How is Forreels unique?", content: "Hello World 1" }],
    },

    // Add more sections as needed
  ];
  const halfImageSectionData = [
    {
      heading: "Actor",
      title: "John Kaluuya",
      text: "John Kaluuya is a British actor. Prominent both on screen and stage, he has received numerous accolades, including an Academy Award, two BAFTA Awards, and a Golden Globe Award. ",
      image: whatsNew1,
    },
    {
      heading: "Actor",
      title: "John Kaluuya",
      text: "John Kaluuya is a British actor. Prominent both on screen and stage, he has received numerous accolades, including an Academy Award, two BAFTA Awards, and a Golden Globe Award. ",
      image: whatsNew2,
    },
    {
      heading: "Actor",
      title: "John Kaluuya",
      text: "John Kaluuya is a British actor. Prominent both on screen and stage, he has received numerous accolades, including an Academy Award, two BAFTA Awards, and a Golden Globe Award. ",
      image: whatsNew3,
    },
  ];
  return (
    <div className="whats-new-container">
      {/* header section */}

      <BlueSection className="blue-section-01-styles">
        <div
          className="blue-section-01-container"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <div className="sub-headingg">
            <Typography variant="pre-title">This month’s</Typography>
          </div>
          <div className="big-heading">
            <Typography variant="h1">Notable Creators.</Typography>
          </div>
          <div className="sub-heading">
            <Typography variant="pre-title">
              <span>Shining</span> light <span>on our most inspiring and emerging creators of this month.</span>
            </Typography>{" "}
          </div>
        </div>
      </BlueSection>

      {/* eclipse and half image section */}
      <div className="container">
        {/* eclipse gradient */}
        <div className="eclipse"></div>
        <div className="section-02-containers">
          {halfImageSectionData.map((item) => (
            <HalfImageText
              align="left"
              key={item.image}
              heading={item.heading}
              title={item.title}
              text={item.text}
              image={[{ src: item.image, carousel: false }]}
              titleVariant={"subtitle"}
              linkButton={true}
              textVariant={"body-1"}
              className="hh"
            />
          ))}
        </div>
      </div>

      {/* middle blue section and card section */}
      <div className="container">
        {/* middle blue section */}

        <BlueSection className="blue-section-middle">
          <div
            className="section-03-container"
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <div className="sub-heading">
              <Typography variant="pre-title">
                <span>This month’s</span>
              </Typography>
            </div>
            <div className="big-heading">
              <Typography variant="h1">Staff picks</Typography>
            </div>
            <div className="sub-heading pstyles">
              <Typography variant="pre-title">Forreels staff hand picked selections of the month.</Typography>
            </div>
          </div>
        </BlueSection>
        <div className="row mt-5 imageDescriptions">
          <div
            className="col-12 col-md-6 id"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <ImageDescription
              cover={uber}
              logo={uberLogo}
              text={"UberEats uses Forreels.io to book their crew to create some tasty commercials."}
            />
          </div>
          <div
            className="col-12 col-md-6 id"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <ImageDescription
              cover={spaceX}
              logo={spaceXLogo}
              text={"Forreels.io collaborated with SPACEX on their journey to Mars."}
            />
          </div>

          <div
            className="col-12 col-md-6 id"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <ImageDescription
              cover={crush}
              logo={crushLogo}
              text={"Master Hairstylist Giovanni Amar shares his top 5 film styling secrets from “Crush”."}
            />
          </div>
          <div
            className="col-12 col-md-6 id"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <ImageDescription
              cover={ciara}
              logo={ciaraLogo}
              text={"Ciara crews up with Forreels.io for her new single “FOMO”."}
            />
          </div>
        </div>
      </div>
      {/* common questions container section */}
      <div className="container">
        <div className="common-questions-container">
          <div
            className="main-headingg"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <Typography variant="h2">Common Questions</Typography>
          </div>
          <div
            className="accordions"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            {sectionsData.map((section, index) => (
              <div
                className="accordions-containers"
                key={index}
              >
                <div className="accordion-heading">
                  <Typography variant="h3">{section.heading}</Typography>
                </div>
                <div className="all-accordions">
                  {section.accordions.map((accordion, i) => (
                    <Accordion
                      key={i}
                      title={accordion.title}
                      content={accordion.content}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* plain section and blue gradient last section */}
      <div className="plain-section-blue-section">
        <div className="eclipse1"></div>
        <div className="container plain-section">
          <div
            className="text"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <Typography variant="h2">Stay connected.</Typography>
          </div>
          <section className="parent">
            <div className="cta">
              <div className="cta__content">
                <form className="input-container">
                  <span>
                    <input
                      type="email"
                      name="query"
                      placeholder="example@example.com"
                      autoComplete="off"
                      required
                    />
                  </span>

                  <span>
                    <button
                      type="submit"
                      className="subscribe-btn"
                    >
                      <Typography variant="button-text">Subscribe</Typography>
                    </button>
                  </span>
                </form>
              </div>
            </div>
          </section>
          {/* <SearchBar inputPlaceholder={'example@gmail.com'} buttonName={'Subscribe'} icon={false} /> */}
        </div>

        <BlueSection className="blue-section-styles">
          <div className="section-text">
            <div className="section-text-style">
              <Typography variant="h2">Forreels.io, supporting the world’s top content creators.</Typography>
            </div>
          </div>
        </BlueSection>
      </div>
    </div>
  );
};

export default WhatsNewDefault;
