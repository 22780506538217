import React, { useEffect, useRef, useState } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Button, Select, Typography } from "../../../../components/common/Base";
import { SearchBar } from "../../../../components/common/Widgets";
import "./styles.scss";

const languageOptions = [
  "Bengali",
  "English",
  "French",
  "Hindi",
  "Indonesian",
  "Mandarin Chinese",
  "Portuguese",
  "Russian",
  "Spanish",
  "Standard Arabic",
  "Standard German",
  "Urdu",
];
const ethnicityOptions = [
  "American Indian/Alaska Native",
  "African",
  "Asian",
  "Arabian",
  "Alaskan Native",
  "Australasian/Aboriginal",
  "Black or African American",
  "Caribbean",
  "Cuban",
  "Chinese",
  "Decline to Respond",
  "Decline to Respond",
  "European/Anglo Saxon",
  "Filipino",
  "Guamanian",
  "Indian",
  "Japanese",
  "Korean",
  "Latin American",
  "Melanesian",
  "Mexican",
  "Micronesian",
  "Native Hawaiian or other Pacific Islander",
  "Other Pacific Islander",
  "Other Hispanic",
  "Other Asian",
  "Polynesian",
  "Puerto Rican",
  "US or Canadian Indian",
  "Vietnamese",
  "White",
];
const skillsOptions = [
  "Accents",
  "Acrobatics",
  "Badminton",
  "Basketball",
  "Baseball",
  "Cheerleading",
  "Cricket",
  "Dancing",
  "Fencing",
  "Football",
  "Foreign Languages",
  "Gun Handling",
  "Gymnastics",
  "Hockey",
  "horseback riding",
  "Improv",
  "Martial Arts",
  "Musical instruments",
  "Racquetball",
  "Roller Hockey",
  "Rugby",
  "Singing",
  "Skiing",
  "Soccer",
  "Softball",
  "Stage combat",
  "Stand up",
  "Tennis",
  "Voiceover acting",
  "Volleyball",
];
const disabilitiesOptions = [
  "Achondroplasia Dwarfism",
  "Amputee",
  "ASD",
  "Asperger’s",
  "Autism",
  "Bipolar Disorder",
  "Cerebral palsy",
  "Down syndrome",
  "Dyspraxia",
  "Epilepsy",
  "Hearing Impaired",
  "Multiple Sclerosis",
  "Neurofibromatosis",
  "Parkinson’s Disease",
  "PTSD",
  "Quadriplegia",
  "Tourette Syndrome",
  "Tetraplegia",
  "Vision Impaired",
];
const cameraExperienceOptions = [
  "ARRI35",
  "ARRI ALEXA MINI",
  "ARRI ALEXA MINI LF",
  "ARRI AMIRA",
  "RED CAMERA SERIES",
  "PANASONIC VARICAM",
  "CANON C700",
  "BLACKMAGIC URSA",
  "SONY VENICE 2",
  "SONY A7 III",
  "CANON XF605",
  "PHANTOM VEO",
];
const supportExperienceOptions = ["DJI SERIES", "EASYRIG", "READYRIG", "BOLT JR", "TIFFEN  STEADICAMS"];

interface DetailsSelectionProps {
  handleNext?: () => void;
  step?: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const DetailsSelection: React.FC<DetailsSelectionProps> = ({ handleNext, step, setStep }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [subStep, setSubStep] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Change 768 to your desired breakpoint
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleNextSubStep = () => {
    if (subStep < 5) {
      setSubStep((prevStep) => prevStep + 1);
      scrollToSection();
    }
  };

  const handleStepDotClick = (index: number) => setStep(index);

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
    scrollToSection();
  };

  const scrollToSection = () => {
    // Scrolls to the section
    if (sectionRef.current) sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        className="detail-selection-container"
        ref={sectionRef}
      >
        <div className="detail-selection-left">
          <Typography
            variant="pre-title"
            className="es-sub-title-1"
          >
            Language. Skills. and more.
          </Typography>
          <Typography
            variant="h1"
            className="es-main-heading"
          >
            Select your details.
          </Typography>
          <Typography
            variant="pre-title"
            className="es-sub-title-2"
          >
            Forreels.io welcomes all members of the media and entertainment industry to a revolutionary space. Step into
            the future with us.
          </Typography>
          <div className="previous-dot-next-buttons">
            <Button
              className="previous-button"
              onClick={handleBack}
            >
              <Typography variant="small-text">
                <ArrowLeft /> Previous
              </Typography>
            </Button>
            <div className="step-dots">
              {[0, 1, 2].map((index) => (
                <div
                  key={index}
                  className={`dot ${step === index ? "active" : ""}  ${step === 2 ? "third-ad-dots" : ""}`}
                  onClick={() => handleStepDotClick(index)}
                  onKeyDown={() => {
                    // TODO: dotIndex not exist
                    // if (e.key === 'Enter') {
                    // 	handleStepDotClick(dotIndex);
                    // }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <span className="sr-only">Step {index + 1}</span> {/* Screen reader-only text for accessibility */}
                </div>
              ))}
            </div>
            <Button
              className="next-button"
              onClick={handleNext}
            >
              <Typography variant="small-text">
                Next <ArrowRight />{" "}
              </Typography>
            </Button>
          </div>
        </div>
        <div className="detail-selection-right">
          <SearchBar
            inputPlaceholder="Search"
            icon={true}
          />
          {subStep === 0 && (
            <div className="expertise-list">
              <Typography
                variant="pre-title"
                className="el-main-heading"
              >
                Language
              </Typography>
              <Select
                options={languageOptions}
                onChange={handleNextSubStep}
              />
            </div>
          )}
          {subStep === 1 && (
            <div className="expertise-list">
              <Typography
                variant="pre-title"
                className="el-main-heading"
              >
                Ethnicty
              </Typography>
              <Select
                options={ethnicityOptions}
                onChange={handleNextSubStep}
              />
            </div>
          )}
          {subStep === 2 && (
            <div className="expertise-list">
              <Typography
                variant="pre-title"
                className="el-main-heading"
              >
                Skills
              </Typography>
              <Select
                options={skillsOptions}
                onChange={handleNextSubStep}
              />
            </div>
          )}
          {subStep === 3 && (
            <div className="expertise-list">
              <Typography
                variant="pre-title"
                className="el-main-heading"
              >
                Disabilities
              </Typography>
              <Select
                options={disabilitiesOptions}
                onChange={handleNextSubStep}
              />
            </div>
          )}
          {subStep === 4 && (
            <div className="expertise-list">
              <Typography
                variant="pre-title"
                className="el-main-heading"
              >
                Camera Experience
              </Typography>
              <Select
                options={cameraExperienceOptions}
                onChange={handleNextSubStep}
              />
            </div>
          )}
          {subStep === 5 && (
            <div className="expertise-list">
              <Typography
                variant="pre-title"
                className="el-main-heading"
              >
                Support Experience
              </Typography>
              <Select
                options={supportExperienceOptions}
                onChange={handleNextSubStep}
              />
            </div>
          )}
        </div>
        {isSmallScreen && (
          <div className="detail-selection-footer">
            <div className="previous-dot-next-buttons">
              <Button
                className="previous-button"
                onClick={handleBack}
              >
                <Typography variant="small-text">
                  <ArrowLeft /> Previous
                </Typography>
              </Button>
              <div className="step-dots">
                {[0, 1, 2].map((index) => (
                  <div
                    key={index}
                    className={`dot ${step === index ? "active" : ""}  ${step === 2 ? "third-ad-dots" : ""}`}
                    onClick={() => handleStepDotClick(index)}
                    onKeyDown={() => {
                      // TODO: dotIndex not exist
                      // if (e.key === 'Enter') {
                      // 	handleStepDotClick(dotIndex);
                      // }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="sr-only">Step {index + 1}</span> {/* Screen reader-only text for accessibility */}
                  </div>
                ))}
              </div>
              <Button
                className="next-button"
                onClick={handleNext}
              >
                <Typography variant="small-text">
                  Next <ArrowRight />{" "}
                </Typography>
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DetailsSelection;
