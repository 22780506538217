import EmptyList from "../../Base/EmptyList";
import "./style.scss";
import { ReactComponent as NotificationIcon } from "./../../../../assets/icons/notificationIcon.svg";

interface Notification {
  message: string;
  date: string;
}

interface NotificationListProps {
  notifications: Notification[];
}

const NotificationList: React.FC<NotificationListProps> = ({ notifications }) => {
  return (
    <>
      {notifications.length > 0 ? (
        <div className="notifications-list">
          {notifications.map((notification, index) => (
            <div
              className="notification"
              key={index}
            >
              <span className="ntf-border"></span>
              <div className={`dot ${index <= 2 ? "active" : ""}`}></div>
              <div className="content">
                <p className="message">
                  {notification.message} <span>{index >= 1 ? "message" : ""}</span>
                </p>
                <p className="date">{notification.date}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyList
          text="No notifications yet."
          icon={<NotificationIcon />}
          className="emptyNotification"
        />
      )}
    </>
  );
};

export default NotificationList;
