import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { blender, facebookLogo, gitlab, google, iqvia, loreal, rollins } from "../../../../assets";
import "./styles.scss";

const LogoSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          rows: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div>
        <img
          src={facebookLogo}
          alt="Facebook"
          className="img-fluid"
          width={150}
        />
      </div>
      <div>
        <img
          src={blender}
          alt="Blender"
          className="img-fluid"
          width={150}
        />
      </div>
      <div>
        <img
          src={gitlab}
          alt="GitLab"
          className="img-fluid"
          width={150}
        />
      </div>
      <div>
        <img
          src={google}
          alt="Google"
          className="img-fluid"
          width={150}
        />
      </div>
      <div>
        <img
          src={iqvia}
          alt="Iqvia"
          className="img-fluid"
          width={150}
        />
      </div>
      <div>
        <img
          src={loreal}
          alt="Loreal"
          className="img-fluid"
          width={150}
        />
      </div>
      <div>
        <img
          src={rollins}
          alt="Rollins"
          className="img-fluid"
          width={150}
        />
      </div>
    </Slider>
  );
};

export default LogoSlider;
