import React, { useRef, useState } from "react";
import { useRive } from "@rive-app/react-canvas";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Link } from "react-router-dom";
import {
  adSuperProducer2,
  adSuperProducer3,
  adSuperProducer4,
  adSuperProducer5,
  logoBlack,
  logoBlue,
  textLogoBlack,
  textLogoBlue,
} from "../../../assets";
import { Button, Typography } from "../../../components/common/Base";
import "./styles.scss";

const AccountDetails_SuperProducerDefault = () => {
  const [step, setStep] = useState(0);
  const { rive, RiveComponent } = useRive({
    src: "logo_hover_anim_5.riv",
    stateMachines: "State Machine 1",
    autoplay: true,
  });
  const stepMainHeadings = [
    "Welcome to forreels",
    "SuperProducer® Messenger",
    "Expand your tribe.",
    "Unlimited Opportunity",
    "Organize your productions.",
    "",
  ];
  const stepSubHeadings = [
    "Hey, SuperProducer®. Organize your productions and build teams. ",
    "Talk in groups with your crew based on departments, productions or milestones.",
    " Boost your circle with elite creatives globally; your creative arsenal becomes boundless, making you the ultimate connect.",
    "Worldwide creative opportunities and a consistent stream of work.",
    "Review messages, schedule tasks, delegate responsibilities to your teams, and track project milestones diligently",
    "",
  ];
  const images = ["", adSuperProducer2, adSuperProducer3, adSuperProducer4, adSuperProducer5];
  const sectionRef = useRef(null);

  const handleNext = () => setStep((prevStep) => prevStep + 1);

  const handleStepDotClick = (index: number) => setStep(index);

  const handleBack = () => setStep((prevStep) => prevStep - 1);

  return (
    <div
      className={`full-container-ad ${step === 0 ? "firstPage" : step === 1 ? "secondPage" : step === 2 ? "thirdPage" : step === 3 ? "fourthPage" : step === 4 ? "fifthPage" : step === 5 ? "sixthPage" : ""}`}
    >
      <div
        className="container accountDetails_SuperProducer-container"
        ref={sectionRef}
      >
        <div className="accountDetails_navbar">
          <Link
            className="navbar-brandd"
            to="/"
          >
            {step !== 2 ? (
              <>
                <img
                  src={logoBlue}
                  alt="Logo"
                  width={40}
                  height={40}
                  className="img-fluid logo me-3"
                />
                <img
                  src={textLogoBlue}
                  alt="Text logo"
                  className="img-fluid text-logo"
                />
              </>
            ) : (
              <>
                <img
                  src={logoBlack}
                  alt="Logo"
                  width={40}
                  height={40}
                  className="img-fluid logo me-3"
                />
                <img
                  src={textLogoBlack}
                  alt="Text logo"
                  className="img-fluid text-logo"
                />
              </>
            )}
          </Link>
          {step < 5 && (
            <div className="skip-button-container">
              <Button
                className={`skip-button ${step === 0 ? "first-ad-details" : step === 1 ? "second-ad-details" : step === 2 ? "third-ad-details" : step === 3 ? "fourth-ad-details" : step === 4 ? "fifth-ad-details" : ""}`}
                onClick={handleNext}
              >
                <Typography variant="button-text">Skip</Typography>
              </Button>
            </div>
          )}
        </div>
        <div className="accountDetails_body">
          {step !== 5 ? (
            <div
              className={`ad-details-container ${step === 0 ? "first-ad-details" : step === 1 ? "second-ad-details" : step === 2 ? "third-ad-details" : step === 3 ? "fourth-ad-details" : step === 4 ? "fifth-ad-details" : ""}`}
            >
              <div className="ad-image">
                {step !== 0 && step !== 5 ? (
                  <img
                    src={images[step]}
                    alt="image1"
                  />
                ) : (
                  <RiveComponent
                    onMouseEnter={() => rive && rive.play()}
                    onMouseLeave={() => rive && rive.play()}
                    className="rive-style"
                  />
                )}
              </div>
              <Typography
                variant="h3"
                className="ad-main-heading"
              >
                {stepMainHeadings[step]}
              </Typography>
              <Typography
                variant="small-text"
                className="ad-sub-heading"
              >
                {stepSubHeadings[step]}
              </Typography>
              <div className="step-dots">
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <div
                    key={index}
                    className={`dot ${step === index ? "active" : ""}  ${step === 2 ? "third-ad-dots" : ""}`}
                    onClick={() => handleStepDotClick(index)}
                    onKeyDown={() => {
                      // TODO: dotIndex not exist
                      // if (e.key === 'Enter') {
                      // 	handleStepDotClick(dotIndex);
                      // }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="sr-only">Step {index + 1}</span> {/* Screen reader-only text for accessibility */}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="last-step-container">
              <Typography
                variant="h3"
                className="ad-main-heading"
              >
                Choose an account type.{" "}
              </Typography>
              <div className="ad-boxes">
                <Button className="ad-box-1">
                  <Typography variant="small-text">Free</Typography>
                  <Typography variant="h3">Producer</Typography>
                  <ul className="ad-box-list">
                    <li className="ad-list-item">
                      <Typography variant="small-text">Basic Messenger</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">Up to 4 crew per production</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">Basic client opportunities</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">Pay per view seminar and course</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">2 productions per month</Typography>
                    </li>
                  </ul>
                  <ArrowRight />
                </Button>
                <Button className="ad-box-2">
                  <Typography variant="small-text">$100 per month</Typography>
                  <Typography variant="h3">SuperProducer®</Typography>
                  <ul className="ad-box-list">
                    <li className="ad-list-item">
                      <Typography variant="small-text">Premium Messenger - Beta</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">Up to 4 crew per production</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">Basic client opportunities</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">Seminar and courses - Beta</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">2 productions per month</Typography>
                    </li>
                  </ul>
                  <Typography variant="small-text">Recommended</Typography>
                </Button>
              </div>
              <div className="step-dots">
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <div
                    key={index}
                    className={`dot ${step === index ? "active" : ""}`}
                    onClick={() => handleStepDotClick(index)}
                    onKeyDown={() => {
                      // TODO: dotIndex not exist
                      // if (e.key === 'Enter') {
                      // 	handleStepDotClick(dotIndex);
                      // }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="sr-only">Step {index + 1}</span> {/* Screen reader-only text for accessibility */}
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* {displayBody()} */}
        </div>
        {step < 5 && (
          <div className="accountDetails_footer">
            <div className={`previousAndNextButtons-container `}>
              <Button
                className={`ad-previous-button ${step === 2 ? "third-ad-details" : ""}`}
                onClick={handleBack}
                disabled={step === 0}
              >
                <Typography variant="button-text">
                  <ArrowLeft /> Previous
                </Typography>
              </Button>
              <Button
                className={`ad-next-button ${step === 2 ? "third-ad-details" : ""}`}
                onClick={handleNext}
              >
                <Typography variant="button-text">
                  Next <ArrowRight />
                </Typography>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountDetails_SuperProducerDefault;
