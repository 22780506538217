import { useEffect, useRef } from "react";
import { Typography } from "../../Base";
import "./style.scss";

interface PercentageTextProps {
  number: number;
  text: string;
  topText?: string;
}

const PercentageText: React.FC<PercentageTextProps> = ({ number, text, topText }) => {
  const objRef = useRef<HTMLDivElement | null>(null);

  const start = 0;
  const end = number;
  const duration = 2000;

  useEffect(() => {
    let startTimestamp: number | null = null;
    let animationFrameId: number | null = null;

    const animateCounter = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;

      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      if (objRef.current) {
        objRef.current.innerHTML = Math.floor(progress * (end - start) + start).toString();
      }

      if (progress < 1) {
        animationFrameId = window.requestAnimationFrame(animateCounter);
      }
    };

    const handleIntersection = ([entry]: IntersectionObserverEntry[]) => {
      if (entry.isIntersecting) {
        window.requestAnimationFrame(animateCounter);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    if (objRef.current) {
      observer.observe(objRef.current);
    }

    // Clean up the animation frame when the component unmounts
    return () => {
      if (animationFrameId !== null) {
        window.cancelAnimationFrame(animationFrameId);
      }
      observer.disconnect();
    };
  }, [start, end, duration]);

  return (
    <div className="percentage-text">
      <Typography
        variant="subtitle"
        className="mb-0 topText"
      >
        {topText}
      </Typography>
      <div className="d-flex">
        <Typography variant="h1">
          <div ref={objRef}>{number}</div>
        </Typography>
        <Typography
          variant="h2"
          className="ms-2 percentage-symbol"
        >
          %
        </Typography>
      </div>
      <Typography
        variant="body-1"
        className="bottomText"
      >
        {text}
      </Typography>
    </div>
  );
};

export default PercentageText;
