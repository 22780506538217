import { useState } from "react";
import { Button, Input, SpinnerLoading, Typography } from "../../../components/common/Base";
import "./styles.scss";

import { useFormik } from "formik";
import { CheckCircle } from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleForgotPassword } from "../../../api/auth/auth.service";
import { logo } from "../../../assets";
import { RootState } from "../../../store";
import { validationSchemaCreateNewPassword } from "../../../utils/validationSchema";

const CreateNewPasswordDefault = () => {
  const [loading, setLoading] = useState(false);
  const { uuid, forgotPasswordToken: token } = useSelector((state: RootState) => state.auth.profile);
  const isPasswordLengthValid = (password: string) => {
    return password.length >= 8 && password.length <= 30;
  };

  const containsNumber = (password: string) => {
    return /^(?=.*[0-9])(?=.*[a-zA-Z]).+$/.test(password);
  };
  const navigate = useNavigate();

  const { values, handleChange, handleBlur, handleSubmit, errors, setErrors } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      submit: null,
    },
    validationSchema: validationSchemaCreateNewPassword,
    onSubmit: () => {
      const { password, confirmPassword } = values;
      if (!uuid || !token || !password || !confirmPassword) {
        setErrors({ submit: "Invalid request" });
        return;
      }
      setLoading(true);
      handleForgotPassword({ uuid, token, password, confirmPassword })
        .then(() => {
          navigate("/auth/forgot/success");
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="create-new-password-container">
      <div className="create-new-password-left-container"></div>
      <div className="create-new-password-right-container">
        <div className="cnp-right-container-align-center">
          <img
            src={logo}
            alt="logo"
            className="logo"
          />
          <Typography
            variant="h3"
            className="choose-password-text"
          >
            Choose a new password
          </Typography>

          <Typography
            variant="small-text"
            className="choose-password-sub-text"
          >
            You'll be logged out of all active sessions after your password is changed.
          </Typography>
          <form
            className="choose-password-body-container"
            onSubmit={handleSubmit}
          >
            <div>
              <Typography
                variant="small-text"
                className="choose-password-field-name"
              >
                Enter new password
              </Typography>

              <Input
                type="password"
                placeholder="Type here"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.password ?? (
                    <div className="helper-text">
                      <div
                        className={`helper-text-1 ${isPasswordLengthValid(values.password) && containsNumber(values.password) ? "hidden" : ""}`}
                      >
                        <div className="point-circle"></div>
                        <Typography
                          variant="small-text"
                          className={`hp-1-text ${isPasswordLengthValid(values.password) ? "hp-checked" : ""}`}
                        >
                          Between 8 to 30 characters
                        </Typography>
                      </div>
                      <div
                        className={`helper-text-2 ${isPasswordLengthValid(values.password) && containsNumber(values.password) ? "hidden" : ""}`}
                      >
                        <div className="point-circle"></div>
                        <Typography
                          variant="small-text"
                          className={`hp-2-text`}
                        >
                          At least one number (0-9)
                        </Typography>
                      </div>
                      <div
                        className={`helper-success ${isPasswordLengthValid(values.password) && containsNumber(values.password) ? "hp-success" : ""}`}
                      >
                        <CheckCircle className="check-icon" /> Now, that's a good password!
                      </div>
                    </div>
                  )
                }
                state={errors.password ? "error" : ""}
              />
            </div>
            <div>
              <Typography
                variant="small-text"
                className="choose-password-field-name"
              >
                Confirm your password
              </Typography>
              <Input
                type="password"
                placeholder="Type here"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.confirmPassword}
                state={errors.confirmPassword ? "error" : ""}
              />
            </div>
            <div>
              <Button
                color="primary"
                className="choose-password-next-button"
                type="submit"
                disabled={
                  !!(
                    ((!values.password || !values.confirmPassword || errors.password) ??
                      errors.confirmPassword ??
                      !isPasswordLengthValid(values.password)) ||
                    !containsNumber(values.password)
                  )
                }
              >
                <Typography variant="button-text">{loading ? <SpinnerLoading /> : "Change password"}</Typography>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateNewPasswordDefault;
