import { Footer, MenuNavbar } from "../components/common/Layout";
import { SuperProducersDefault } from "../components/features";

const SuperProducers = () => (
  <>
    <MenuNavbar />
    <SuperProducersDefault />
    <Footer showLargeFooter />
  </>
);

export default SuperProducers;
