import React from "react";
import Card from "../Card";
import "./style.scss";

interface EmptyListProps {
  className?: string;
  text?: string;
  icon?: React.ReactNode;
}

const EmptyList: React.FC<EmptyListProps> = ({ className, text, icon }) => {
  return (
    <Card className={`emptyTaskCard ${className}`}>
      <div className="icon">{icon}</div>
      <p className="text">{text}</p>
    </Card>
  );
};

export default EmptyList;
