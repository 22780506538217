import React from "react";
import Card from "../../Base/Card";
import EmptyList from "../../Base/EmptyList";
import "./style.scss";
import { ReactComponent as ProfilePic } from "./../../../../assets/icons/profilePic.svg";
import { ReactComponent as RemovIcon } from "./../../../../assets/icons/removeIcon.svg";
import { ReactComponent as MemberIcon } from "./../../../../assets/icons/memberProfileIcon.svg";
import { ReactComponent as TeamMemberIcon } from "./../../../../assets/icons/teamMmember.svg";

interface TeamMembers {
  id?: number;
  logo?: string;
  status?: string;
}

interface TaskListProps {
  members: TeamMembers[];
}

const TeamMemberList: React.FC<TaskListProps> = ({ members }) => {
  return (
    <>
      {members.length > 0 ? (
        <div className="taskListMain">
          {members.map((member, index) => {
            return (
              <Card
                key={index}
                className="main-card"
              >
                <div className="card-sub">
                  <div className="profile-pic">
                    <ProfilePic />
                  </div>
                  <div className="info">
                    <p className="email">
                      jsmith@gmail.com <span className="status">Pending</span>
                    </p>
                    <p className="role">Video editor</p>
                  </div>

                  <button className="delete">
                    <RemovIcon />
                  </button>
                </div>

                <div className="card-sub">
                  <div className="profile-pic">
                    <MemberIcon />
                  </div>
                  <div className="info">
                    <p className="email">rakibalborbori@gmail.com</p>
                    <p className="role">Video editor</p>
                  </div>

                  <button className="delete">
                    <RemovIcon />
                  </button>
                </div>
              </Card>
            );
          })}
        </div>
      ) : (
        <EmptyList
          text="No team member yet."
          icon={<TeamMemberIcon />}
          className="emptyTaskCard"
        />
      )}
    </>
  );
};

export default TeamMemberList;
