import Dropdown from "../../Base/Dropdown";
import ProductionList from "../../components/ProductionList";
import "./style.scss";

const ProductionWidget = () => {
  const totalProductionCount = 10;

  const productionOption = [
    { value: "Production-1", label: "Production-1" },
    { value: "Production-2", label: "Production-2" },
    { value: "Production-3", label: "Production-3" },
  ];

  const productionList = [
    { id: 1, title: "Interstellar", date: "Apr 7, 2024", taskCount: 10, status: "Active" },
    { id: 1, title: "One Piece", date: "Apr 7, 2024", taskCount: 10, status: "Completed" },
  ];
  return (
    <div className="productionWidgetMain">
      <div className="productionHeader">
        <h4 className="title">Productions ({totalProductionCount})</h4>
        <div className="dropDownWarpper">
          <Dropdown
            placeholder="All Productions"
            options={productionOption}
            onChange={(option) => console.log(option)}
          />
        </div>
      </div>
      <ProductionList productions={productionList} />
    </div>
  );
};

export default ProductionWidget;
