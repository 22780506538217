import { Button, Typography } from "../../../../components/common/Base";
import "./styles.scss";

interface GetStartedProps {
  handleNext: () => void;
}

const GetStarted: React.FC<GetStartedProps> = ({ handleNext }) => {
  return (
    <>
      <div className="creator-onboarding-body">
        <div className="creator-onboarding-body-left">
          <Typography
            variant="h1"
            className="co-main-heading"
          >
            It’s easy to get started on forreels
          </Typography>
          <Typography
            variant="pre-title"
            className="co-sub-heading"
          >
            It only takes 5-10 minutes and you can edit it later. We’ll save as you go.
          </Typography>
          <Button
            color="primary"
            className="co-button"
            onClick={handleNext}
          >
            <Typography variant="button-text">Get started</Typography>
          </Button>
        </div>
        <div className="creator-onboarding-body-right">
          <div className="gs-heading-01">
            <Typography
              variant="subtitle"
              className="cobr-main-heading"
            >
              1 Pick your passion
            </Typography>
            <Typography
              variant="body-1"
              className="cobr-sub-heading"
            >
              Specify your expertise and career details to help users find you effectively.
            </Typography>
          </div>
          <div className="gs-heading-02">
            <Typography
              variant="subtitle"
              className="cobr-main-heading"
            >
              2 Make it pop
            </Typography>
            <Typography
              variant="body-1"
              className="cobr-sub-heading"
            >
              Craft your profile to stand out by showcasing some of your work.
            </Typography>
          </div>
          <div className="gs-heading-03">
            <Typography
              variant="subtitle"
              className="cobr-main-heading"
            >
              3 Wrap it up and hit publish{" "}
            </Typography>
            <Typography
              variant="body-1"
              className="cobr-sub-heading"
            >
              Go live with your profile and dive into collaboration with our creatives to kickstart projects.
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStarted;
