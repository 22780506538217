import { useRive } from "@rive-app/react-canvas";
import React, { useRef, useState } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Link } from "react-router-dom";
import {
  adCreator1,
  adCreator3,
  adCreator4,
  adSuperProducer4,
  logoBlack,
  logoBlue,
  textLogoBlack,
  textLogoBlue,
} from "../../../assets";
import { Button, Typography } from "../../../components/common/Base";
import "./styles.scss";

const AccountDetails_CreatorDefault = () => {
  const [step, setStep] = useState(0);
  const { rive, RiveComponent } = useRive({
    src: "logo_hover_anim_5.riv",
    stateMachines: "State Machine 1",
    autoplay: true,
  });
  const stepMainHeadings = [
    "Welcome to forreels",
    "Dynamic Profiles",
    "Unlimited Opportunity",
    "Creative Showcase",
    "Career Development",
  ];
  const stepSubHeadings = [
    "Hey, Creator. Build your profile and watch the work come in.",
    "Talk in groups with your crew based on departments, productions or milestones.",
    "Worldwide creative opportunities and a consistent stream of work.",
    'Forreels showcases creators from around the globe on our prestigious "Notable Creators" page.',
    "Goal setting technology for career goals and continue to learn with Forreels.io seminars and courses.",
  ];
  const images = ["", adCreator1, adSuperProducer4, adCreator3, adCreator4];
  const sectionRef = useRef(null);

  const handleNext = () => {
    const nextStep = step + 1;
    if (nextStep <= 4) {
      setStep(nextStep);
    }
  };

  const handleStepDotClick = (index: number) => setStep(index);

  const handleBack = () => setStep((prevStep) => prevStep - 1);

  return (
    <div
      className={`full-container-ad ${step === 0 ? "firstPage" : step === 1 ? "secondPage" : step === 2 ? "thirdPage" : step === 3 ? "fourthPage" : step === 4 ? "fifthPage" : ""}`}
    >
      <div
        className={`container ${step === 4 ? "accountDetails_creator-containerr" : "accountDetails_creator-container"} `}
        ref={sectionRef}
      >
        <div className="accountDetails_navbar">
          <Link
            className="navbar-brandd"
            to="/"
          >
            {step !== 2 ? (
              <>
                <img
                  src={logoBlue}
                  alt="Logo"
                  width={40}
                  height={40}
                  className="img-fluid logo me-3"
                />
                <img
                  src={textLogoBlue}
                  alt="Text logo"
                  className="img-fluid text-logo"
                />
              </>
            ) : (
              <>
                <img
                  src={logoBlack}
                  alt="Logo"
                  width={40}
                  height={40}
                  className="img-fluid logo me-3"
                />
                <img
                  src={textLogoBlack}
                  alt="Text logo"
                  className="img-fluid text-logo"
                />
              </>
            )}
          </Link>

          <div className="skip-button-container">
            <Button
              className={`skip-button ${step === 0 ? "first-ad-details" : step === 1 ? "second-ad-details" : step === 2 ? "third-ad-details" : step === 3 ? "fourth-ad-details" : step === 4 ? "fifth-ad-details" : ""}`}
              onClick={handleNext}
            >
              <Typography variant="button-text">Skip</Typography>
            </Button>
          </div>
        </div>
        <div className="accountDetails_body">
          <div
            className={`ad-details-container ${step === 0 ? "first-ad-details" : step === 1 ? "second-ad-details" : step === 2 ? "third-ad-details" : step === 3 ? "fourth-ad-details" : step === 4 ? "fifth-ad-details" : ""}`}
          >
            <div className="ad-image">
              {step !== 0 ? (
                <img
                  src={images[step]}
                  alt="image1"
                />
              ) : (
                <RiveComponent
                  onMouseEnter={() => rive && rive.play()}
                  onMouseLeave={() => rive && rive.play()}
                  className="rive-style"
                />
              )}
            </div>
            <Typography
              variant="h3"
              className="ad-main-heading"
            >
              {stepMainHeadings[step]}
            </Typography>
            <Typography
              variant="small-text"
              className="ad-sub-heading"
            >
              {stepSubHeadings[step]}
            </Typography>
            <div className="step-dots">
              {[0, 1, 2, 3, 4].map((index) => (
                <div
                  key={index}
                  className={`dot ${step === index ? "active" : ""}  ${step === 2 ? "third-ad-dots" : ""}`}
                  onClick={() => handleStepDotClick(index)}
                  onKeyDown={() => {
                    // TODO: dotIndex not exist
                    // if (e.key === 'Enter') {
                    // 	handleStepDotClick(dotIndex);
                    // }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <span className="sr-only">Step {index + 1}</span> {/* Screen reader-only text for accessibility */}
                </div>
              ))}
            </div>

            {step === 4 && (
              <Button
                color="secondary"
                variant="outlined"
                className="free-button"
              >
                <Typography variant="button-text">Join for free</Typography>
              </Button>
            )}
          </div>
        </div>
        {step < 4 && (
          <div className="accountDetails_footer">
            <div className={`previousAndNextButtons-container `}>
              <Button
                className={`ad-previous-button ${step === 2 ? "third-ad-details" : ""}`}
                onClick={handleBack}
                disabled={step === 0}
              >
                <Typography variant="button-text">
                  <ArrowLeft /> Previous
                </Typography>
              </Button>
              <Button
                className={`ad-next-button ${step === 2 ? "third-ad-details" : ""}`}
                onClick={handleNext}
              >
                <Typography variant="button-text">
                  Next <ArrowRight />
                </Typography>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountDetails_CreatorDefault;
