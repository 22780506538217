import React, { useRef, useState } from "react";
import { ArrowRight } from "react-feather";
import { Button, Typography } from "../../../components/common/Base";
import AddSomething from "./AddSomething";
import CreaterAssessment from "./CreaterAssessment";
import Endorsements from "./Endorsements";
import ServiceProductAssessment from "./ServiceProductAssessment";
import Tip from "./Tip";
import TipThanks from "./TipThanks";
import "./styles.scss";

const ClientReviewDefault = () => {
  const [step, setStep] = useState(0);
  const stepMainHeadings = [
    "Creater assessment",
    "Service and Product Assessment",
    "Anything you’d like to add?",
    "Endorsements",
    "Tip",
    "",
  ];
  const stepSubHeadings = [
    "Let us know how we are doing",
    "Let us know how we are doing",
    "Help us help you",
    "You give the creators the crown",
    "Show some love",
    "",
  ];
  const sectionRef = useRef<HTMLDivElement | null>(null);

  const scrollToSection = () => {
    // Scrolls to the section
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const displayBody = () => {
    if (step === 0) {
      return <CreaterAssessment />;
    } else if (step === 1) {
      return <ServiceProductAssessment />;
    } else if (step === 2) {
      return <AddSomething />;
    } else if (step === 3) {
      return <Endorsements />;
    } else if (step === 4) {
      return <Tip />;
    } else {
      return <TipThanks />;
    }
  };
  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
    scrollToSection();
  };
  const handleBack = () => setStep((prevStep) => prevStep - 1);

  return (
    <>
      <div
        className="container client-review-container"
        ref={sectionRef}
      >
        <div className="header">
          <Typography
            variant="subtitle"
            className="subtitle"
          >
            {stepSubHeadings[step]}
          </Typography>
          <Typography
            variant="h1"
            className="mainHeading"
          >
            {stepMainHeadings[step]}
          </Typography>
        </div>
        <div className="body">{displayBody()}</div>
        {step !== 5 && (
          <div className="footer">
            <div className="step-bar-container">
              <div className="step-lines">
                <div className={`two ${step >= 0 ? "active" : ""}`}></div>
                <div className={`two ${step >= 1 ? "active" : ""}`}></div>
                <div className={`two ${step >= 2 ? "active" : ""}`}></div>
                <div className={`two ${step >= 3 ? "active" : ""}`}></div>
                <div className={`two ${step >= 4 ? "active" : ""}`}></div>
              </div>
              <div className="step-buttons">
                <Button
                  className="back-button"
                  onClick={handleBack}
                  disabled={step === 0}
                >
                  <Typography
                    variant="button-text"
                    className="back-button-text"
                  >
                    back
                  </Typography>
                </Button>
                <Button
                  className="next-button"
                  onClick={handleNext}
                  disabled={step === stepMainHeadings.length - 1}
                >
                  <Typography
                    variant="button-text"
                    className="next-button-text"
                  >
                    next
                  </Typography>
                  <div className="pink-circle-icon">
                    <ArrowRight className="next-arrow-icon" />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ClientReviewDefault;
