import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setEmail } from "src/store/reducers/auth.reducer";
import * as Yup from "yup";
import { handleSignInEmail } from "../../../api/auth/auth.service";
import { apple, googleColored, logo } from "../../../assets";
import { Button, CustomAlert, Input, SpinnerLoading, Typography } from "../../../components/common/Base";
import "./styles.scss";

const SignInEmailDefault = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { values, handleChange, handleBlur, handleSubmit, errors, setErrors, isValid } = useFormik({
    initialValues: {
      email: "",
      submit: null,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Enter a valid email address")
        .required("Email is required"),
    }),
    onSubmit: () => {
      const { email } = values;
      if (!email) {
        setErrors({ email: "Email is required" });
        return;
      }
      setLoading(true);
      handleSignInEmail({ email })
        .then((res) => {
          dispatch(setEmail(res.data.email));
          navigate("/auth/sign-in/email");
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message || err?.message || "An error occurred";
          setErrors({ submit: errorMessage });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="sign-in-email-container">
      <div className="sie-left-container"></div>
      <div className="sie-right-container">
        <div className="sie-align-center">
          <img
            src={logo}
            alt="logo"
            className="logo"
          />
          <Typography
            variant="h3"
            className="sie-main-text"
          >
            Sign in to Forreels
          </Typography>
          <div className="signin-buttons">
            <Button
              variant="outlined"
              color="link"
              className="google-button"
            >
              <img
                src={googleColored}
                alt=""
                className="icon-stylee"
              />
              <Typography variant="body-2">Sign in with Google</Typography>
            </Button>
            <Button
              variant="outlined"
              color="link"
              className="apple-button"
            >
              <img
                src={apple}
                alt=""
                className="icon-stylee"
              />
              <Typography variant="body-2">Sign in with Apple</Typography>
            </Button>
          </div>
          <div className="divider-lines">
            <Typography
              variant="small-text"
              className="or-text"
            >
              or
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit}
            className="sie-form-container"
          >
            <div>
              <Typography
                variant="small-text"
                className="sie-field-name"
              >
                Email
              </Typography>
              <Input
                type="text"
                placeholder="Enter your email address"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.email}
                state={errors.email ? "error" : ""}
              />
            </div>
            {errors.submit && (
              <CustomAlert
                variant="danger"
                text={errors.submit}
              />
            )}
            <div>
              <Button
                color="primary"
                className="sie-next-button"
                type="submit"
                disabled={!isValid || !values.email}
              >
                <Typography variant="button-text">{loading ? <SpinnerLoading /> : "Next"}</Typography>
              </Button>
            </div>
          </form>
          <div>
            <Button
              color="secondary"
              variant="outlined"
              className="sie-fp-button"
              onClick={() => navigate("/auth/forgot")}
            >
              <Typography variant="button-text">Forgot Password?</Typography>
            </Button>
          </div>
          <div className="sie-last-text">
            <Typography
              variant="link-text"
              className="sie-text"
            >
              Don't Have an account?{" "}
              <Link
                className="link-style"
                to={"/auth/sign-up"}
              >
                Sign up
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInEmailDefault;
