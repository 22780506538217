import {
  spBodyImage,
  spCard1,
  spCard2,
  spCard3,
  spCard4,
  spCard5,
  spHeader1,
  spHeader2,
  vector5,
  vector6,
  vector7,
} from "../../../assets";
import { Accordion, Button, CarouselSlider, Typography } from "../../../components/common/Base";
import { BlueSection } from "../../../components/common/Layout";
import { IconTextCard, ImageTextHeader, SingleTextCard } from "../../../components/common/Widgets";
import "./styles.scss";

const SuperProducersDefault = () => {
  const sectionsData = [
    {
      heading: "Overview",
      accordions: [
        { title: "How is Forreels unique?", content: "Hello World 1" },
        {
          title: "What happens if I’m not satisfied with Forreels SuperProducers?",
          content: "Hello World 2",
        },
        { title: "Are there any hidden fees? ", content: "Hello World 3" },
        { title: "Geography ", content: "Hello World 3" },
      ],
    },
    {
      heading: "Geography",
      accordions: [{ title: "How is Forreels unique?", content: "Hello World 1" }],
    },

    // Add more sections as needed
  ];
  const itemList = [
    {
      heading: <Typography variant="h3">Directors</Typography>,
      image: spCard1,
    },
    {
      heading: <Typography variant="h3">MUAs</Typography>,
      image: spCard2,
    },
    {
      heading: <Typography variant="h3">Full Crews</Typography>,
      image: spCard3,
    },
    {
      heading: <Typography variant="h3">Sound Designers</Typography>,
      image: spCard4,
    },
    {
      heading: <Typography variant="h3">Editors</Typography>,
      image: spCard5,
    },
    {
      heading: <Typography variant="h3">...and more</Typography>,
    },
  ];

  const itemListt = [
    { src: spCard1, imageText: "Directors", textAlignment: "right" },
    { src: spCard2, imageText: "MUAs", textAlignment: "right" },
    { src: spCard3, imageText: "Full crews", textAlignment: "right" },
    { src: spCard4, imageText: "Sound designers", textAlignment: "right" },
    { src: spCard5, imageText: "Editors", textAlignment: "right" },
  ];

  const cardItems = [
    {
      id: "1",
      icon: vector5,
      heading: "Tailored guidance ",
      subHeading: "Exclusive attention and personalized development plan ",
    },
    {
      id: "2",
      icon: vector6,
      heading: "Shadowing ",
      subHeading: "Spend the day with a film professional to gain on the job experience",
    },
    {
      id: "3",
      icon: vector7,
      heading: "Around the clock support",
      subHeading: "Input and support at your fingertips, our SuperProducers are here whenever you need an ear.",
    },
  ];
  return (
    <>
      <div className="super-producers">
        <div className="section-01">
          <ImageTextHeader
            bgImage={spHeader1}
            className="bg-image"
          >
            <div className="container text-wrapper">
              <div
                className="sub-heading"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <Typography variant="pre-title">
                  <span className="spanStyle">Maximum </span> productivity
                </Typography>
              </div>
              <div
                className="main-heading"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <Typography
                  variant="h1"
                  className="heading-style"
                >
                  SuperProducers, got your back.
                </Typography>
              </div>
              <div
                className="sub-heading"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <Typography variant="pre-title">
                  <span className="spanStyle">Rest assurance</span> for the completion of your project when in the hands
                  of a <span className="spanStyle">SuperProducer</span>. From conception to deployment our seasoned
                  <span className="spanStyle"> SuperProducers</span> are here by your side with their own rock solid
                  team, ensuring you with best practices, cost effectiveness and a peace of mind during your project.
                </Typography>
              </div>
              <div
                className="buttons"
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-duration="500"
              >
                <Button
                  className={`desktop animate__fadeInUp animate__animated animate__slow buttonStyle`}
                  color={"link"}
                  variant={"outlined"}
                >
                  <Typography variant="button-text">Book a SuperProducer</Typography>
                </Button>

                <Button
                  className={`desktop animate__fadeInUp animate__animated animate__slow buttonStyle`}
                  color={"link"}
                  variant={"outlined"}
                >
                  <Typography variant="button-text">888-323-3524</Typography>
                </Button>
              </div>
            </div>
          </ImageTextHeader>
        </div>
        <div className="section-02">
          <div className="eclipse"></div>

          <div
            className="container text-wrapper"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <div
              className="s2-mainHeading"
              data-aos="fade-in"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <Typography variant="h1">Skin in the game.</Typography>
            </div>
            <div className="sub-heading">
              <Typography variant="pre-title">
                <span className="spanStyle">On budget,</span> on time
              </Typography>
            </div>
            <div className="sub-heading">
              <Typography variant="pre-title">
                Forreels.io <span className="spanStyle">SuperProducers</span> are renown for{" "}
                <span className="spanStyle">assembling dream teams </span>
                that brings <span className="spanStyle">cinematic visions to life</span>, with{" "}
                <span className="spanStyle">proven track records </span>
                and extensive network of <span className="spanStyle">industry connections</span>. <br />
                With this
                <span className="spanStyle"> powerhouse producer</span> by your side, your project not only gains
                industry expertise but also a <span className="spanStyle">passionate ambassador for its success.</span>
              </Typography>
            </div>
          </div>
          <div className="gradientStyle1"></div>
          <div className="image-container">
            <img
              src={spBodyImage}
              alt=""
              className="image"
            />
          </div>
          <div className="gradientStyle2"></div>
          <div
            className="container text-wrapper-02"
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <div className="sub-heading">
              <Typography variant="pre-title">
                The one stop shop - <span className="spanStyle">dream team</span>
              </Typography>
            </div>
            <div className="s2-mainHeadingg">
              <Typography
                variant="h1"
                className="heading-style"
              >
                Giving you all the cheat codes.
              </Typography>
            </div>

            <div className="text-wrapper-03">
              <div className="sub-heading">
                <Typography variant="pre-title">
                  <span className="spanStyle">Up to</span>
                </Typography>
              </div>
              <div className="main-heading-02">
                <Typography
                  variant="h3"
                  className="textStyle"
                >
                  20x faster
                </Typography>
              </div>
              <div className="sub-heading-02">
                <Typography
                  variant="pre-title"
                  className="subtitle-styling"
                >
                  <span className="spanStyle">and more successful, organized productions</span>
                </Typography>
              </div>
            </div>
          </div>
          {/* component   */}
          <div
            className="container grid-container desktop_view"
            data-aos="zoom-out"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            {itemList.map((item, index) => (
              <SingleTextCard
                heading={item.heading}
                image={item.image}
                key={index}
                // description={item.description}
                headingAlignment={"right"}
              />
            ))}
          </div>
          <div className="mobile_slider">
            <CarouselSlider images={itemListt} />
          </div>

          <div className="gradientStyle1"></div>

          <div className="header-02-styles">
            <ImageTextHeader bgImage={spHeader2}>
              <div className="container text-wrapper">
                <div
                  className="sub-heading"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="700"
                >
                  <Typography variant="pre-title">
                    <span className="spanStyle">Industry </span> standard support
                  </Typography>
                </div>
                <div
                  className="main-heading"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="600"
                >
                  <Typography variant="h1">Guiding emerging talent.</Typography>
                </div>
                <div
                  className="sub-headingg"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                >
                  <Typography variant="pre-title">
                    Its never too late or too early to break into creative spaces. Our{" "}
                    <span className="spanStyle">SuperProducers</span> are also here for other creators including child
                    actors, to mentor, to coach and to pave the way. Our
                    <span className="spanStyle">SuperProducers</span> provide support that helps{" "}
                    <span className="spanStyle">instil confidence</span>
                    useful in landing your <span className="spanStyle">first gig</span>.
                  </Typography>
                </div>
              </div>
            </ImageTextHeader>
          </div>
          <div className="gradientStyle2"></div>
        </div>
        <div className="section-03">
          {/* component */}
          <div
            className="card-container"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            {cardItems.map((item) => (
              <IconTextCard
                key={item.id}
                icon={item.icon}
                heading={item.heading}
                subHeading={item.subHeading}
              />
            ))}
          </div>
          <div className="container common-questions-container">
            <div
              className="main-headingg"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <Typography variant="h2">Common Questions</Typography>
            </div>
            <div
              className="accordions"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              {sectionsData.map((section, index) => (
                <div
                  className="accordions-containers"
                  key={index}
                >
                  <div className="accordion-heading">
                    <Typography variant="h3">{section.heading}</Typography>
                  </div>
                  <div className="all-accordions">
                    {section.accordions.map((accordion, i) => (
                      <Accordion
                        key={i}
                        title={accordion.title}
                        content={accordion.content}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="eclipse1"></div>
          <div className="container plain-section">
            <div
              className="text"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <Typography variant="h2">Stay connected.</Typography>
            </div>
            <section className="parent">
              <div className="cta">
                <div className="cta__content">
                  <form className="input-container">
                    <span>
                      <input
                        type="email"
                        name="query"
                        placeholder="example@example.com"
                        autoComplete="off"
                        required
                      />
                    </span>

                    <span>
                      <button
                        type="submit"
                        className="subscribe-btn"
                      >
                        <Typography variant="button-text">Subscribe</Typography>
                      </button>
                    </span>
                  </form>
                </div>
              </div>
            </section>
            {/* <SearchBar inputPlaceholder={'example@gmail.com'} buttonName={'Subscribe'} /> */}
          </div>

          {/* You can adjust the rotation angle based on your design */}
          {/* <div className="eclipse2"></div> */}
          <BlueSection className="blue-section-styles">
            <div className="section-text">
              <div className="section-text-style">
                <Typography
                  variant="h2"
                  className="blue-section-text-center"
                >
                  Be a part of the leading content creator sourcing platform.
                </Typography>
              </div>
            </div>
          </BlueSection>
        </div>
      </div>
    </>
  );
};

export default SuperProducersDefault;
