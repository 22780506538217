import { Link } from "react-router-dom";
import { logoBlue, textLogoBlue } from "../../../assets";
import { Button, Typography } from "../../../components/common/Base";
import "./styles.scss";

const ContactFormConfirmationDefault = () => {
  return (
    <div className="contact-form-confirmation-container">
      <div className="contact-form-confirmation_navbar">
        <Link
          className="navbar-brandd"
          to="/"
        >
          <>
            <img
              src={logoBlue}
              alt="Logo"
              width={40}
              height={40}
              className="img-fluid logo me-3"
            />
            <img
              src={textLogoBlue}
              alt="Text logo"
              className="img-fluid text-logo"
            />
          </>
        </Link>
      </div>
      <div className="contact-form-confirmation-body">
        <Typography
          variant="h2"
          className="cfc-main-heading"
        >
          We’ll be in touch.
        </Typography>
        <Typography
          variant="body-1"
          className="cfc-sub-heading"
        >
          You are <span>officially</span> on the list and we will be in touch soon! In the <span>meantime</span>, you
          can <span>follow our social accounts.</span>
        </Typography>
        <Link to={"/landing-page"}>
          <Button
            color="primary"
            className="cfc-button"
          >
            <Typography variant="button-text">Back to Home</Typography>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ContactFormConfirmationDefault;
