import React from "react";
import { Input, Select, Typography } from "../../../../components/common/Base";
import "./styles.scss";

const ServiceProductAssessment = () => {
  const selectOptions = ["strongly agree", "agree", "I don't know", "not satisfied", "dissatisfied"];

  return (
    <div className="service-product-assessment-container">
      <Select
        text={"How satisfied are you with Forreels.io?"}
        options={selectOptions}
      />
      <Select
        text={"Forreels.io provides innovative solutions?"}
        options={selectOptions}
      />
      <Select
        text={"The services provided by Forreels.io were of quality and met an industry standard."}
        options={selectOptions}
      />
      <Select
        text={"The services were good value for the money."}
        options={selectOptions}
      />
      <div className="section-02-container">
        <div className="text-area-container">
          <Typography variant="subtitle">How can we encourage creators to make more use of this platform?</Typography>
          <Input
            type="textarea"
            placeholder="Type here"
          />
        </div>
        <div className="text-area-container">
          <Typography variant="subtitle">How can we encourage creators to make more use of this platform?</Typography>
          <Input
            type="textarea"
            placeholder="Type here"
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceProductAssessment;
