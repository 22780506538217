import React from "react";
import { Select } from "../../../../components/common/Base";
import "./styles.scss";

const Endorsements = () => {
  const selectOptions1 = [
    "graphic design",
    "modeling",
    "acting",
    "screenwriting",
    "sound design",
    "producing",
    "film editing",
  ];
  const selectOptions2 = [
    "modeling",
    "content strategy",
    "cinematography",
    "collaboration",
    "communication",
    "project management",
    "budget management",
  ];
  const selectOptions3 = [
    "modeling",
    "film finance",
    "critical thinking",
    "directing actors",
    "decision making",
    "social media",
    "presenting",
  ];
  const selectOptions4 = [
    "modeling",
    "problem solving",
    "camera operating",
    "creativity",
    "gaffing",
    "set designing",
    "composing",
  ];

  return (
    <div className="endorsements-container">
      <Select options={selectOptions1} />
      <Select options={selectOptions2} />
      <Select options={selectOptions3} />
      <Select options={selectOptions4} />
    </div>
  );
};

export default Endorsements;
