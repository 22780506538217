import { Link } from "react-router-dom";
import { apple, googleColored, logo } from "../../../assets";
import { Button, Typography } from "../../../components/common/Base";
import "./styles.scss";

const WelcomeSignUpDefault = () => {
  return (
    <div className="sign-up-container">
      <div className="left-container"></div>

      <div className="right-container">
        <div className="align-center">
          <img
            src={logo}
            alt="logo"
            className="logo"
          />
          <Typography
            variant="h3"
            className="welcome-text"
          >
            Join Foreels
          </Typography>
          <div className="signup-buttons">
            <Button
              variant="outlined"
              color="link"
              className="google-button"
            >
              <img
                src={googleColored}
                alt=""
                className="icon-stylee"
              />
              <Typography variant="body-2">Sign up with Google</Typography>
            </Button>
            <Button
              variant="outlined"
              color="link"
              className="apple-button"
            >
              <img
                src={apple}
                alt=""
                className="icon-stylee"
              />
              <Typography variant="body-2">Sign up with Apple</Typography>
            </Button>
          </div>

          <div className="divider-lines">
            <Typography
              variant="small-text"
              className="or-text"
            >
              or
            </Typography>
          </div>
          <Link
            className="create-button-link"
            to={"/auth/sing-up/account"}
          >
            <Button
              color="primary"
              className="create-button"
            >
              <Typography variant="button-text">Create Account</Typography>
            </Button>
          </Link>
          <Typography
            variant="small-text"
            className="description"
          >
            By signing up, you agree to the{" "}
            <Link
              to="#"
              className="link-style"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              to="#"
              className="link-style"
            >
              Privacy Policy
            </Link>
            , including{" "}
            <Link
              to="#"
              className="link-style"
            >
              Cookie Use
            </Link>
            .
          </Typography>
          <div className="last-text">
            <Typography variant="link-text">Already have an account?</Typography>
          </div>
          <Link
            className="sign-in-button-link"
            to={"/auth/sign-in"}
          >
            <Button
              variant="outlined"
              color="link"
              className="sign-in-button"
            >
              <Typography variant="button-text">Sign in</Typography>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSignUpDefault;
