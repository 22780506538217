import React from "react";
import { Alert } from "react-bootstrap";
import { Check, Info } from "react-feather";
import Typography from "../Typography";
import "./styles.scss";

interface CustomAlertProps {
  className?: string;
  variant: string;
  text: string | JSX.Element;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ className, variant, text }) => {
  return (
    <Alert
      variant={variant}
      className={`${variant === "danger" ? "alert-container-error" : "alert-container"} ${className ?? ""} `}
    >
      <div className="icon-text">
        {variant === "danger" ? <Info className="icon" /> : variant === "success" ? <Check className="icon" /> : ""}

        <Typography
          variant="small-text"
          className="alert-text"
        >
          {text}
        </Typography>
      </div>
    </Alert>
  );
};

export default CustomAlert;
