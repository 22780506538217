import { Footer, MenuNavbar } from "../components/common/Layout";
import { CustomersDefault } from "../components/features";

const Customers = () => (
  <>
    <MenuNavbar />
    <CustomersDefault />
    <Footer showLargeFooter />
  </>
);

export default Customers;
