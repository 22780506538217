import React from "react";
import { Search } from "react-feather";
import { Button, Typography } from "../../Base";
import "./styles.scss";

interface SearchBarProps {
  inputPlaceholder: string;
  buttonName?: string;
  icon?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ inputPlaceholder, buttonName, icon }) => (
  <div className="search-bar">
    <div className="input-field">
      {icon && <Search className="search-icon" />}
      <Typography variant="body-1">
        <input
          placeholder={inputPlaceholder}
          className="search-input"
        />
      </Typography>
    </div>
    {buttonName && (
      <Button
        className="search-button"
        color="primary"
      >
        <Typography variant="button-text">{buttonName}</Typography>
      </Button>
    )}
  </div>
);

export default SearchBar;
