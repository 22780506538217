import { MenuNavbar } from "../components/common/Layout";
import { ClientReviewDefault } from "../components/features";

const ClientReview = () => (
  <>
    <MenuNavbar />
    <ClientReviewDefault />
  </>
);

export default ClientReview;
