import React from "react";
import { Typography } from "../../Base";
import "./styles.scss";

interface IconTextCardProps {
  icon: string | JSX.Element;
  heading: string;
  subHeading: string;
}

const IconTextCard: React.FC<IconTextCardProps> = ({ icon, heading, subHeading }) => {
  return (
    <div className="icon-text-card-container">
      <div className="content-container">
        <div className="icon-style">
          {typeof icon === "string" ? (
            <img
              src={icon}
              alt="icon"
            />
          ) : (
            icon
          )}
        </div>
        <div className="heading-style">
          <Typography variant="subtitle">{heading}</Typography>
        </div>
        <div className="sub-heading-style">
          <Typography variant="body-1">{subHeading}</Typography>
        </div>
      </div>
    </div>
  );
};

export default IconTextCard;
