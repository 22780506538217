import { Footer, MenuNavbar } from "../components/common/Layout";
import { CareersDefault } from "../components/features";

const Careers = () => (
  <>
    <MenuNavbar />
    <CareersDefault />
    <Footer showLargeFooter />
  </>
);

export default Careers;
