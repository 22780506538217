import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { arrowIcon, logo, textLogo } from "../../../../../assets";
import { Button, Typography } from "../../../../../components/common/Base";
import "./style.scss";

const MenuNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control menu visibility

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const navItems = document.querySelectorAll(".navigation-menu-collapse .nav-item[data-index]");
    navItems.forEach((item) => {
      const index = item.getAttribute("data-index");
      (item as HTMLLIElement).style.transitionDelay = `calc(30ms * ${index})`;
    });
  }, [isMenuOpen]);

  return (
    <>
      <nav className="menu-navbar navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <Link
            className="navbar-brand"
            to="/"
          >
            <img
              src={logo}
              alt="Logo"
              width={40}
              height={40}
              className="img-fluid logo me-3"
            />
            <img
              src={textLogo}
              alt="Text logo"
              className="img-fluid text-logo"
            />
          </Link>
          <button
            className={`navbar-toggler ${isMenuOpen ? "open" : ""}`}
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <div className="toggle-dots-icon">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
              <div className="dot dot4"></div>
            </div>
          </button>

          <div className={"collapse navbar-collapse"}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                >
                  <Typography variant="small-text">Legacy</Typography>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"/super-producers"}
                >
                  <Typography variant="small-text">SuperProducer®</Typography>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"/whats-new"}
                >
                  <Typography variant="small-text">What's New</Typography>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"/customers"}
                >
                  <Typography variant="small-text">Customers</Typography>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                >
                  <Typography variant="small-text">Marketplace</Typography>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                >
                  <Typography variant="small-text">Creatives</Typography>
                </Link>
              </li>
            </ul>
          </div>
          <div className="signup">
            <a
              href="/auth/sign-in"
              className="text-light"
            >
              <Button
                color={"link"}
                className={"me-2"}
              >
                <Typography variant="button-text">Sign In</Typography>
              </Button>
            </a>
            <a
              href="/auth/sign-up"
              className="text-light"
            >
              <Button
                color={"primary"}
                className={"me-2"}
              >
                <Typography variant="button-text">Sign Up</Typography>
              </Button>
            </a>
          </div>
        </div>
      </nav>

      <div className={`navigation-menu-collapse ${isMenuOpen ? "show" : ""}`}>
        <ul className={`navbar-nav`}>
          <div>
            <li className={`nav-item`}>
              <Link
                className="nav-link"
                to="#"
              >
                <Typography variant="pre-title">Legacy</Typography>
              </Link>
            </li>
            <li
              className="nav-item"
              data-index="1"
            >
              <Link
                className="nav-link"
                to={"/super-producers"}
              >
                <Typography variant="pre-title">Super Producers</Typography>
              </Link>
            </li>
            <li
              className="nav-item"
              data-index="2"
            >
              <Link
                className="nav-link"
                to={"/whats-new"}
              >
                <Typography variant="pre-title">What's New</Typography>
              </Link>
            </li>
            <li
              className="nav-item"
              data-index="3"
            >
              <Link
                className="nav-link"
                to={"/customers"}
              >
                <Typography variant="pre-title">Customers</Typography>
              </Link>
            </li>
            <li
              className="nav-item"
              data-index="4"
            >
              <Link
                className="nav-link"
                to="#"
              >
                <Typography variant="pre-title">Marketplace</Typography>
              </Link>
            </li>
            <li
              className="nav-item"
              data-index="5"
            >
              <Link
                className="nav-link"
                to="#"
              >
                <Typography variant="pre-title">Creatives</Typography>
              </Link>
            </li>
          </div>
          <div
            className="signup-buttonss  nav-item"
            data-index="5"
          >
            <a
              href="/auth/sign-up"
              className="text-light"
            >
              <Button
                color={"primary"}
                className={"me-1"}
              >
                <Typography variant="small-text">Sign Up</Typography>
              </Button>
            </a>
            <a
              href="/auth/sign-in"
              className="text-light"
            >
              <Button className={"me-2"}>
                <Typography variant="small-text">Sign In</Typography>
                <img
                  src={arrowIcon}
                  alt=""
                />
              </Button>
            </a>
          </div>
        </ul>
      </div>
    </>
  );
};

export default MenuNavbar;
