import React from "react";
import { Typography } from "../../../../components/common/Base";
import "./styles.scss";

const TipThanks = () => {
  return (
    <div className="tip-thanks">
      <Typography variant="h1">
        Thanks for <br /> looking out.
      </Typography>
    </div>
  );
};

export default TipThanks;
