import apiClient from "../apiClient";
import { ContactForm } from "./contact.types";

export function handleContactForm(contact: ContactForm) {
  return apiClient.post("contact/post", contact);
}

export function handleGetContactFormLists() {
  return apiClient.get("lookup/getList");
}
