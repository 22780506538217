import { careersImage1, careersImage2 } from "../../../assets";
import { Accordion, Button, Typography } from "../../../components/common/Base";
import { BlueSection } from "../../../components/common/Layout";
import "./styles.scss";

const CareersDefault = () => {
  return (
    <>
      <div className="careers-container">
        <BlueSection className="blue-section-01-styles">
          <div className="text-button-container">
            <div className="text-wrapper">
              <div className="title-center">
                <Typography
                  variant="h1"
                  className="jot-heading"
                >
                  Join our team.
                </Typography>
              </div>
              <div className="subtitle-center">
                <Typography
                  variant="small-text"
                  className="subtitle-styling"
                >
                  Come join a room of other diverse, great minds who are behind some of the world’s most thriving
                  productions.
                </Typography>
              </div>
            </div>
            <Button
              className={`m-auto desktop animate__fadeInUp animate__animated animate__slow vor-button`}
              color={"link"}
              variant={"outlined"}
            >
              <Typography variant="button-text">View Open Roles</Typography>
            </Button>
          </div>
        </BlueSection>
        <div className="image-container">
          <img
            src={careersImage1}
            alt="CareerImage1"
            className="image"
          />
        </div>
        <BlueSection className="blue-section-02-styles">
          <div className="container text-button-container-02">
            <div className="text-wrapper-02">
              <div className="subtitle-left">
                <Typography variant="subtitle">Benefits</Typography>
              </div>
              <div className="title-left">
                <Typography variant="h1">
                  You're in good <br /> hands
                </Typography>
              </div>

              <div className="subtitle-left">
                <Typography
                  variant="subtitle"
                  className="subtitle-style-1"
                >
                  human resources, freedom remote working, worldwide opportunities, diverse projects,
                </Typography>
              </div>
            </div>
          </div>
          <div className="image-container-02">
            <img
              src={careersImage2}
              alt="CareerImage1"
              className="image ci-2"
            />
          </div>
          <div className=" text-button-container-02 accordions-container">
            <div className="container text-wrapper-02">
              <div className="subtitle-left">
                <Typography variant="subtitle">Careers at Forreels.io</Typography>
              </div>
              <div className="title-left">
                <Typography
                  variant="h1"
                  className="main-heading ic-2"
                >
                  Life set up doing what you love everyday.
                </Typography>
              </div>

              <div className="subtitle-left">
                <Typography variant="subtitle">Currently seeking talent in the current categories.</Typography>
              </div>
            </div>

            <div className="container accordions">
              <div className="accordion">
                <div className="mainHeading">
                  <Typography variant="h2">Design</Typography>
                </div>
                <div className="all-accordions">
                  <Accordion
                    title={"Senior Designer Manager"}
                    content={"Hello World"}
                  />
                </div>
              </div>
              <div className="accordion">
                <div className="mainHeading">
                  <Typography variant="h2">Engineering</Typography>
                </div>
                <div className="all-accordions">
                  <Accordion
                    title={"Senior Engineer Manager - Mobile"}
                    content={"Hello Hello"}
                  />
                  <Accordion
                    title={"Senior Engineer - Frontend"}
                    content={"Hello Hello"}
                  />
                </div>
              </div>
              <div className="accordion">
                <div className="mainHeading">
                  <Typography variant="h2">Marketing</Typography>
                </div>
                <div className="all-accordions">
                  <Accordion
                    title={"Senior Engineer - Frontend"}
                    content={"Hello Hello"}
                  />
                </div>
              </div>
              <div className="accordion">
                <div className="mainHeading">
                  <Typography variant="h2">Product and Innovation</Typography>
                </div>
                <div className="all-accordions">
                  <Accordion
                    title={"Senior Product Manager "}
                    content={"Hello Hello"}
                  />
                  <Accordion
                    title={"Content Creator"}
                    content={"Hello Hello"}
                  />
                </div>
              </div>
              <div className="accordion">
                <div className="mainHeading">
                  <Typography variant="h2">Human Resources</Typography>
                </div>
                <div className="all-accordions">
                  <Accordion
                    title={"Human Resource Manager - Japan"}
                    content={"Hello Hello"}
                  />
                  <Accordion
                    title={"Human Resource Manager - USA "}
                    content={"Hello Hello"}
                  />
                </div>
              </div>
            </div>
          </div>
        </BlueSection>
        <BlueSection className="blue-section-03-styles" />
      </div>
    </>
  );
};

export default CareersDefault;
