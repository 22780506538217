import { Footer, MenuNavbar } from "../components/common/Layout";
import { CommonQuestionsDefault } from "../components/features";

const CommonQuestions = () => (
  <>
    <MenuNavbar />
    <CommonQuestionsDefault />
    <Footer showLargeFooter />
  </>
);

export default CommonQuestions;
