import NotificationList from "../../components/Notification";
import "./style.scss";

const NotificationWidget = () => {
  const notifications = [
    { message: "Joe Studio sent form Graphic Design Brief to Jane Studio.", date: "Feb 8, 2024 / 7:15 PM" },
    { message: "Joe Studio sent form Graphic Design Brief to Jane Studio.", date: "Feb 8, 2024 / 7:15 PM" },
    { message: "You have a", date: "Feb 8, 2024 / 7:15 PM" },
    { message: "You have a", date: "Feb 8, 2024 / 7:15 PM" },
    { message: "You have a", date: "Feb 8, 2024 / 7:15 PM" },
    { message: "You have a", date: "Feb 8, 2024 / 7:15 PM" },
    { message: "You have a", date: "Feb 8, 2024 / 7:15 PM" },
  ];

  return (
    <div className="notifications-container">
      <h2>Notifications</h2>

      <NotificationList notifications={notifications} />
    </div>
  );
};

export default NotificationWidget;
