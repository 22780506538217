import { Footer, MenuNavbar } from "../components/common/Layout";
import { SeeStoriesDefault } from "../components/features";

const SeeStories = () => (
  <>
    <MenuNavbar />
    <SeeStoriesDefault />
    <Footer showLargeFooter />
  </>
);

export default SeeStories;
