import { accounts1, accounts2, accounts3 } from "../../../assets";
import { Typography } from "../../../components/common/Base";
import { BlueSection } from "../../../components/common/Layout";
import { HalfImageText } from "../../../components/common/Widgets";
import "./styles.scss";

const AccountsDefault = () => {
  const halfImageSectionData = [
    {
      title: (
        <>
          SuperProducer<Typography variant="small-text">{"\u00AE"}</Typography>
        </>
      ),
      text: "The ultimate hub for project managers. With a wealth of experience, an expansive network of creators, and access to abundant resources, SuperProducers® are the elite of the industry. Plus, enjoy exclusive perks like Forreels.io Messenger access and a range of other cutting-edge features.",
      image: [{ src: accounts1, carousel: false }],
    },
    {
      title: "Creators",
      text: "Where brilliance knows no bounds. From influencers and actresses to dancers, sound designers, set designers, and directors, our platform embraces every creative force. Unleash your talent, flaunt your expertise, and seize the spotlight to land coveted opportunities. ",
      image: [
        { src: accounts3, text: "Makeup Artists" },
        { src: accounts1, text: "Chreographers" },
        { src: accounts2, text: "Designers" },
        { src: accounts3, text: "Makeup Artists" },
        { src: accounts1, text: "Chreographers" },
        { carousel: true },
      ],
    },
    {
      title: "Clients",
      text: (
        <>
          Our Clients infuse us with the dynamic energy that propels us forward. Whether you are a podcaster in search
          of a skilled crew to amplify your voice, a brand in need of captivating content, or a music artist seeking a
          talented film team, your perfect profile awaits you here.
          <br />
          <br />
          <span>Companies </span>and <span>large projects </span>visit us <span>here </span>
        </>
      ),
      image: [{ src: accounts2, carousel: false }],
    },
  ];
  return (
    <div className="accounts-container">
      <BlueSection className="blue-01">
        <Typography
          variant="subtitle"
          className="subtitle-heading"
        >
          Our user accounts
        </Typography>
        <Typography
          variant="h1"
          className="work-mainHeading"
        >
          How We Work
        </Typography>
        <Typography
          variant="subtitle"
          className="body-paragraph"
        >
          Here are the people who make Forreels.io what it is and here is how they work.
        </Typography>
      </BlueSection>
      <div className="container">
        {/* eclipse gradient */}
        <div className="eclipse"></div>
        <div className="account-section-containers">
          {halfImageSectionData.map((item, index) => (
            <HalfImageText
              align="left"
              key={index}
              title={item.title}
              text={item.text}
              image={item.image}
              titleVariant={"h3"}
              textVariant={"subtitle"}
              iconButton={true}
            />
          ))}
        </div>
      </div>

      <BlueSection className="blue-02" />
    </div>
  );
};

export default AccountsDefault;
