import React from "react";
import "./style.scss";
import Select, { SingleValue, ActionMeta, components, OptionProps, SingleValueProps } from "react-select";

import { ReactComponent as TickIcon } from "./../../../../assets/icons/rightIcon.svg";

interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  options: Option[];
  onChange: (option: SingleValue<Option>, actionMeta: ActionMeta<Option>) => void;
  placeholder: string;
}

const Dropdown: React.FC<DropdownProps> = ({ options, onChange, placeholder }) => {
  const SingleValue = (props: SingleValueProps<Option, false>) => (
    <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
  );

  const Option = (props: OptionProps<Option, false>) => (
    <components.Option {...props}>
      <div className="main-input-div">
        <div className="input-text-icon">{props.isSelected && <TickIcon style={{ marginLeft: 2 }} />}</div>
        <div className="input-text-lebal">{props.data.label}</div>
      </div>
    </components.Option>
  );

  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      classNamePrefix="react-select"
      components={{ IndicatorSeparator: () => null, SingleValue, Option }}
    />
  );
};

export default Dropdown;
