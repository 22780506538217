import { Typography } from "../../../components/common/Base";
import "./style.scss";

const SubscriptionConfirmation = () => {
  return (
    <>
      <section className="subscribe-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <Typography
                variant="h1"
                className="h1"
              >
                We’ll be in touch.
              </Typography>
              <Typography
                variant="subtitle"
                className="subtitle-style"
              >
                You are <span>officially</span> on the list and we will be in touch soon!
                <br className="line-break" />
                In the <span>meantime</span>, you can <span>follow our social accounts.</span>
              </Typography>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubscriptionConfirmation;
