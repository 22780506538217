import React from "react";
import { RadioButton } from "src/components/common/Base";
import { Button } from "antd";

interface PayoutData {
  email: string;
  paymentMethod: string;
  handleEdit: () => void;
}

interface PayoutCardProps {
  payoutData: PayoutData;
  type: string;
}

const PayoutCard: React.FC<PayoutCardProps> = ({ payoutData, type }) => {
  return (
    <div className="card-payout">
      <div className="card-radio">
        <RadioButton
          checked
          name="checkbox"
          value="true"
          onChange={() => ""}
        />
      </div>
      <div className="payout-card-content">
        <div className="payout-card-header">
          <h3>{payoutData.paymentMethod}</h3>
          {type != "Rates" && <p className="payout-card-email">{payoutData.email}</p>}
        </div>
      </div>
      <div className="payout-card-body">
        <Button
          className="card-price-edit"
          onClick={payoutData.handleEdit}
        >
          edit
        </Button>
      </div>
    </div>
  );
};

export default PayoutCard;
