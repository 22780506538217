import { useRive } from "@rive-app/react-canvas";
import { useRef, useState } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Link } from "react-router-dom";
import {
  adClients1,
  adClients2,
  adClients3,
  adClients4,
  logoBlack,
  logoBlue,
  textLogoBlack,
  textLogoBlue,
} from "../../../assets";
import { Button, Typography } from "../../../components/common/Base";
import "./styles.scss";

const AccountDetails_ClientsDefault = () => {
  const [step, setStep] = useState(0);
  const { rive, RiveComponent } = useRive({
    src: "logo_hover_anim_5.riv",
    stateMachines: "State Machine 1",
    autoplay: true,
  });
  const stepMainHeadings = [
    "Welcome to forreels",
    "Explore Creators and Locations",
    "Create your masterpiece",
    "Team access enabled",
    "Follow the process",
    "",
  ];
  const stepSubHeadings = [
    "Hey you, thanks for your support. Find your creatives to build your masterpiece ",
    "Discover and secure talented creators and picturesque locations for your upcoming project.",
    "Share your ideas with creators and watch it manifest with ease.",
    "Employees have seats to your Forreels accounts to create a profile and represent your brand. They can receive and manage tasks directly from their dashboard.",
    "Start to finish milestones, receive notifications on progress with clear communication.",
    "",
  ];
  const images = ["", adClients1, adClients2, adClients3, adClients4];
  const sectionRef = useRef(null);

  const handleNext = () => setStep((prevStep) => prevStep + 1);

  const handleStepDotClick = (index: number) => setStep(index);

  const handleBack = () => setStep((prevStep) => prevStep - 1);

  return (
    <div
      className={`full-container-ad ${step === 0 ? "firstPage" : step === 1 ? "secondPage" : step === 2 ? "thirdPage" : step === 3 ? "fourthPage" : step === 4 ? "fifthPage" : step === 5 ? "sixthPage" : ""}`}
    >
      <div
        className="container accountDetails_Clients-container"
        ref={sectionRef}
      >
        <div className="accountDetails_navbar">
          <Link
            className="navbar-brandd"
            to="/"
          >
            {step !== 2 ? (
              <>
                <img
                  src={logoBlue}
                  alt="Logo"
                  width={40}
                  height={40}
                  className="img-fluid logo me-3"
                />
                <img
                  src={textLogoBlue}
                  alt="Text logo"
                  className="img-fluid text-logo"
                />
              </>
            ) : (
              <>
                <>
                  <img
                    src={logoBlack}
                    alt="Logo"
                    width={40}
                    height={40}
                    className="img-fluid logo me-3"
                  />
                  <img
                    src={textLogoBlack}
                    alt="Text logo"
                    className="img-fluid text-logo"
                  />
                </>
              </>
            )}
          </Link>
          {step < 5 && (
            <div className="skip-button-container">
              <Button
                className={`skip-button ${step === 0 ? "first-ad-details" : step === 1 ? "second-ad-details" : step === 2 ? "third-ad-details" : step === 3 ? "fourth-ad-details" : step === 4 ? "fifth-ad-details" : ""}`}
                onClick={handleNext}
              >
                <Typography variant="button-text">Skip</Typography>
              </Button>
            </div>
          )}
        </div>
        <div className="accountDetails_body">
          {step !== 5 ? (
            <div
              className={`ad-details-container ${step === 0 ? "first-ad-details" : step === 1 ? "second-ad-details" : step === 2 ? "third-ad-details" : step === 3 ? "fourth-ad-details" : step === 4 ? "fifth-ad-details" : ""}`}
            >
              <div className="ad-image">
                {step !== 0 && step !== 5 ? (
                  <img
                    src={images[step]}
                    alt="image1"
                  />
                ) : (
                  <RiveComponent
                    onMouseEnter={() => rive && rive.play()}
                    onMouseLeave={() => rive && rive.play()}
                    className="rive-style"
                  />
                )}
              </div>
              <Typography
                variant="h3"
                className="ad-main-heading"
              >
                {stepMainHeadings[step]}
              </Typography>
              <Typography
                variant="small-text"
                className="ad-sub-heading"
              >
                {stepSubHeadings[step]}
              </Typography>
              <div className="step-dots">
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <div
                    key={index}
                    className={`dot ${step === index ? "active" : ""}  ${step === 2 ? "third-ad-dots" : ""}`}
                    onClick={() => handleStepDotClick(index)}
                    onKeyDown={() => {
                      // TODO: dotIndex not exist
                      // if (e.key === 'Enter') {
                      // 	handleStepDotClick(dotIndex);
                      // }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="sr-only">Step {index + 1}</span> {/* Screen reader-only text for accessibility */}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="last-step-container">
              <Typography
                variant="h3"
                className="ad-main-heading"
              >
                Choose an account type.{" "}
              </Typography>
              <div className="ad-boxes">
                <Link
                  to={"/contact-form"}
                  className="link-box-1"
                >
                  <Button className="ad-box-1">
                    <Typography
                      variant="small-text"
                      className="first-text"
                    >
                      Free
                    </Typography>
                    <Typography variant="h3">Client</Typography>
                    <ul className="ad-box-list">
                      <li className="ad-list-item">
                        <Typography variant="small-text">1 account</Typography>
                      </li>
                      <li className="ad-list-item">
                        <Typography variant="small-text">Basic Messenger</Typography>
                      </li>
                      <li className="ad-list-item">
                        <Typography variant="small-text">Paid seminar and course access</Typography>
                      </li>
                      <li className="ad-list-item">
                        <Typography variant="small-text">2 productions per month</Typography>
                      </li>
                    </ul>
                  </Button>
                </Link>
                <Button className="ad-box-2">
                  <Typography
                    variant="small-text"
                    className="first-text"
                  >
                    Chat with us
                  </Typography>
                  <Typography variant="h3">Enterprise</Typography>
                  <ul className="ad-box-list">
                    <li className="ad-list-item">
                      <Typography variant="small-text">Unlimited employee accounts</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">Premium Messenger</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">Seminar and course access</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">Unlimited productions</Typography>
                    </li>
                    <li className="ad-list-item">
                      <Typography variant="small-text">Emerging Talent Access</Typography>
                    </li>
                  </ul>
                  <Typography variant="small-text">Recommended</Typography>
                </Button>
              </div>
              <div className="step-dots">
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <div
                    key={index}
                    className={`dot ${step === index ? "active" : ""}`}
                    onClick={() => handleStepDotClick(index)}
                    onKeyDown={() => {
                      // TODO: dotIndex not exist
                      // if (e.key === 'Enter') {
                      // 	handleStepDotClick(dotIndex);
                      // }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="sr-only">Step {index + 1}</span> {/* Screen reader-only text for accessibility */}
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* {displayBody()} */}
        </div>
        {step < 5 && (
          <div className="accountDetails_footer">
            <div className={`previousAndNextButtons-container `}>
              <Button
                className={`ad-previous-button ${step === 2 ? "third-ad-details" : ""}`}
                onClick={handleBack}
                disabled={step === 0}
              >
                <Typography variant="button-text">
                  <ArrowLeft /> Previous
                </Typography>
              </Button>
              <Button
                className={`ad-next-button ${step === 2 ? "third-ad-details" : ""}`}
                onClick={handleNext}
              >
                <Typography variant="button-text">
                  Next <ArrowRight />
                </Typography>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountDetails_ClientsDefault;
