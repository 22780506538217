import { Footer, MenuNavbar } from "../components/common/Layout";
import { LandingPageDefault } from "../components/features";

const LandingPage = () => (
  <>
    <MenuNavbar />
    <LandingPageDefault />
    <Footer showLargeFooter />
  </>
);

export default LandingPage;
