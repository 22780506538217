import { Footer, MenuNavbar } from "../components/common/Layout";
import { WhatsNewDefault } from "../components/features";

const WhatsNew = () => (
  <>
    <MenuNavbar />
    <WhatsNewDefault />
    <Footer showLargeFooter />
  </>
);

export default WhatsNew;
